import React, { useEffect, useState } from 'react';
import { logoutListeners } from '../ApolloInjector';
import { gql, useLazyQuery } from '@apollo/client';
import UserContext from '../../context/UserContext';
import AuthRefresh from '../../context/AuthRefresh';

export default function AuthManager({ children }) {
	const [ user, setUser ] = useState(null);

	const [ refreshUser, userQuery ] = useLazyQuery(gql`{
        whoami {
            id,
	        firstName,
	        fullName,
            email
        }
    }`);

	useEffect(() => {
		refreshUser();

		function setUserNull() {
			setUser(null);
		}

		logoutListeners.push(setUserNull);

		return () => {
			logoutListeners.splice(logoutListeners.indexOf(setUserNull), 1);
		};
	}, []);

	useEffect(() => {
		if (userQuery.data != null) {
			setUser(userQuery.data.whoami);
		}
	}, [ userQuery.data ]);

	return <UserContext.Provider value={user}>
		<AuthRefresh.Provider value={refreshUser}>
			{children}
		</AuthRefresh.Provider>
	</UserContext.Provider>;
}