// This is separated out for performance reasons on page load,
//  the XLSX library is really heavy
import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';

const FILE_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const FILE_EXTENSION = '.xlsx';

export default function exportToExcel(columns, rows) {
	const fileName = 'manifest';

	// const columns = [
	// 	{ signifier: 'A', name: , width: 30 },
	// 	{ signifier: 'B', name: , width: 20 },
	// 	{ signifier: 'C', name: , width: 20 },
	// 	{ signifier: 'D', name: , width: 20 },
	// 	{ signifier: 'E', name: , width: 20 },
	// 	{ signifier: 'F', name: , width: 8 },
	// ];

	// const excelData = modifiedProducts.map(p => ({
	// 	[columns[0].name]: p.productType.name,
	// 	[columns[1].name]: p.productType.code,
	// 	[columns[2].name]: p.barcode,
	// 	[columns[3].name]: parseValue(p.gateIn),
	// 	[columns[4].name]: p.location.code,
	// 	[columns[5].name]: p.weight,
	// }));

	const ws = XLSX.utils.json_to_sheet(rows, { header: columns.map(c => c.name), dateNF: 'yyyy"-"mm"-"dd" "hh":"mm', cellDates: true });
	ws['!cols'] = columns.map(c => ({ wch: c.width }));

	const wb = { Sheets: { stock: ws }, SheetNames: [ 'stock' ] };
	const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });

	const data = new Blob([ excelBuffer ], { type: FILE_TYPE });
	FileSaver.saveAs(data, fileName + FILE_EXTENSION);
}
