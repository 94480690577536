import SideBar from './nav/SideBar';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import CallOverview from './calls/CallOverview';
import CreateCall from './calls/CreateCall';
import ViewCall from './calls/viewcall/ViewCall';
import UserContext from '../context/UserContext';
import AuthRefresh from '../context/AuthRefresh';
import { useContext } from 'react';
import UseMagicLink from './auth/UseMagicLink';
import Login from './auth/Login';
import atrocitLogo from '../assets/atrocit-logo-1024.png';
import ShipTemplatesOverview from './shipTemplates/ShipTemplatesOverview';
import EditShipTemplate from './shipTemplates/viewShipTemplates/EditShipTemplate';
import About from './about/About';
import Dashboard from './dashboard/Dashboard';
import PortOverview from './ports/PortOverview';
import EditPort from './ports/EditPort';
import PortColorFunctionInjector from './ports/PortColorFunctionInjector';
import CreateDamageReport from './damagereport/CreateDamageReport';
import ViewOperationalPlan from './calls/operationalplan/ViewOperationalPlan';
import { DndContext } from '@dnd-kit/core';

export default function RoutesInternal() {
	const user = useContext(UserContext);
	const refreshAuth = useContext(AuthRefresh);

	return <Router>
		{user == null && <div className="auth">
			<div className="login-logo">
				<img src={atrocitLogo} alt="Atrocit Logo" className="atrocit-logo" />
			</div>
			<div className="login-frame">
				<Routes>
					<Route element={<UseMagicLink refreshAuth={refreshAuth} />} path="/magiclink/:token" />
					<Route element={<Login />} path="*" />
				</Routes>
			</div>
		</div>}
		{user != null && <div className="app">
			<SideBar />
			<div className="app-content">
				<PortColorFunctionInjector>
					<DndContext>
						<Routes>
							<Route element={<Dashboard />} path="/dashboard" />

							<Route element={<CallOverview />} path="/calls" />
							<Route element={<CreateCall />} path="/calls/create" />
							<Route element={<ViewCall />} path="/calls/:id" />

							<Route element={<ViewOperationalPlan />} path="/operationalplan/stowplan/:id" />

							<Route element={<ShipTemplatesOverview />} path="/shipTemplates" />
							<Route element={<EditShipTemplate />} path="/shipTemplates/:id" />

							<Route element={<PortOverview />} path="/ports" />
							<Route element={<EditPort />} path="/ports/create" />
							<Route element={<EditPort />} path="/ports/:id" />

							<Route element={<CreateDamageReport />} path="/damageReports" />

							<Route element={<About />} path="/about" />
							<Route element={<Navigate to="/dashboard" replace />} path="/*" />
						</Routes>
					</DndContext>
				</PortColorFunctionInjector>
			</div>
		</div>}
	</Router>;
}