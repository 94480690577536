import React from 'react';
import BigBay from './bayplanning/BigBay';
import { DateTime } from 'luxon';
import MiniBay from './bayplanning/MiniBay';
import ColorsLegend from './ColorsLegend';

export default React.forwardRef(({ voyageData, containerDiggingMap, colorMode, splitBaysOnPrintIfWide, selectedBayOnly, overviewOnly, shifters, ports, hasTemplate, templateCells, call, inOutBound, combinedBays, nonCollapsedBays, bays, polFilter, betterHighlightForPolOnly, dontShowNumbersOfEmptiesToBeLoaded }, ref) => {
	return <div className="bay-print-view-wrapper" style={{ opacity: 0 }}>
		<style type="text/css" media="print">{css}</style>

		<div className="bays-print-view" style={{ display: 'none' }} ref={ref}>
			{selectedBayOnly == null && <BayPrintPage key="overview" ports={ports} call={call} title="Bay Plan Overview" inOutBound={inOutBound} colorMode={colorMode}>
				<div style={{ display: 'flex', gap: '2mm', flexWrap: 'wrap' }}>
					{bays.map((bay, idx) => {
						return <div key={idx} style={{ display: 'inline-block', padding: '2mm' }} className="bays-print-view-minibay">
							<div style={{ textAlign: 'center' }} className="bays-item-title">
								bay {idx} {combinedBays && nonCollapsedBays[idx + 1] != null && <span>+ {idx + 1}</span>}
							</div>
							<MiniBay
								containerDiggingMap={containerDiggingMap}
								combinedBays={combinedBays}
								shifters={shifters}
								hasTemplate={hasTemplate}
								templateCells={templateCells}
								bay={bay}
								bayNumber={idx}
								inOutBound={inOutBound}
								polFilter={polFilter}
								betterHighlightForPolOnly={betterHighlightForPolOnly}
								selectedBay={null} />
						</div>;
					})}
				</div>
			</BayPrintPage>}

			{!overviewOnly && bays.map((bay, idx) => {
				if (selectedBayOnly != null && idx != selectedBayOnly) return null;

				// Dynamic width scaling calculations
				const calibratedWidth = 11; // This is what was designed against
				const bayWidth = Math.max(Math.max(Math.abs(Math.max(...bay.rows)), Math.abs(Math.min(...bay.rows))), 7);

				// Only wide bays, render it in two parts, on two consecutive pages
				if (splitBaysOnPrintIfWide && bayWidth > 10) {
					const splitBayWidth = Math.max(Math.ceil(bayWidth / 2), 8);
					return <>
						<BayPrintPage
							key={idx + 'L'}
							ports={ports}
							call={call}
							title={<>Bay &lsaquo;{idx} {combinedBays && nonCollapsedBays[idx + 1] != null && <span>+ &lsaquo;{idx + 1}</span>}</>}
							inOutBound={inOutBound}
							colorMode={colorMode}>
							<div style={{ zoom: calibratedWidth / splitBayWidth }}>
								<BigBay
									containerDiggingMap={containerDiggingMap}
									voyageData={voyageData}
									colorMode={colorMode}
									bay={bay}
									shifters={shifters}
									inOutBound={inOutBound}
									polFilter={polFilter}
									bayNumber={idx}
									hasTemplate={hasTemplate}
									templateCells={templateCells}
									dontShowNumbersOfEmptiesToBeLoaded={dontShowNumbersOfEmptiesToBeLoaded}
									viewPart="LEFT" />
							</div>
						</BayPrintPage>
						<BayPrintPage
							key={idx + 'R'}
							ports={ports}
							call={call}
							title={<>Bay {idx}&rsaquo; {combinedBays && nonCollapsedBays[idx + 1] != null && <span>+ {idx + 1}&rsaquo;</span>}</>}
							inOutBound={inOutBound}
							colorMode={colorMode}>
							<div style={{ zoom: calibratedWidth / splitBayWidth }}>
								<BigBay
									containerDiggingMap={containerDiggingMap}
									voyageData={voyageData}
									colorMode={colorMode}
									bay={bay}
									shifters={shifters}
									inOutBound={inOutBound}
									polFilter={polFilter}
									bayNumber={idx}
									hasTemplate={hasTemplate}
									templateCells={templateCells}
									dontShowNumbersOfEmptiesToBeLoaded={dontShowNumbersOfEmptiesToBeLoaded}
									viewPart="RIGHT" />
							</div>
						</BayPrintPage>
					</>;
				} else {
					return <BayPrintPage
						key={idx}
						ports={ports}
						call={call}
						title={<>Bay {idx} {combinedBays && nonCollapsedBays[idx + 1] != null && <span>+ {idx + 1}</span>}</>}
						inOutBound={inOutBound}
						colorMode={colorMode}>
						<div style={{ zoom: calibratedWidth / bayWidth }}>
							<BigBay
								containerDiggingMap={containerDiggingMap}
								voyageData={voyageData}
								colorMode={colorMode}
								bay={bay}
								shifters={shifters}
								inOutBound={inOutBound}
								polFilter={polFilter}
								bayNumber={idx}
								hasTemplate={hasTemplate}
								dontShowNumbersOfEmptiesToBeLoaded={dontShowNumbersOfEmptiesToBeLoaded}
								templateCells={templateCells}/>
						</div>
					</BayPrintPage>;
				}
			})}
		</div>
	</div>;
});

function BayPrintPage({ ports, call, title, inOutBound, children, colorMode }) {
	return <div className="bay-page" style={{ display: 'flex', alignItems: 'center', flexDirection: 'column', paddingTop: '12mm' }}>
		<div className="bay-page-info" style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', width: '20.2cm' }}>
			<div>
				Ship Name: {call.shipName}<br />
				Print Date: {DateTime.now().toLocal().toFormat('dd-MM-yyyy')}
			</div>
			<div style={{ textAlign: 'right' }}>
				Call Reference: {call.callReference}<br />
				Port Code: {call.portUnlo}
			</div>
		</div>
		<h2 style={{ marginBottom: '0', paddingBottom: '0' }}>{title}</h2>
		<span>{inOutBound == 'INBOUND' ? 'Inbound' : 'Outbound'}</span>
		<br />
		{children}
		<div style={{ position: 'fixed', bottom: '12mm', maxWidth: '100%' }}>
			<ColorsLegend ports={ports} colorMode={colorMode} inOutBound={inOutBound} />
		</div>
	</div>;
}

const css = `
@page {
	margin: 7mm;
}

.bays-print-view {
	zoom: 80%;
	display: block !important;
	print-color-adjust: exact;
}

.bays-print-view .rowtier-label {
	color: var(--col-grey-800);
	font-size: 11pt;
	font-weight: bold;
}

.bays-print-view-minibay .cell {
	width: 13px;
	height: 13px;
}

.bay-print-view .bay-large .cell {
    width: 63px;
}

.bays-print-view .bay-large .row {
    width: 62px;
}

.bays-print-view .bay-large .cell-height,
.bays-print-view .bay-large .cell {
    height: 44px;
}

.bays-print-view .bays-item-title {
	font-weight: bold;
	font-size: 13pt;
}

.bay-page {
	page-break-after: always;
}

.print-only {
	display: initial;
}
`;