import React, { useState } from 'react';
import { Button } from '@atrocit/scl';

export default function BaysOverview({ bays, inboundCells, outboundCells, baysNonCollapsed, polFilter }) {
	const [ isReverse, setIsReverse ] = useState(false);

	const reversedBays = Object.keys(baysNonCollapsed).reverse();

	return <div style={{ overflowX: 'scroll' }}>
		<Button active={isReverse} onClick={() => setIsReverse(!isReverse)} style={{ position: 'fixed', margin: 'var(--u-24) var(--u-24)' }}><span className="fa fa-exchange" style={{ transition: 'transform 200ms ease-in-out', transform: isReverse ? 'rotateY(180deg)' : 'rotateY(0deg)' }} />&nbsp; {!isReverse ? <>Bakboord aan wal</> : <>Stuurboord aan wal</>}</Button>
		<div style={{ marginTop: 'var(--u-256)', width: 'fit-content' }}>
			<div style={{ display: 'grid', gridTemplateColumns: "repeat( " + bays.length + ", 1fr)", alignItems: 'flex-end', padding: '0 var(--u-16)' }}>
				{!isReverse && <svg height="384" width="70">
					<polygon points="70 73, 70 328, 0 210" style={{ stroke: "var(--col-grey-800)", fill: "none" }} />
				</svg>}
				{(isReverse ? reversedBays : reversedBays.reverse()).map(idx => {
					const i = Number(idx);
					const isEvenBayNr = i % 2 == 0;
					const isPartOfFortyFtBay = baysNonCollapsed[i - 1] == null && baysNonCollapsed[i + 1] == null;
					if (isEvenBayNr) return <CombinedBays inboundCells={inboundCells} outboundCells={outboundCells} idx={i} isCombinedFortyFtBay={true} polFilter={polFilter} />;
					else if (isPartOfFortyFtBay) return <CombinedBays inboundCells={inboundCells} outboundCells={outboundCells} idx={i} isCombinedFortyFtBay={false} polFilter={polFilter} />;
				})}
				{isReverse && <svg style={{ marginLeft: '2px' }} height="384" width="70">
					<polygon points="0 73, 0 328, 70 210" style={{ stroke: "black", fill: "none" }} />
				</svg>}
			</div>
		</div>
	</div>;

	function CombinedBays({ inboundCells, outboundCells, idx, isCombinedFortyFtBay, polFilter }) {
		let inboundBays = inboundCells.filter((_, index) => (!isCombinedFortyFtBay
			? index == idx
			: (index == idx || index == idx - 1 || index == idx + 1))).reduce((sum, b) => sum.concat(b.cells), []);
		let outboundBays = outboundCells.filter((_, index) => (!isCombinedFortyFtBay
			? index == idx
			: (index == idx || index == idx - 1 || index == idx + 1))).reduce((sum, b) => sum.concat(b.cells), []);

		inboundBays = polFilter != null ? inboundBays.filter(ct => ct.container.portOfDischarge == polFilter) : inboundBays;
		outboundBays = polFilter != null ? outboundBays.filter(ct => ct.container.portOfLoading == polFilter) : outboundBays;

		const nrOfContainersInbound = inboundBays.length;
		const nrOfContainersOutbound = outboundBays.length;

		const nrOfContainersTwentyFtInbound = inboundBays.map(c => c.container.isoCode).filter(iso => iso != null && iso.length > 0 && iso[0] == '2').length;
		const nrOfContainersFortyFtInbound = inboundBays.map(c => c.container.isoCode).filter(iso => iso != null && iso.length > 0 && iso[0] == '4').length;
		const nrOfContainersOtherFtInbound = inboundBays.map(c => c.container.isoCode).filter(iso => iso == null || iso.length == 0 || (iso[0] != '2' && iso[0] != '4')).length;
		const nrOfContainersTwentyFtOutbound = outboundBays.map(c => c.container.isoCode).filter(iso => iso != null && iso.length > 0 && iso[0] == '2').length;
		const nrOfContainersFortyFtOutbound = outboundBays.map(c => c.container.isoCode).filter(iso => iso != null && iso.length > 0 && iso[0] == '4').length;
		const nrOfContainersOtherFtOutbound = outboundBays.map(c => c.container.isoCode).filter(iso => iso == null || iso.length == 0 || (iso[0] != '2' && iso[0] != '4')).length;

		return <div style={{ display: 'flex', flexDirection: 'column' }}>
			<div className="bays-item-title">
				<div style={{ display: 'flex', justifyContent: 'center' }}>
					BAY {isCombinedFortyFtBay && <>{idx - 1} / </>} {idx} {isCombinedFortyFtBay && <> / {idx + 1}</>}
				</div>
			</div>
			<div style={{ display: 'grid', gridTemplateRows: '1fr 1fr', height: 'var(--u-256)', width: isCombinedFortyFtBay ? 'var(--u-192)' : 'var(--u-128)', border: 'var(--u-2) solid var(--col-grey-700)', marginRight: '-2px' }}>
				<div style={{ display: 'grid', gridTemplateColumns: '1fr', background: (nrOfContainersInbound > 0 ? 'var(--col-white)' : 'var(--col-grey-200)') }}>
					<DisplayInfo label={polFilter == null ? 'INKOM. AAN BOORD' : "TE LOSSEN"} value={nrOfContainersInbound} />
					<div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr', borderBottom: 'var(--u-2) solid var(--col-grey-700)' }}>
						<DisplayInfo label="20FT" value={nrOfContainersTwentyFtInbound} smallNum={true} />
						<DisplayInfo label="40FT" value={nrOfContainersFortyFtInbound} smallNum={true} />
						<DisplayInfo label="??FT" value={nrOfContainersOtherFtInbound} smallNum={true} />
					</div>
				</div>
				<div style={{ display: 'grid', gridTemplateColumns: '1fr', background: (nrOfContainersOutbound > 0 ? 'var(--col-white)' : 'var(--col-grey-200)') }}>
					<DisplayInfo label={polFilter == null ? 'UITG. AAN BOORD' : "TE LADEN"} value={nrOfContainersOutbound} />
					<div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr' }}>
						<DisplayInfo label="20FT" value={nrOfContainersTwentyFtOutbound} smallNum={true} />
						<DisplayInfo label="40FT" value={nrOfContainersFortyFtOutbound} smallNum={true} />
						<DisplayInfo label="??FT" value={nrOfContainersOtherFtOutbound} smallNum={true} />
					</div>
				</div>
			</div>
			<div style={{ alignItems: 'stretch', textAlign: 'center', paddingTop: 'var(--u-16)' }}>
				<DisplayInfo label="Tot. moves" value={nrOfContainersInbound + nrOfContainersOutbound} />
			</div>
		</div>;
	}

	function DisplayInfo({ label, value, smallNum = false }) {
		return <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
			<span style={{ fontSize: 'var(--u-8)', fontWeight: 'bold', color: (value == 0 ? 'var(--col-grey-600)' : 'var(--col-primary-600)'), textTransform: 'uppercase' }}>{label}</span>
			<span style={{ fontSize: smallNum ? 'var(--u-20)' : 'var(--u-24)', opacity: value == 0 ? '0.4' : '1' }}>{value}</span>
		</div>;
	}
}
