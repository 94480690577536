import { useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import ButtonArea from '../lib/forms/ButtonArea';
import { gql, useLazyQuery, useMutation } from '@apollo/client';
import { Button, Form, HuePicker, InputField, LoadingOverlay, WithLabel } from '@atrocit/scl';

export default function EditPort() {
	const navigate = useNavigate();
	const { id } = useParams();

	const [ loading, setLoading ] = useState(true);
	const [ name, setName ] = useState('');
	const [ unlo, setUnlo ] = useState('');
	const [ hue, setHue ] = useState(0);

	const [ getPort ] = useLazyQuery(gql`query Query($id: ID!) { port(id: $id) { id, name, unlo, hue } }`);
	const [ createOrUpdatePort ] = useMutation(gql`mutation Mutation($id: ID, $name: String!, $unlo: String!, $hue: Int!) {
		createOrUpdatePort(id: $id, name: $name, unlo: $unlo, hue: $hue) { id }
	}`);

	useEffect(() => {
		if (id == null) {
			setLoading(false);
			setName('');
			setUnlo('');
			setHue(0);
		} else {
			getPort({ variables: { id } })
				.then(({ data }) => {
					setName(data?.port?.name);
					setUnlo(data?.port?.unlo);
					setHue(data?.port?.hue);
					setLoading(false);
				});
		}
	}, [ id ]);

	return <div className="app-ui">
		{loading && <LoadingOverlay />}
		<Form onSubmit={() => {
			createOrUpdatePort({ variables: { id, name, unlo, hue: Math.round(hue) } })
				.finally(() => navigate('/ports'));
		}}>
			<WithLabel label="Havennaam">
				<InputField onChange={setName} value={name} />
			</WithLabel>

			<WithLabel label="UN-locatiecode">
				<InputField onChange={setUnlo} value={unlo} placeholder="Bijv. BEANR" />
			</WithLabel>

			<WithLabel label="Kleurkeuze">
				<HuePicker value={hue} onChange={setHue} />
			</WithLabel>

			<ButtonArea>
				<Button level="primary" type="submit"><span className="fa fa-check" />&nbsp; Opslaan</Button>
			</ButtonArea>
		</Form>
	</div>;
}