// export function generateColorForHue(hue, lightness) {
// 	return 'hsl(' + (hue) + ', 100%, ' + ((lightness + (lightness * (1 - lightness) * hueLightnessCorrection(hue))) * 100) + '%)';
// }

export function generateHexColorForHue(hue, lightness) {
	const l = (lightness + (lightness * (1 - lightness) * hueLightnessCorrection(hue)));
	const a = Math.min(l, 1 - l);
	const f = n => {
		const k = (n + hue / 30) % 12;
		const color = l - a * Math.max(Math.min(k - 3, 9 - k, 1), -1);
		return Math.round(255 * color);   // convert to Hex and prefix "0" if needed
	};
	return (f(0) << 16) + (f(8) << 8) + f(4);
}

// function rgbFromHex(hex) {
// 	return {
// 		r: hex >> 16,
// 		g: hex >> 8 & 0xFF,
// 		b: hex & 0xFF,
// 	};
// }
//
// function perceivedLuminance(r, g, b) {
// 	return Math.sqrt(0.299 * Math.pow(r, 2) + 0.587 * Math.pow(g, 2) + 0.114 * Math.pow(b, 2));
// }
//
// // In the future we should probably write an interpolation function for this thing,
// //  but whatever, it's pretty good
export function hueLightnessCorrection(hue) {
	if (hue < 20) return 0.7;
	if (hue < 40) return 0.3;
	if (hue < 60) return 0.2;
	if (hue < 80) return 0;
	if (hue < 110) return 0.1;
	if (hue < 170) return 0.1;
	if (hue < 190) return 0.3;
	if (hue < 220) return 0.5;
	if (hue < 280) return 0.5;
	if (hue < 360) return 0.4;
	return 0;
}

function hslToRgb(hue, saturation, lightness) {
	const c = (1 - Math.abs(2 * lightness - 1)) * saturation;
	const x = c * (1 - Math.abs((hue / 60) % 2 - 1));
	const m = lightness - c / 2;
	let r = 0, g = 0, b = 0;

	if (hue >= 0 && hue < 60) {
		r = c;
		g = x;
		b = 0;
	} else if (hue >= 60 && hue < 120) {
		r = x;
		g = c;
		b = 0;
	} else if (hue >= 120 && hue < 180) {
		r = 0;
		g = c;
		b = x;
	} else if (hue >= 180 && hue < 240) {
		r = 0;
		g = x;
		b = c;
	} else if (hue >= 240 && hue < 300) {
		r = x;
		g = 0;
		b = c;
	} else if (hue >= 300 && hue < 360) {
		r = c;
		g = 0;
		b = x;
	}

	r = (r + m);
	g = (g + m);
	b = (b + m);
	return [ r, g, b ];
}

function perceivedLuminance(r, g, b) {
	return Math.sqrt(0.199 * Math.pow(r, 2) + 0.587 * Math.pow(g, 2) + 0.214 * Math.pow(b, 2));
}

function bezier(input, pullFactor) {
	// P0 = (0, 0), P1 = (0.5, pullFactor), P2 = (1, 1)
	// We calculate the Bezier curve at point lightness
	const t = input; // As lightness ranges from 0 to 1, it maps directly to t
	const P0 = 0, P2 = 1;
	const P1 = pullFactor; // Control the pulling effect towards brighter lightness

	// Applying the quadratic Bezier formula
	return (1 - t) * (1 - t) * P0 + 2 * (1 - t) * t * P1 + t * t * P2;
}

export function generateColorForHue(hue, lightness) {
	const adjustedLightness = bezier(lightness, 0.75);

	let saturation = 1; // Assuming full saturation for vivid colors
	let [ rInitial, gInitial, bInitial ] = hslToRgb(hue, saturation, adjustedLightness);
	// return `rgb(${rInitial * 255}, ${gInitial * 255}, ${bInitial * 255})`;
	let currentLuminance = perceivedLuminance(rInitial, gInitial, bInitial);
	const targetLuminance = adjustedLightness; // Define your target luminance here

	// Scale factor to adjust the RGB values to achieve the target luminance
	const [ r, g, b ] = hslToRgb(hue, saturation, targetLuminance * Math.sqrt(targetLuminance / currentLuminance));
	return `rgb(${r * 255}, ${g * 255}, ${b * 255})`;
}

export function strHash(str = '') {
	let hash = 0, chr;
	if (str != null) {
		if (str == null || str.length === 0) return hash;
		for (let i = 0; i < str.length; i++) {
			chr = str.charCodeAt(i);
			hash = ((hash << 5) - hash) + chr;
			hash |= 0; // Convert to 32bit integer
		}
	}
	return hash;
}

export const DEFAULT_PORT_LIGHTNESS = 0.78;
export const DARK_PORT_LIGHTNESS = 0.5;