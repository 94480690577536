import React from 'react';
import { DateTime } from 'luxon';
import { InputField } from '@atrocit/scl';

export default function DateTimeField({ onChange, value }) {
	const date = value.toFormat('yyyy-MM-dd');
	const time = value.toFormat('HH:mm');

	return <div style={{ display: 'grid', gridTemplateColumns: '2fr 1fr', gap: 'var(--u-16)' }}>
		<div>
			<InputField type="date" onChange={e => onChange(DateTime.fromISO(e + 'T' + time))} value={date} />
		</div>
		<div>
			<InputField type="time" onChange={e => onChange(DateTime.fromISO(date + 'T' + e))} value={time} />
		</div>
	</div>;
}