import Breadcrumbs from '../lib/Breadcrumbs';
import { gql, useMutation, useQuery } from '@apollo/client';
import { Link, useNavigate } from 'react-router-dom';
import React, { useState } from 'react';
import ImportShipTemplateModal from './viewShipTemplates/ImportShipTemplateModal';
import ReactTooltip from "react-tooltip";
import DeleteShipTemplateModal from "./viewShipTemplates/DeleteShipTemplateModal";
import { Button, LinkButton, LoadingOverlay } from '@atrocit/scl';
import ButtonArea from '../lib/forms/ButtonArea';


export default function ShipTemplatesOverview() {
	const [ importTemplateModalOpen, setImportTemplateModalOpen ] = useState(false);
	const [ deleteShipTemplateID, setDeleteShipTemplateID ] = useState(null);

	let [ sortingDirection, setSortingDirection ] = useState("DESC");

	const shipTemplatesQuery = useQuery(gql`query Query($sortingDirection: String) {
		shipTemplates(sortingDirection: $sortingDirection) {
			id, templateName, imoCode
		}
	}`, { variables: { sortingDirection: sortingDirection } });

	const shipTemplates = shipTemplatesQuery?.data?.shipTemplates ?? [];

	const [ createShipTemplateByButton ] = useMutation(gql`mutation Mutation($templateName: String) {
        createShipTemplateByButton(name: $templateName) { id }
    }`);
	const navigate = useNavigate();

	return <div className="app-ui">
		<ImportShipTemplateModal
			onClose={() => { setImportTemplateModalOpen(false); shipTemplatesQuery.refetch(); }}
			isOpen={importTemplateModalOpen} />

		{deleteShipTemplateID != null && <DeleteShipTemplateModal
			shipTemplateId={deleteShipTemplateID}
			isOpen={deleteShipTemplateID}
			onClose={() => {
				setDeleteShipTemplateID(null);
				shipTemplatesQuery.refetch();
			}} />}

		<Breadcrumbs segments={[
			{ link: '/shipTemplates', label: 'Scheepstemplates' },
			{ link: '/shipTemplates', label: 'Overzicht' },
		]} />
		<div>
			<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
				<ButtonArea>
					<Button onClick={() => createShipTemplateByButton().then(results => {
						navigate('/shipTemplates/' + results?.data?.createShipTemplateByButton?.id);
					})}><span className="fa fa-plus" />&nbsp; New template</Button>
					<Button onClick={() => setImportTemplateModalOpen(true)}><span className="fa fa-cloud-upload" />&nbsp; Importeer van SimpleStow</Button>
				</ButtonArea>
			</div>
			<br />
		</div>
		<table className="table">
			<colgroup>
				<col width="*" />
				<col width="120" />
				<col width="80" />
				<col width="44" />
			</colgroup>
			<thead>
				<tr style={{ userSelect: 'none' }}>
					<th onClick={() => setSortingDirection(sortingDirection == "DESC" ? "ASC" : "DESC")}><span className={sortingDirection == "DESC" ? "fa fa-caret-up" : "fa fa-caret-down"} /> Scheepsnaam</th>
					<th>IMO-nummer</th>
					<th />
					<th />
				</tr>
			</thead>
			<tbody>
				{shipTemplates.map(st => <tr key={st.id}>
					<td>{st.templateName}</td>
					<td>{st.imoCode}</td>
					<td style={{ padding: '0 var(--u-4)' }}>
						<LinkButton to={'/shipTemplates/' + st.id}>Openen</LinkButton>
					</td>
					<td style={{ display: 'flex', justifyContent: 'center' }}>
						<Button data-tip data-for='delete' onClick={() => { setDeleteShipTemplateID(st.id); }}><span className="fa fa-trash" /> </Button>
					</td>
					<ReactTooltip id="delete" place='bottom' effect='solid'>Verwijderen</ReactTooltip>
				</tr>)}
			</tbody>
		</table>
		{shipTemplatesQuery.loading && <LoadingOverlay />}
	</div>;
}