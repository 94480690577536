import React from "react";
import { prepContainerNumber } from './CreateContainerModal';
import { Button, Modal } from '@atrocit/scl';
import ButtonArea from '../../lib/forms/ButtonArea';

export default function WarningModal({ isOpen, onClose, doHighlightCell, warningList }) {
	return <Modal isOpen={isOpen} onRequestClose={onClose} title="Waarschuwingenlijst">
		<div>
			<table className='table'>
				{warningList != null && warningList.length > 0 ?
					<>
						<thead>
							<tr>
								<th>Bay</th>
								<th>Cell</th>
								<th>Containernr</th>
								<th>Probleem</th>
								<th />
							</tr>
						</thead>

						<tbody>
							{warningList.map(({ type, cell }, idx) => <tr key={idx}>
								<td>{cell.bay}</td>
								<td>{cell.cellCode}</td>
								<td>{prepContainerNumber(cell.container.equipmentIdentifier)}</td>
								<td>{warningTypeToDescription(type)}</td>
								<Button className="fa fa-crosshairs" onClick={() => doHighlightCell(cell.cellCode)} />
							</tr>)}
						</tbody>
					</>
					:
					<span>
						Er zijn geen waarschuwingen.
					</span>
				}
			</table>
		</div>
		<ButtonArea>
			<Button level="primary" onClick={onClose}><span className="fa fa-check"/>&nbsp; Sluiten</Button>
		</ButtonArea>
	</Modal>;
}

function warningTypeToDescription(type) {
	return {
		'Floating': 'Zwevende container',
		'KilledSlot': 'Container overlapt met overgroottes',
		'TwentyOverForty': '20ft op 40ft gestackt',
	}[type] || 'Onbekend probleem';
}