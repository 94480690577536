import { Button, Form, InputField, LoadingOverlay, WithLabel } from '@atrocit/scl';
import Modal from '../../lib/Modal';
import { gql, useLazyQuery, useMutation } from '@apollo/client';
import { useEffect, useState } from 'react';
import ButtonArea from '../../lib/forms/ButtonArea';

export default function AttachedEquipmentEditModal({ isOpen, onClose, containerId }) {
	const [ container, setContainer ] = useState(null);
	const [ attachedEquipment, setAttachedEquipment ] = useState([]);
	const [ loading, setLoading ] = useState(true);

	const [ containerQuery ] = useLazyQuery(gql`query Query($containerId: Int!) {
        container(containerId: $containerId) {
            id,
	        isEmpty,
	        isoCode,
	        equipmentIdentifier,
	        attachedEquipment,
        }
    }`);

	const [ updateAttachedEquipmentForContainer ] = useMutation(gql`mutation Mutation($containerId: Int!, $attachedEquipment: [ String! ]!) {
        updateAttachedEquipmentForContainer(containerId: $containerId, attachedEquipment: $attachedEquipment) {
            id,
            equipmentIdentifier,
            attachedEquipment
        }
    }`);

	useEffect(() => {
		setLoading(true);
		containerQuery({ variables: { containerId } })
			.then(({ data }) => {
				setLoading(false);
				setContainer(data?.container);
				setAttachedEquipment(data?.container?.attachedEquipment ?? []);
			});
	}, [ containerId, isOpen ]);

	return <Modal isOpen={isOpen} title="Gekoppelde flatracks" onClose={onClose}>
		{loading && <LoadingOverlay />}
		<Form onSubmit={() => {
			setLoading(true);
			updateAttachedEquipmentForContainer({ variables: { containerId, attachedEquipment } })
				.finally(onClose);
		}}>
			<div style={{ display: 'flex', flexDirection: 'column', gap: 'var(--u-8)', minWidth: '500px' }}>
				<div style={{ display: 'grid', gridTemplateColumns: '1fr 48px', gap: 'var(--u-8)' }}>
					<div>{container?.equipmentIdentifier}</div>
					<div />
				</div>
				{attachedEquipment.map((ae, idx) => <div key={idx} style={{ display: 'grid', gridTemplateColumns: '1fr 48px', gap: 'var(--u-8)' }}>
					<div>
						<InputField onChange={e => setAttachedEquipment(attachedEquipment.map((oae, eIdx) => (eIdx == idx ? e : oae)))} value={ae} />
					</div>
					<div>
						<Button onClick={() => setAttachedEquipment(attachedEquipment.filter((oae, eIdx) => eIdx != idx))}><span className="fa fa-trash" /></Button>
					</div>
				</div>)}
			</div>

			<div style={{ padding: 'var(--u-16) 0', display: 'flex', justifyContent: 'flex-end' }}>
				<Button onClick={() => setAttachedEquipment(aes => [ ...aes, '' ])}><span className="fa fa-plus" />&nbsp; Flatrack toevoegen</Button>
			</div>

			<ButtonArea>
				<Button level="primary" type="submit"><span className="fa fa-check" />&nbsp; Opslaan</Button>
				<Button level="secondary" onClick={onClose}>Annuleren</Button>
			</ButtonArea>
		</Form>
	</Modal>;
}