import React, { useEffect, useMemo, useState } from 'react';
import { gql, useMutation, useQuery } from '@apollo/client';
import { useNavigate, useParams } from "react-router-dom";
import SelectableMiniBay from '../../calls/viewcall/SelectableMiniBay';
import BigBay, { toCellCode } from '../../calls/viewcall/bayplanning/BigBay';
import { cellCodeMapLookup } from '../../calls/viewcall/ViewCall';
import ReactTooltip from 'react-tooltip';
import usePersistentState from '../../../hooks/usePersistentState';
import ViewCallSettingsModal from '../../calls/viewcall/ViewCallSettingsModal';
import NavigateAwayPrompt from '../../lib/forms/NavigateAwayPrompt';
import { Button, InputField, LoadingOverlay, WithLabel } from '@atrocit/scl';


export default function EditShipTemplate() {
	const { id } = useParams();
	const navigate = useNavigate();

	const [ selectedBay, setSelectedBay ] = useState(null);
	const [ viewSettingsModalOpen, setViewSettingsModalOpen ] = useState(false);

	const [ savingShipTemplate, setSavingShipTemplate ] = useState(false);
	const [ combinedBays, setCombinedBays ] = usePersistentState(true, 'combinedBays');

	const shipTemplateQuery = useQuery(gql`query Query($id: Int!) {
		shipTemplate(id: $id)  {
			id,
			templateName,
			imoCode,
			cells {
				id,
				bay, row, tier,
				hasReeferPlug,
				forceTwentyFeet,
				cellCode
			}
		}
	}`, { variables: { id } });

	const [ updateShipTemplate ] = useMutation(gql`
		mutation Mutation($shipTemplateId: Int!, $name: String, $imoCode: String, $updates: [ ShipCellPartialUpdate! ]!) {
			updateShipTemplate(shipTemplateId: $shipTemplateId, name: $name, imoCode: $imoCode, updates: $updates) { id }
		}
	`);
	const [ shipTemplate, setShipTemplate ] = useState(null);
	const hasShipTemplate = shipTemplateQuery?.data?.shipTemplate != null;
	const hasCells = shipTemplate?.cells != null;
	const cells = shipTemplate?.cells ?? [];

	const editingMode = true;
	const [ changes, setChanges ] = useState(false);
	const templateCells = useMemo(() => cellCodeMapLookup(shipTemplate?.cells ?? []), [ shipTemplate?.cells ]);
	const baysNonCollapsed = useMemo(() => cellsToBays(cells, shipTemplate?.cells, false, editingMode), [ cells, shipTemplate?.cells, addBay, addCell ]);
	const bays = useMemo(() => cellsToBays(cells, shipTemplate?.cells, combinedBays), [ cells, combinedBays, shipTemplate?.cells, addBay, addCell ]);

	useEffect(() => {
		setShipTemplate({ ...shipTemplateQuery?.data?.shipTemplate });
	}, [ shipTemplateQuery?.data?.shipTemplate ]);

	useEffect(() => {
		if (selectedBay == null) return;
		if (bays[selectedBay] == null) setSelectedBay(null);
	}, [ selectedBay, bays ]);

	function shipTemplateUpdate(newShipTemplate) {
		setShipTemplate(newShipTemplate);
		setChanges(true);
	}

	function addCell(bayNumber, row, tier) {
		if (bayNumber % 2 == 0) {
			const bayNumbers = [ bayNumber - 1, bayNumber, bayNumber + 1 ];
			const updatedCells = [];
			for (let j = 0; j < bayNumbers.length; j++) {
				if (bays.indexOf(bayNumbers[j])) {
					updatedCells.push({
						id: null,
						bay: bayNumbers[j],
						row: row,
						tier: tier,
						hasReeferPlug: false,
						forceTwentyFeet: false,
						cellCode: toCellCode(bayNumbers[j], row, tier),
					});
				}
			}
			const newShipTemplate = { ...shipTemplate, cells: shipTemplate.cells.concat(updatedCells) };
			shipTemplateUpdate(newShipTemplate);
		} else {
			const newShipTemplate = { ...shipTemplate,
				cells: [ ...shipTemplate.cells, {
					id: null,
					bay: bayNumber,
					row: row,
					tier: tier,
					hasRefeerPlug: false,
					forceTwentyFeet: false,
					cellCode: toCellCode(bayNumber, row, tier),
				} ],
			};
			shipTemplateUpdate(newShipTemplate);
		}
	}

	function deleteBay(bayNumber) {
		let nextNumber = bayNumber;
		nextNumber++;
		let previousNumber = bayNumber;
		previousNumber--;
		let newCells = [];
		if (nextNumber % 2 == 0) {
			newCells = shipTemplate.cells.filter(c => c.cellCode.slice(3 - previousNumber.toString().length, 3) !== previousNumber.toString() && c.cellCode.slice(3 - bayNumber.toString().length, 3) !== bayNumber.toString() && c.cellCode.slice(3 - nextNumber.toString().length, 3) !== nextNumber.toString());
		} else {
			newCells = shipTemplate.cells.filter(c => c.cellCode.slice(3 - bayNumber.toString().length, 3) !== bayNumber.toString() && c.cellCode.slice(3 - previousNumber.toString().length, 3) !== previousNumber.toString());
		}
		const newShipTemplate = { ...shipTemplate, cells: [ ...newCells ] };
		shipTemplateUpdate(newShipTemplate);
	}

	function removeNext2Cells(bayNumber, row, tier) {
		shipTemplateUpdate({ ...shipTemplate, cells: shipTemplate.cells.filter(c => (c.cellCode !== toCellCode(bayNumber - 1, row, tier) && c.cellCode !== toCellCode(bayNumber, row, tier) && c.cellCode !== toCellCode(bayNumber + 1, row, tier) && c.cellCode !== toCellCode(bayNumber + 2, row, tier))) });
	}

	function addAnother2Cells(bayNumber, row, tier) {
		const bayNumbers = [ bayNumber, bayNumber + 1, bayNumber + 2 ];
		const updatedCells = [];
		for (let j = 0; j < bayNumbers.length; j++) {
			if (bays.indexOf(bayNumbers[j])) {
				updatedCells.push({
					id: null,
					bay: bayNumbers[j],
					row: row,
					tier: tier,
					hasReeferPlug: false,
					forceTwentyFeet: false,
					cellCode: toCellCode(bayNumbers[j], row, tier),
				});
			}
		}
		const newShipTemplate = { ...shipTemplate, cells: shipTemplate.cells.concat(updatedCells) };
		shipTemplateUpdate(newShipTemplate);
	}
	function updateCellsBay(bayNumbers, newCells) {
		const updatedCells = [];
		for (let j = 0; j < bayNumbers.length; j++) {
			if (bays.indexOf(bayNumbers[j])) {
				for (let i = 0; i < newCells.length; i++) {
					updatedCells.push({ ...newCells[i], bay: bayNumbers[j], id: null, cellCode: toCellCode(bayNumbers[j], newCells[i].row, newCells[i].tier) });
				}
			}
		}
		const newShipTemplate = { ...shipTemplate, cells: shipTemplate.cells.concat(updatedCells) };
		shipTemplateUpdate(newShipTemplate);
	}

	function addBay() {
		updateCellsBay(addToBays(bays.length + 1, combinedBays), (bays.length > 0) ? bays[bays.length - 1].cells : addCell(1, 0, 2));
	}

	function mapCells(cellsToMap) {
		return cellsToMap.map(cell => ({
			shipCellId: cell.id,
			bay: cell.bay,
			row: cell.row,
			tier: cell.tier,
			hasReeferPlug: cell.hasReeferPlug,
			forceTwentyFeet: cell.forceTwentyFeet,
			cellCode: cell.cellCode,
		}));
	}

	function saveChanges() {
		const updates = mapCells(cells);
		setSavingShipTemplate(true);
		return updateShipTemplate({ variables: { shipTemplateId: shipTemplate?.id, name: shipTemplate.templateName, imoCode: shipTemplate.imoCode, updates } })
			.finally(() => { setSavingShipTemplate(false); });
	}

	function saveChangesWithRedirect() {
		saveChanges().then(() => navigate('/shipTemplates'));
	}

	return <div style={{ display: 'flex', flexDirection: 'column', maxHeight: '100vh' }}>
		{shipTemplate?.cells != null && changes == true && savingShipTemplate == false && <NavigateAwayPrompt onSave={() => saveChanges() }/>}
		{(savingShipTemplate || shipTemplateQuery.loading) && <LoadingOverlay />}
		{shipTemplate != null && <>
			<ViewCallSettingsModal
				isOpen={viewSettingsModalOpen}
				combinedBays={combinedBays}
				setCombinedBays={setCombinedBays}
				onClose={() => { setViewSettingsModalOpen(false); }} />
			<div className="toolbar">
				<div className="toolbar-description">
					<div style={{ display: 'flex', flexDirection: 'row', gap: 'var(--u-8)', alignItems: 'center' }}>
						<div style={{ maxHeight: '44px', marginTop: '-6px', display: 'flex', gap: 'var(--u-16)' }}>
							<WithLabel label="Scheepsnaam">
								<InputField onChange={templateName => { setShipTemplate(st => ({ ...st, templateName: templateName })); setChanges(true); }} value={shipTemplate.templateName} style={{ height: '24px', marginTop: '-4px' }} />
							</WithLabel>
							<WithLabel label="IMO-code">
								<InputField onChange={imo => { setShipTemplate(st => ({ ...st, imoCode: imo })); setChanges(true); }} value={shipTemplate.imoCode} placeholder="0000000" style={{ height: '24px', marginTop: '-4px' }} />
							</WithLabel>
						</div>
					</div>
				</div>
				<div className="toolbar-controls">
					<div style={{ display: 'flex', flexDirection: 'row', gap: 'var(--u-8)', alignItems: 'center' }}>
						<Button onClick={saveChangesWithRedirect} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>Opslaan</Button>
						<Button onClick={() => setViewSettingsModalOpen(true)} data-tip data-for='settings'><span className="fa fa-cogs" /></Button>
						<ReactTooltip id="settings" place='left' effect='solid'>Instellingen</ReactTooltip>
					</div>
				</div>
			</div>
			{(hasShipTemplate || hasCells) && <div style={{ display: 'flex', flexDirection: 'row', flex: 1, overflow: 'hidden' }}>
				<div style={{ display: 'flex', flexDirection: 'column', width: '350px', maxHeight: '100%' }}>
					<div className="bays" style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', overflowY: 'auto', padding: 'var(--u-8)', borderRight: (bays.length !== 0) ? '1px solid var(--col-grey-200)' : 'none' }}>
						{bays.map((bay, index) => <SelectableMiniBay
							key={index}
							hasTemplate={shipTemplate}
							templateCells={templateCells}
							selectedBay={selectedBay}
							bay={bay}
							bayNumber={index}
							onSelect={() => setSelectedBay(index)}
							baysNonCollapsed={baysNonCollapsed}
							combinedBays={combinedBays}
							editingMode={editingMode}
							deleteBay={deleteBay} />)}

					</div>
					{editingMode && <div style={{ display: 'flex', flexDirection: 'initial', padding: '10px 10px 10px 40px', borderTop: (bays.length !== 0) ? '1px solid var(--col-grey-200)' : 'none' }}>
						<Button onClick={addBay}>Nieuwe bay</Button>
					</div>}
				</div>
				{selectedBay != null && bays[selectedBay] != null && <div style={{ padding: 'var(--u-16)', flex: '1 1 auto', overflow: 'auto', display: 'flex' }}>
					<div style={{ margin: 'auto' }}>
						<BigBay
							addCell={addCell}
							hasTemplate={shipTemplate}
							templateCells={templateCells}
							bay={bays[selectedBay]}
							bayNumber={selectedBay}
							combinedBays={combinedBays}
							editingMode={editingMode}
							removeNext2Cells={removeNext2Cells}
							addAnother2Cells={addAnother2Cells}/>
					</div>
				</div>}
			</div>}
		</>}
	</div>;
}
function addToBays(bayNr, combinedBays) {
	if (combinedBays) {
		if (bayNr % 2 == 0) {
			return [ bayNr - 1, bayNr + 1 ];
		} else {
			return [ bayNr ];
		}
	} else {
		return [ bayNr ];
	}
}

export function cellsToBays(cells, templateCells, combinedBays = false) {
	const bays = [];

	for (let i = 0; i < cells.length; i++) {
		const cell = cells[i];

		const baysToAdd = addToBays(cell.bay, combinedBays);
		baysToAdd.forEach(bay => {
			if (bays[bay] == null) {
				bays[bay] = {
					cells: [],
					rows: new Set(),
					tiers: new Set(),
				};
			}
			bays[bay].cells.push(cell);
			bays[bay].rows.add(cell.row);
			bays[bay].tiers.add(cell.tier);
		});
	}

	// Skip the rest of the bay construction if no template known
	if (templateCells == null) return bays;

	// Determine the bounds of each bay to render
	for (let i = 0; i < templateCells.length; i++) {
		const cell = templateCells[i];
		const baysToAdd = addToBays(cell.bay, combinedBays);
		baysToAdd.forEach(bay => {
			if (bays[bay] == null) {
				bays[bay] = {
					cells: [],
					rows: new Set(),
					tiers: new Set(),
				};
			}
			bays[bay].rows.add(cell.row);
			bays[bay].tiers.add(cell.tier);
		});
	}

	return bays;
}