import { useMemo } from 'react';
import { DiggingCalculationsProvider, useBayExclusionSets } from '../../../viewcall/digging/DiggingCalculationsProvider';
import DisplayDuration from '../../DisplayDuration';

export default function ProposedLoadingOrder({ stowagePlan, operationalPlan, bays, containerDiggingMap }) {
	const baysWithDependencies = useMemo(() => {
		if (stowagePlan == null || bays == null || containerDiggingMap == null) return [];

		const containersToBayId = {};
		for (const bay of bays) {
			for (const cell of bay.loadingCells) {
				if (cell.container == null || cell.container.equipmentIdentifier == null || cell.container.equipmentIdentifier == '') continue;
				containersToBayId[cell.container.equipmentIdentifier] = bay.id;
			}
		}

		const baysWithBlockedBy = bays.map(bay => {
			const baysBlocking = bay.loadingCells
				.filter(c => c.container != null && c.container.equipmentIdentifier != null && c.container.equipmentIdentifier != '')
				.map(c => containerDiggingMap[c.container.equipmentIdentifier])
				.filter(c => c != null)
				.flatMap(c => c.blocking)
				.map(cnum => containersToBayId[cnum.trim()]);

			return {
				...bay,
				blockedBy: baysBlocking.filter(b => b != bay.id && b != null).reduce((perBay, bayId) => ({ ...perBay, [bayId]: (perBay[bayId] ?? 0) + 1 }), {}),
			};
		});

		const baysWithBlocking = baysWithBlockedBy.map(bay => ({
			...bay,
			blocking: baysWithBlockedBy
				.filter(b => b.id != bay.id)
				.map(b => [ b.id, b.blockedBy[bay.id] ?? 0 ])
				.filter(([ bayId, count ]) => count > 0)
				.reduce((tot, [ bayId, count ]) => ({ ...tot, [bayId]: count }), {}),
		}));

		// console.log(baysWithBlocking);
		return baysWithBlocking.map(bay => ({
			...bay,
			belowDeckBays: bay.baySection != 'ABOVE_DECK' ? [] : baysWithBlocking.filter(b => b.bayNumber == bay.bayNumber && b.baySection == 'BELOW_DECK' && b.loadingCells.length > 0),
		}));
	}, [ bays, containerDiggingMap, stowagePlan ]);

	function calcScore(bay, bayIdsToExclude) {
		// Assume we finished any stuff below deck
		const myBaysToExclude = new Set([ ...bayIdsToExclude, (bay.belowDeckBays ?? []).map(b => b.id) ]);

		const blocking = Object.entries(bay.blocking)
			.filter(([ bayId, _ ]) => !myBaysToExclude.has(bayId))
			.map(([ _, count ]) => count)
			.reduce((tot, n) => tot + n, 0);

		const blockedBy = Object.entries(bay.blockedBy)
			.filter(([ bayId, _ ]) => !myBaysToExclude.has(bayId))
			.map(([ _, count ]) => count)
			.reduce((tot, n) => tot + n, 0);

		const scoreOfUnprocessedBelowDeckBays = (bay.belowDeckBays ?? []).filter(b => !bayIdsToExclude.has(b.id)).map(b => calcScore(b, bayIdsToExclude)).reduce((tot, n) => tot + n, 0) ?? 0;
		return blocking - blockedBy + scoreOfUnprocessedBelowDeckBays;
	}

	function generateBayOrder(bays) {
		const bayIdsHandled = new Set();
		const bayHandlingOrder = [];
		while (bays.length > 0) {
			let bestBays = [];
			let bestScore = -Infinity;
			for (let i = 0; i < bays.length; i++) {
				const score = calcScore(bays[i], bayIdsHandled);
				if (score > bestScore) {
					bestBays = [ bays[i] ];
					bestScore = score;
				} else if (score == bestScore) {
					bestBays.push(bays[i]);
				}
			}

			const lastHandledBayNr = bayHandlingOrder[bayHandlingOrder.length - 1]?.bayNumber ?? 0;
			const bestBay = bestBays.toSorted((a, b) => {
				const distanceToLastBayA = Math.abs(a.bayNumber - lastHandledBayNr);
				const distanceToLastBayB = Math.abs(b.bayNumber - lastHandledBayNr);
				if (distanceToLastBayA == distanceToLastBayB) {
					if (a.baySection == 'ABOVE_DECK' && b.baySection == 'BELOW_DECK') return -1;
					if (a.baySection == 'BELOW_DECK' && b.baySection == 'ABOVE_DECK') return 1;
					return 0;
				}
				return distanceToLastBayA - distanceToLastBayB;
			})[0];

			// Add any relevant bays below deck
			// We should do this recursively if this was a generic dependency issue, but we're not going to do that rn
			bestBay.belowDeckBays.filter(b => !bayIdsHandled.has(b.id)).forEach(b => {
				bayHandlingOrder.push(b);
				bayIdsHandled.add(b.id);
				bays.splice(bays.findIndex(bay => bay.id == b.id), 1);
			});

			// Add the bay itself to the ordering
			bayHandlingOrder.push(bestBay);
			bayIdsHandled.add(bestBay.id);
			bays.splice(bays.findIndex(bay => bay.id == bestBay.id), 1);
		}

		return bayHandlingOrder;
	}

	const orderedBays = generateBayOrder(baysWithDependencies.filter(bay => bay.operationType != 'DISCHARGING' && bay.loadingCells.length > 0));
	const actualBayExclusionSets = useBayExclusionSets();

	return <div style={{ display: 'flex', flexDirection: 'column', gap: 'var(--u-8)', width: 'var(--u-512)' }}>
		<DiggingCalculationsProvider stowagePlan={stowagePlan} operationalPlan={operationalPlan} customBayOrder={orderedBays.map(b => b.id)} containerDiggingMap={containerDiggingMap}>
			{orderedBays.map((bay, idx, bays) => <BayLoadingOrderProposal key={idx} previousSameBay={bays[idx - 1]?.bayNumber == bay.bayNumber} bay={bay} containerDiggingMap={containerDiggingMap} actualBayExclusionSets={actualBayExclusionSets} />)}
		</DiggingCalculationsProvider>
	</div>;
}

function BayLoadingOrderProposal({ previousSameBay, bay, containerDiggingMap, actualBayExclusionSets }) {
	const actualBayExclusionSet = useMemo(() => (actualBayExclusionSets[bay.bayNumber] ?? {})[bay.baySection] ?? new Set(), [ actualBayExclusionSets, bay ]);
	const actualDiggingMoves = useMemo(() => {
		return bay.loadingCells
			.filter(c => c.container != null && c.container.equipmentIdentifier != null && c.container.equipmentIdentifier != '')
			.map(c => containerDiggingMap[c.container.equipmentIdentifier])
			.filter(c => c != null)
			.map(c => c.blocking.filter(cnum => !actualBayExclusionSet.has(cnum.trim())));
	}, [ bay, actualBayExclusionSet, containerDiggingMap ]);

	const proposedBayExclusionSets = useBayExclusionSets();
	const proposedBayExclusionSet = useMemo(() => (proposedBayExclusionSets[bay.bayNumber] ?? {})[bay.baySection] ?? new Set(), [ proposedBayExclusionSets, bay ]);
	const proposedDiggingMoves = useMemo(() => {
		return bay.loadingCells
			.filter(c => c.container != null && c.container.equipmentIdentifier != null && c.container.equipmentIdentifier != '')
			.map(c => containerDiggingMap[c.container.equipmentIdentifier])
			.filter(c => c != null)
			.map(c => c.blocking.filter(cnum => !proposedBayExclusionSet.has(cnum.trim())));
	}, [ bay, proposedBayExclusionSet, containerDiggingMap ]);

	const proposedLoadPlusDigging = bay.loadingCells.length + proposedDiggingMoves.reduce((tot, n) => tot + n.length, 0);
	const actualLoadPlusDigging = bay.loadingCells.length + actualDiggingMoves.reduce((tot, n) => tot + n.length, 0);
	const diff = proposedLoadPlusDigging - actualLoadPlusDigging;

	return <div style={{ background: 'var(--col-white)', display: 'grid', gridTemplateColumns: '32px min-content 1fr 1.5fr max-content', alignItems: 'center', padding: 'var(--u-4) 0', lineHeight: '1.2em', boxShadow: '0 2px 4px rgba(0, 0, 0, 0.05), 0 2px 16px rgba(0, 0, 0, 0.04)', marginTop: previousSameBay ? '-7px' : '0' }}>
		<div style={{ textAlign: 'center' }}>
			<span style={{ textTransform: 'uppercase', fontSize: '0.8em', color: 'var(--col-grey-400)' }}>Bay</span><br />
			<span style={{ color: 'var(--col-grey-700)', fontWeight: 'bold', fontSize: '1.2em' }}>{bay.bayNumber}</span>
		</div>
		<div style={{ paddingRight: 'var(--u-24)', display: 'flex', flexDirection: 'column', gap: 'var(--u-2)', paddingTop: 'var(--u-2)', justifyContent: 'center' }}>
			<span className="fa fa-arrow-up" title="Bovendeks" style={{ fontSize: '1em', color: bay.baySection == 'ABOVE_DECK' ? 'var(--col-grey-500)' : 'var(--col-grey-100)', marginTop: '-1px' }} />
			<span className="fa fa-arrow-down" title="Onderdeks" style={{ fontSize: '1em', color: bay.baySection == 'BELOW_DECK' ? 'var(--col-grey-700)' : 'var(--col-grey-100)', marginTop: '-1px' }} />
		</div>
		<div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', paddingRight: 'var(--u-32)' }}>
			{bay.movesDischarge > 0 && <div style={{ display: 'flex', gap: 'var(--u-8)', alignItems: 'baseline', fontSize: '1.2em' }} title="Lossing">
				<span style={{ display: 'inline-block', minWidth: '4ch', textAlign: 'right' }}>{bay.movesDischarge}<span style={{ opacity: 0.5, fontSize: '0.8em' }}>&times;</span></span>
				<span className="fa fa-arrow-circle-down" style={{ color: 'var(--col-primary-400)' }} />
				<span style={{ color: 'var(--col-primary-400)' }}>Lossen</span>
			</div>}

			{bay.movesLoading > 0 && <div style={{ display: 'flex', gap: 'var(--u-8)', alignItems: 'baseline', fontSize: '1.2em' }} title="Lading">
				<span style={{ display: 'inline-block', minWidth: '4ch', textAlign: 'right' }}>{bay.movesLoading}<span style={{ opacity: 0.5, fontSize: '0.8em' }}>&times;</span></span>
				<span className="fa fa-arrow-circle-up" style={{ color: 'var(--col-primary-600)' }} />
				<span style={{ color: 'var(--col-primary-600)' }}>Laden</span>
			</div>}
		</div>
		<div>
			{bay.movesDischarge > 0 && <div style={{ display: 'flex', gap: 'var(--u-8)', alignItems: 'baseline', fontSize: '1.2em' }} title="Lossing">
				<span style={{ display: 'inline-block', minWidth: '4ch', textAlign: 'right' }}>{bay.dischargeCells.length}<span style={{ opacity: 0.5, fontSize: '0.8em' }}>&times;</span></span>
				<span className="fa fa-arrow-circle-down" style={{ color: 'var(--col-primary-400)' }} />
				<span style={{ color: 'var(--col-primary-400)' }}>Wegzetten</span>
			</div>}

			{bay.movesLoading > 0 && <div style={{ display: 'flex', gap: 'var(--u-8)', alignItems: 'baseline', fontSize: '1.2em', justifyContent: 'flex-end', paddingRight: 'var(--u-16)' }} title="Lading">
				<span style={{ display: 'inline-block', textAlign: 'right' }}>{proposedLoadPlusDigging}<span style={{ opacity: 0.5, fontSize: '0.8em' }}>&times;</span> <span style={{ display: 'inline-block', fontSize: '0.8em', width: '3ch', color: diff > 0 ? 'var(--col-red-500)' : (diff == 0 ? 'var(--col-grey-500)' : 'var(--col-green-500)') }}>{diff >= 0 ? '+' : ''}{diff}</span></span>
				<span className="fa fa-arrow-circle-up" style={{ color: 'var(--col-primary-600)' }} />
				<span style={{ color: 'var(--col-primary-600)' }}>Uitgraven</span>
			</div>}
		</div>
		<div style={{ textAlign: 'right', paddingRight: 'var(--u-16)' }}>
			<span className="fa fa-clock-o" />&nbsp; <span style={{ fontWeight: 'bold', color: 'var(--col-grey-700)' }}><DisplayDuration value={(bay.dischargeTime + bay.loadingTime)} /></span>
		</div>
	</div>;
}