import Modal from '../../lib/Modal';
import { gql, useMutation } from "@apollo/client";
import React from 'react';
import ButtonArea from "../../lib/forms/ButtonArea";
import { Button } from '@atrocit/scl';

export default function DeleteShipTemplateModal({ shipTemplateId, isOpen, onClose }) {

	const [ deleteShipTemplate ] = useMutation(gql`mutation Mutation($shipTemplateId: Int!) {
		deleteShipTemplate(shipTemplateId: $shipTemplateId)
    }`);

	return <Modal isOpen={isOpen} onClose={onClose} title="Scheepstemplate verwijderen?">
		<div style= {{ maxWidth: 'var(--u-384)' }} >
            Weet je zeker dat je dit scheepstemplate wilt verwijderen? Deze actie kan niet ongedaan worden gemaakt.
		</div>
		<ButtonArea>
			<Button
				style={{ background: 'var(--col-red)', color: 'white' }}
				onClick={() => deleteShipTemplate({ variables: { shipTemplateId: shipTemplateId } }).finally(onClose)}>
				Verwijderen
			</Button>
			<Button onClick={onClose}>Annuleren</Button>
		</ButtonArea>
	</Modal>;
}