import { useCallback, useEffect, useState } from 'react';
import ButtonArea from '../../lib/forms/ButtonArea';
import FileDropInput from '../../lib/forms/FileDropInput';
import ValidatedButton from '../../lib/forms/ValidatedButton';
import { gql, useMutation } from '@apollo/client';
import { BigMultiSelector, Button, Form, LoadingOverlay, Modal, WarningBanner } from '@atrocit/scl';

export default function ImportBaplieModal({ planId, portUnlo, isOpen, onClose, onDone, hasContainersInbound, hasContainersOutbound }) {
	const [ step, setStep ] = useState('TYPE_SELECTOR');
	const [ type, setType ] = useState(null);

	const [ file, setFile ] = useState(null);

	const [ baplieVariables, setBaplieVariables ] = useState(null);

	const showWarning = (type == 'INBOUND' && hasContainersInbound) || (type == 'OUTBOUND' && hasContainersOutbound);

	const [ replaceIncomingBaplie ] = useMutation(gql`mutation Mutation($planId: Int!, $baplie: String!) {
        replaceIncomingBaplie(planId: $planId, baplie: $baplie) { id }
    }`);

	const [ replaceOutgoingBaplie ] = useMutation(gql`mutation Mutation($planId: Int!, $baplie: String!) {
        replaceOutgoingBaplie(planId: $planId, baplie: $baplie) { id }
    }`);

	// Reset form on close of modal
	useEffect(() => {
		if (isOpen) return;
		setStep('TYPE_SELECTOR');
		setType(null);
		setFile(null);
	}, [ isOpen ]);

	const process = useCallback(() => {
		setStep('PROCESSING');
		if (file == null) return;
		const fr = new FileReader();
		fr.onload = () => {
			const baplie = fr.result;
			if (type == 'INBOUND') {
				if (baplie.includes('LOC+9+' + portUnlo) || baplie.includes('LOC+6+' + portUnlo) || baplie.includes('LOC+5+' + portUnlo)) {
					setStep('WARN_ABOUT_WRONG_TYPE');
					setBaplieVariables({ variables: { planId, baplie } });
				} else {
					replaceIncomingBaplie({ variables: { planId, baplie } }).finally(onClose);
				}
			} else {
				if (baplie.includes('LOC+11+' + portUnlo) || baplie.includes('LOC+12+' + portUnlo)) {
					setStep('WARN_ABOUT_WRONG_TYPE');
					setBaplieVariables({ variables: { planId, baplie } });
				} else {
					replaceOutgoingBaplie({ variables: { planId, baplie } }).finally(onClose);
				}
			}
		};
		fr.readAsText(file);
	}, [ type, file ]);

	return <Modal isOpen={isOpen} onRequestClose={onClose} title="BAPLIE importeren">
		{step == 'TYPE_SELECTOR' && <Form style={{ maxWidth: 'var(--u-384)' }} onSubmit={() => setStep('READ_FILE')}>
			<BigMultiSelector
				options={[
					{ id: 'INBOUND', title: 'Inkomend', description: 'Lees inkomend stuwplan in, wijzigingen in dit stuwplan zijn niet mogelijk' },
					{ id: 'OUTBOUND', title: 'Uitgaand', description: 'Lees uitgaand stuwplan in. Hierin kunnen wijzigingen worden aangebracht.' },
				]}
				onChange={setType}
				value={type} />

			<ButtonArea>
				<ValidatedButton validations={[ { valid: type != null, error: 'Selecteer type stuwageplan' } ]} type="submit">
					Doorgaan
				</ValidatedButton>
			</ButtonArea>
		</Form>}
		{step == 'READ_FILE' && <Form style={{ maxWidth: 'var(--u-384)' }} onSubmit={process}>
			{showWarning && <WarningBanner>Het importeren van een nieuw BAPLIE-bestand overschrijft het bestaande stuwageplan</WarningBanner>}
			<div style={{ height: 'var(--u-8)' }} />

			<FileDropInput
				onChange={setFile}
				value={file} />

			<ButtonArea>
				<ValidatedButton level="primary" type="submit" validations={[ { valid: file != null, error: 'Selecteer eerst een BAPLIE-bestand' } ]}>
					<span className="fa fa-cloud-upload" />&nbsp; Importeren
				</ValidatedButton>
				<Button onClick={onClose}>Annuleren</Button>
			</ButtonArea>
		</Form>}
		{step == 'PROCESSING' && <LoadingOverlay />}
		{step == 'WARN_ABOUT_WRONG_TYPE' && type == 'INBOUND' && <div>
			<WarningBanner>Weet u zeker dat dit een inkomende BAPLIE is? <br></br> In deze BAPLIE komt {portUnlo} als laadhaven voor. <br></br> Dit duidt potentieel op een foutief geselecteerd bestand</WarningBanner>
			<ButtonArea>
				<Button level="primary" onClick={() => setStep('TYPE_SELECTOR')}>
					Opnieuw selecteren
				</Button>
				<Button onClick={() => replaceIncomingBaplie(baplieVariables).finally(onClose)}>
					Toch inlezen
				</Button>
			</ButtonArea>
		</div>}
		{step == 'WARN_ABOUT_WRONG_TYPE' && type == 'OUTBOUND' && <div>
			<WarningBanner>Weet u zeker dat dit een uitgaande BAPLIE is? <br></br> In deze BAPLIE komt {portUnlo} als loshaven voor. <br></br> Dit duidt potentieel op een foutief geselecteerd bestand</WarningBanner>
			<ButtonArea>
				<Button level="primary" onClick={() => setStep('TYPE_SELECTOR')}>
					Opnieuw selecteren
				</Button>
				<Button onClick={() => replaceOutgoingBaplie(baplieVariables).finally(onClose)}>
					Toch inlezen
				</Button>
			</ButtonArea>
		</div>}
	</Modal>;
}
