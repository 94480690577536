import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, UNSAFE_NavigationContext } from 'react-router-dom';
import Modal from '../Modal';
import Button from './Button';


export function NavigateAwayPrompt({ onBlock, captureNavigateAwayEvent, children }) {
	// Why have I used React.useContext(UNSAFE_NavigationContext)?
	// The approach using Prompt and Redirect could not work here since they are not supported in react-router v6.
	// Therefore, I had to come up with a different solution and while doing research about potential solutions I came across a few websites which use similar technique.
	// Since react-router expose NavigationContext under UNSAFE_NavigationContext we can build NavigataAwayPrompt hook with that code.
	// However, we need to bear in mind that future updates of react-router or react-router-dom may break it.
	// Therefore, at the moment we would like to stick to the specific version of react-router and react-router-dom We add ~ sign to these dependencies so only which allows patch-level changes. So for example react-router may be updated to 6.2.4, 6.2.5 ... , 6.2.9 but not 6.3.0 since this is a minor change.
	const { navigator } = React.useContext(UNSAFE_NavigationContext);

	useEffect(() => {
		if (!captureNavigateAwayEvent) return;
		return navigator.block(onBlock);
	},
	[ navigator, onBlock, captureNavigateAwayEvent ]);
	return <>{children}</>;
}


export default function PromptModal({ onSave }) {
	const navigate = useNavigate();
	const [ isOpen, setIsOpen ] = useState(false);
	const [ desiredLocation, setDesiredLocation ] = useState("");
	const handleBlockedNavigation = useCallback((nextLocation) => {
		setDesiredLocation(nextLocation.location.pathname);
		setIsOpen(true);
	}, [ ]);

	return <NavigateAwayPrompt onBlock={handleBlockedNavigation} captureNavigateAwayEvent={!isOpen}>
		<Modal isOpen={isOpen} onSave={onSave} onClose={() => setIsOpen(false)} title="Wijzigingen opslaan">
			<div style={{ maxWidth: 'var(--u-512)' }}>
				Je staat op het punt de bewerk-modus te verlaten. Wil je de gemaakte wijzigingen opslaan?<br /><br />
			</div>
			<div style={{ display: 'flex', flexDirection: 'row-reverse', justifyContent: 'space-between' }}>
				 <Button className="primary" onClick={() => { onSave(); setIsOpen(false); navigate(desiredLocation); }}><span className="fa fa-check" /> Opslaan </Button>
				 <Button onClick={() => { setIsOpen(false); navigate(desiredLocation); }}>Doorgaan zonder op te slaan</Button>
			</div>
		</Modal>
	</NavigateAwayPrompt>;
}
