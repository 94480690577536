import { gql, useQuery, useMutation } from '@apollo/client';
import { Link } from 'react-router-dom';
import Breadcrumbs from '../lib/Breadcrumbs';
import ReactTooltip from 'react-tooltip';
import React, { useCallback } from 'react';
import { useState } from "react";
import DeleteCallModal from "./viewcall/DeleteCallModal";
import DateTime from '../lib/formatting/DateTime';
import { LinkButton, Pagination, Button } from '@atrocit/scl';

const PAGE_SIZE = 25;

// Hey I just met you,
// and this is crazy.
// but here's my number
// so call me maybe?
export default function CallOverview() {
	const [ page, setPage ] = useState(1);
	const [ deleteCallModalID, setDeleteCallModalID ] = useState(null);

	let [ currentSorting, setCurrentSorting ] = useState("pta");
	let [ currentSortingDirection, setCurrentSortingDirection ] = useState("DESC");

	const onClickSort = useCallback((sort) => {
		if (currentSorting == sort && currentSortingDirection == "ASC") {
			setCurrentSortingDirection("DESC");
		} else {
			setCurrentSortingDirection("ASC");
		}
		setCurrentSorting(sort);
	}, [ currentSorting, currentSortingDirection ]);

	const stowagePlanQuery = useQuery(gql`query Query($offset: Int!, $limit: Int!, $currentSorting: String!, $currentSortingDirection: String!) {
		stowagePlans(offset: $offset, limit: $limit, currentSorting: $currentSorting, currentSortingDirection: $currentSortingDirection) {
			totalElements,
			content {
                id,
                callReference,
                shipName,
                pta,
                ptd,
                portUnlo,
            },
        }
	}`, { variables: { offset: (page - 1) * PAGE_SIZE, limit: PAGE_SIZE, currentSorting: currentSorting, currentSortingDirection: currentSortingDirection } });

	const totalElements = stowagePlanQuery?.data?.stowagePlans?.totalElements || 0;
	const plans = stowagePlanQuery?.data?.stowagePlans?.content ?? [];

	return <div className="app-ui">

		{deleteCallModalID != null && <DeleteCallModal
			planId={deleteCallModalID}
			isOpen={deleteCallModalID}
			onClose={() => {
				setDeleteCallModalID(null);
				stowagePlanQuery.refetch();
			}} />}

		<Breadcrumbs
			segments={[
				{ link: '/calls', label: 'Calls' },
				{ link: '/calls', label: 'Overzicht' },
			]} />
		<div>
			<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
				<LinkButton to="/calls/create"><span className="fa fa-plus" />&nbsp; Nieuwe call</LinkButton>
			</div>
			<br />
		</div>
		<table className="table">
			<colgroup>
				<col width="*" />
				<col width="200" />
				<col width="75" />
				<col width="140" />
				<col width="140" />
				<col width="80" />
				<col width="44" />
			</colgroup>
			<thead>
				<tr style={{ userSelect: 'none' }}>
					<th onClick={() => onClickSort("callReference")}>{(currentSorting == "callReference" ? currentSortingDirection : null) == null ? <>&nbsp;&nbsp;</> : <span className={(currentSorting == "callReference" ? currentSortingDirection : null) == "DESC" ? "fa fa-caret-up" : "fa fa-caret-down" } />} Callref.</th>
					<th onClick={() => onClickSort("shipName")}>{(currentSorting == "shipName" ? currentSortingDirection : null) == null ? <>&nbsp;&nbsp;</> : <span className={(currentSorting == "shipName" ? currentSortingDirection : null) == "DESC" ? "fa fa-caret-up" : "fa fa-caret-down" } />} Schip</th>
					<th onClick={() => onClickSort("portUnlo")}>{(currentSorting == "portUnlo" ? currentSortingDirection : null) == null ? <>&nbsp;&nbsp;</> : <span className={(currentSorting == "portUnlo" ? currentSortingDirection : null) == "DESC" ? "fa fa-caret-up" : "fa fa-caret-down" } />} Haven</th>
					<th onClick={() => onClickSort("pta")}>{(currentSorting == "pta" ? currentSortingDirection : null) == null ? <>&nbsp;&nbsp;</> : <span className={(currentSorting == "pta" ? currentSortingDirection : null) == "DESC" ? "fa fa-caret-up" : "fa fa-caret-down" } />} PTA</th>
					<th onClick={() => onClickSort("ptd")}>{(currentSorting == "ptd" ? currentSortingDirection : null) == null ? <>&nbsp;&nbsp;</> : <span className={(currentSorting == "ptd" ? currentSortingDirection : null) == "DESC" ? "fa fa-caret-up" : "fa fa-caret-down" } />} PTD</th>
					<th></th>
					<th></th>
				</tr>
			</thead>
			<tbody>
				{plans.map(p => <tr key={p.id}>
					<td>{p.callReference}</td>
					<td>{p.shipName}</td>
					<td>{p.portUnlo}</td>
					<td><DateTime value={p.pta} /></td>
					<td><DateTime value={p.ptd} /></td>
					<td style={{ padding: '0 var(--u-4)' }}>
						<LinkButton to={'/calls/' + p.id}>Openen</LinkButton>
					</td>
					<td style={{ padding: '0 var(--u-4)' }}>
						<Button data-tip data-for='delete' onClick={() => { setDeleteCallModalID(p.id); }}><span className="fa fa-trash" /> </Button>
					</td>
					<ReactTooltip id="delete" place='bottom' effect='solid'>Verwijderen</ReactTooltip>
				</tr>)}
			</tbody>
		</table>
		<div style={{ display: 'flex', justifyContent: 'flex-end', paddingTop: 'var(--u-16)', gap: 'var(--u-24)', alignItems: 'center' }}>
			<div>
				<span><span>Call {(page - 1) * PAGE_SIZE + 1} t/m {Math.min((page * PAGE_SIZE), totalElements)} getoond, van {totalElements} totaal</span></span>
			</div>
			<Pagination page={page} setPage={setPage} pageSize={PAGE_SIZE} total={totalElements} />
		</div>
		{stowagePlanQuery.loading && <div>
			<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', padding: "7px" }}>
				<span className="fa fa-spinner fa-pulse" style={{ fontSize: 'var(--fs-18)' }}></span>
			</div>
		</div> }
	</div>;
}