import { gql, useQuery } from '@apollo/client';
import { useCallback, useMemo } from 'react';
import Select from 'react-select';


export default function ShipTemplateField({ onChange, value, placeholder = '', ...props }) {
	const shipTemplatesQuery = useQuery(gql`query {
        shipTemplates {
            id, templateName
        }
    }`);

	const options = useMemo(() => {
		return (shipTemplatesQuery?.data?.shipTemplates ?? []).map(st => ({
			value: st.id,
			label: st.templateName,
			original: st,
		}));
	}, [ shipTemplatesQuery?.data?.shipTemplates ]);

	const onChangeCb = useCallback((obj) => {
		onChange(obj?.value ?? null, obj?.original);
	}, [ onChange ]);

	return <Select
		{...props}
		className="react-select"
		classNamePrefix="react-select"
		placeholder={placeholder}
		loading={shipTemplatesQuery.loading}
		isClearable={true}
		options={options}
		onChange={onChangeCb}
		value={options.find(o => o.value == value)} />;
}