import React, { Fragment, useRef } from 'react';
import { FileDrop } from 'react-file-drop';

export default function FileDropInput({ onChange, value }) {
	const fileInputRef = useRef(null);

	return <Fragment>
		<input
			type="file"
			ref={fileInputRef}
			className="invisible"
			onChange={event => event.target.files[0] && onChange(event.target.files[0])} />

		<FileDrop
			className="file-drop-area"
			onTargetClick={() => fileInputRef.current && fileInputRef.current.click()}
			onDrop={(files, event) => onChange(files[0])}>
			<span>
				<span className="fa fa-file-o" /><br />
				{value == null && <Fragment>
					<span style={{ display: 'inline-block', marginBottom: 'var(--u-2)' }}>Sleep een bestand hier</span>
					<br />
					<span style={{ fontSize: 'var(--fs-9)', lineHeight: '1.0em', textTransform: 'initial' }}>(of klik hier om handmatig te selecteren)</span>
				</Fragment>}
				{value != null && <span>
					{value.name}
				</span>}
			</span>
		</FileDrop>
	</Fragment>;
}
