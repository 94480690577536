import ReactModal from 'react-modal';
import { useContext } from 'react';
import WindowContext from '../../context/WindowContext';
import { Button } from '@atrocit/scl';

const modalStyles = {
	content: {
		background: 'transparent',
		inset: '0px',
		border: 'none',
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'center',
		alignItems: 'center',
	},
	overlay: {
		background: 'rgba(0, 0, 0, 0.5)',
		zIndex: 100,
	},
};
const modalStylesLeft = {
	content: {
		background: 'transparent',
		inset: '0px',
		border: 'none',
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'left',
		alignItems: 'center',
	},
	overlay: {
		background: 'rgba(0, 0, 0, 0.5)',
		zIndex: 100,
	},
};

export default function Modal({ isOpen = true, title, onClose, children, leftSide = false, ...props }) {
	const window = useContext(WindowContext);

	return <ReactModal
		isOpen={isOpen}
		onRequestClose={onClose}
		style={leftSide ? modalStylesLeft : modalStyles}
		parentSelector={() => window.document.querySelector('#modal')}>
		<div className="modal-card" style={{ maxHeight: '90vh', overflow: 'auto' }}>
			<div className="modal-header">
				<div style={{ paddingRight: 'var(--u-24)' }}>
					<span style={{ textTransform: 'uppercase', fontWeight: '500', color: 'var(--col-grey-700)' }}>{title}</span>
				</div>
				<div>
					<Button onClick={onClose} style={{ width: '32px', height: '32px', fontSize: '1.2em' }}>&times;</Button>
				</div>
			</div>
			<div className="modal-content">
				{children}
			</div>
		</div>
	</ReactModal>;
}