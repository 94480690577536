import { InfoBanner, Modal, Toggle } from '@atrocit/scl';

export default function ViewCallSettingsModal({ isOpen, combinedBays, setCombinedBays, filterPolOnly, setFilterPolOnly, showSwapSuggestions, setShowSwapSuggestions, splitBaysOnPrintIfWide, setSplitBaysOnPrintIfWide, realisticContainerHeights, setRealisticContainerHeights, dontShowNumbersOfEmptiesToBeLoaded, setDontShowNumbersOfEmptiesToBeLoaded, betterHighlightForPolOnly, setBetterHighlightForPolOnly, onClose }) {
	return <Modal title="Weergaveinstellingen" isOpen={isOpen} onRequestClose={onClose}>
		<div style={{ maxWidth: 'var(--u-384)' }}>
			<InfoBanner>Deze instellingen zijn alleen visueel, ze hebben geen invloed op de import/export functies.</InfoBanner>
			<br/>
			<br/>
			<div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
				<div style={{ paddingRight: 'var(--u-16)' }}>
					<Toggle
						onChange={setCombinedBays}
						value={combinedBays} />
				</div>
				<div>
					<span>Combineer 20ft en 40ft bays</span>
				</div>
			</div>
			<br />
			{filterPolOnly != null && <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
				<div style={{ paddingRight: 'var(--u-16)' }}>
					<Toggle
						onChange={setFilterPolOnly}
						value={filterPolOnly} />
				</div>
				<div>
					<span>Laat alleen containers hier te laden/lossen zien</span>
				</div>
			</div>}
			<br />
			<div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
				<div style={{ paddingRight: 'var(--u-16)' }}>
					<Toggle
						onChange={setShowSwapSuggestions}
						value={showSwapSuggestions} />
				</div>
				<div>
					<span>Laat wissel-compatibiliteit zien bij selecteren</span>
				</div>
			</div>
			<br />
			<div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
				<div style={{ paddingRight: 'var(--u-16)' }}>
					<Toggle
						onChange={setSplitBaysOnPrintIfWide}
						value={splitBaysOnPrintIfWide} />
				</div>
				<div>
					<span>Splits brede bays bij printen</span>
				</div>
			</div>
			<br />
			<div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
				<div style={{ paddingRight: 'var(--u-16)' }}>
					<Toggle
						onChange={setRealisticContainerHeights}
						value={realisticContainerHeights} />
				</div>
				<div>
					<span>Gebruik daadwerkelijke hoogtes voor containers</span>
				</div>
			</div>
			<br />
			<div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
				<div style={{ paddingRight: 'var(--u-16)' }}>
					<Toggle
						onChange={setDontShowNumbersOfEmptiesToBeLoaded}
						value={dontShowNumbersOfEmptiesToBeLoaded} />
				</div>
				<div>
					<span>Laat containernummers voor te laden lege containers weg</span>
				</div>
			</div>
			<br />
			<div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
				<div style={{ paddingRight: 'var(--u-16)' }}>
					<Toggle
						onChange={setBetterHighlightForPolOnly}
						value={betterHighlightForPolOnly} />
				</div>
				<div>
					<span>Beter markeren welke containers hier niet geladen/gelost moeten worden.</span>
				</div>
			</div>
		</div>
	</Modal>;
}