import { generateColorForHue, strHash } from '../../../util/colors';
import React, { useEffect, useMemo } from 'react';
import { gql, useMutation, useSubscription } from '@apollo/client';

export default function EditSessions({ id }) {
	const subscriptionEditSessions = useSubscription(gql`subscription Subscription($id: Int!) {
		subscribeToSessionsOnStowagePlan(planId: $id) {
			numberOfUsers,
			sessions {
				id,
				user { id, firstName, fullName, email }
				opened,
				validUntil,
            }
		}
	}`, { variables: { id } });
	const editSessions = useMemo(() => subscriptionEditSessions.data?.subscribeToSessionsOnStowagePlan?.sessions ?? [], [ subscriptionEditSessions.data ]);

	const [ pingEditSession ] = useMutation(gql`mutation Mutation($id: Int!) {
        pingEditSession(planId: $id) { id }
	}`);

	useEffect(() => {
		pingEditSession({ variables: { id } });
		const interval = setInterval(() => pingEditSession({ variables: { id } }), 5000);
		return () => clearInterval(interval);
	}, [ id ]);

	return <div style={{ display: 'flex', flexDirection: 'row', gap: '-4px' }}>
		{editSessions.sort((a, b) => (a.user?.fullName < b.user?.fullName ? -1 : 1)).map(s => <div className="session-user-circle" style={{ background: generateColorForHue(strHash(s.user?.fullName) % 360, 0.5) }}>{s.user?.fullName.split(' ').map(w => w.toUpperCase().slice(0, 1)).join('')}</div>)}
	</div>;
}