import React, { useCallback, useState } from 'react';
import Modal from '../../lib/Modal';
import FileDropInput from '../../lib/forms/FileDropInput';
import ValidatedButton from '../../lib/forms/ValidatedButton';
import ButtonArea from '../../lib/forms/ButtonArea';
import { gql, useMutation } from '@apollo/client';
import { prepContainerNumber } from './CreateContainerModal';
import exportToExcel from '../../../util/exportToExcel';
import SelectField from '../../lib/selectfields/SelectField';
import { BigMultiSelector, Button, Form, LoadingOverlay, WithLabel } from '@atrocit/scl';
import _ from 'lodash';

export default function ImportCsvLocationsModal({ plan, cells, isOpen, onClose }) {
	const [ loading, setLoading ] = useState(false);
	const [ file, setFile ] = useState(null);
	const [ method, setMethod ] = useState('ID_MATCH');
	const [ unmatched, setUnmatched ] = useState([]);
	const [ matchMode, setMatchMode ] = useState('MATCH_LENGTH_HEIGHT');
	const [ finished, setFinished ] = useState(false);

	const [ updateContainersAlternative ] = useMutation(gql`mutation Mutation($planId: Int!, $updates: [ ContainerPartialUpdate! ]!, $matchMode: ContainerTypeMatchMode!, $originalCsv: String!) {
        updateContainersAlternative(planId: $planId, updates: $updates, matchMode: $matchMode, originalCsv: $originalCsv) {
	        equipmentIdentifier,
	        reason
        }
    }`);

	const [ updateContainers ] = useMutation(gql`mutation Mutation($planId: Int!, $updates: [ ContainerPartialUpdate! ]!) {
        updateContainers(planId: $planId, updates: $updates) { id }
    }`);

	const process = useCallback(() => {
		setLoading(true);
		if (file == null) {
			return setLoading(false);
		}

		const byContainerNumber = {};
		cells.forEach(cell => {
			if (cell.container == null) return;
			if (cell.container.equipmentIdentifier == null || cell.container.equipmentIdentifier.length == 0) return;
			byContainerNumber[prepContainerNumber(cell.container.equipmentIdentifier)] = cell.container;
		});

		const fr = new FileReader();
		fr.onload = () => {
			const csv = fr.result;
			const lines = csv.split('\n').map(line => line.split(';'));

			if (method == 'ID_MATCH') {
				const updates = lines.filter(l => l.length > 23 && byContainerNumber[l[0]] != null).map(fields => {
					return {
						// Match on this ID
						containerId: byContainerNumber[fields[0]].id,

						// Overwrite these fields
						equipmentIdentifier: fields[0],
						isoCode: fields[2] == null || fields[2].length == 0 ? null : fields[2],
						portOfLoading: fields[27],
						portOfDischarge: fields[21],
						carrierReference: fields[1],
						grossWeight: fields[6] == null || fields[6].length == 0 ? null : parseInt(fields[6].replace('.', ''), 10),
						isVgm: fields[29] == null || fields[29].length == 0 ? null : (fields[29] == '1'),
						reeferTemperature: fields[10] == null || fields[10].length == 0 ? null : parseFloat(fields[10]),
						stackLocation: fields[22],
						isDangerousGoods: fields[19] == 1 || fields[19] == '1',
						isEmpty: fields[5] == null || parseInt(fields[5].replace('.', ''), 10) == 0, // net weight check
						overHeight: (fields[18] == null || fields[18].length == 0) ? null : (parseInt(fields[18], 10) / 10),
						overWidthLeft: (fields[16] == null || fields[16].length == 0) ? null : (parseInt(fields[16], 10) / 10),
						overWidthRight: (fields[17] == null || fields[17].length == 0) ? null : (parseInt(fields[17], 10) / 10),
						overLengthFront: (fields[14] == null || fields[14].length == 0) ? null : (parseInt(fields[14], 10) / 10),
						overLengthBack: (fields[15] == null || fields[15].length == 0) ? null : (parseInt(fields[15], 10) / 10),

						partialUpdateFieldSet: {
							equipmentIdentifier: true,
							isoCode: fields[2] != null,
							portOfLoading: true,
							portOfDischarge: true,
							carrierReference: true,
							grossWeight: true,
							isVgm: !(fields[29] == null || fields[29].length == 0),
							reeferTemperature: !(fields[10] == null || fields[10].length == 0),
							stackLocation: true,
							isDangerousGoods: true,
							isEmpty: true,
							overHeight: !(fields[18] == null || fields[18].length == 0),
							overWidthLeft: !(fields[16] == null || fields[16].length == 0),
							overWidthRight: !(fields[17] == null || fields[17].length == 0),
							overLengthFront: !(fields[14] == null || fields[14].length == 0),
							overLengthBack: !(fields[15] == null || fields[15].length == 0),
						},
					};
				});

				console.log('Sending updates', updates);

				updateContainers({ variables: { planId: plan.id, updates } })
					.finally(() => {
						onClose();
						setLoading(false);
					});
			} else {
				const updates = lines.filter(l => l.length > 23).map(fields => {
					return {
						// Match on this ID
						containerId: 0,

						// Overwrite these fields
						equipmentIdentifier: fields[0],
						isoCode: fields[2] == null || fields[2].length == 0 ? null : fields[2],
						portOfLoading: fields[27],
						portOfDischarge: fields[21],
						carrierReference: fields[1],
						grossWeight: fields[6] == null || fields[6].length == 0 ? null : parseInt(fields[6].replace('.', ''), 10),
						isVgm: fields[29] == null || fields[29].length == 0 ? null : (fields[29] == '1'),
						reeferTemperature: fields[10] == null || fields[10].length == 0 ? null : parseFloat(fields[10]),
						stackLocation: fields[22],
						isDangerousGoods: fields[19] == 1 || fields[19] == '1',
						isActiveReefer: !(fields[10] == null || fields[10].length == 0),
						isEmpty: fields[5] == null || parseInt(fields[5].replace('.', ''), 10) == 0, // net weight check
						overHeight: (fields[18] == null || fields[18].length == 0) ? null : (parseInt(fields[18], 10) / 10),
						overWidthLeft: (fields[16] == null || fields[16].length == 0) ? null : (parseInt(fields[16], 10) / 10),
						overWidthRight: (fields[17] == null || fields[17].length == 0) ? null : (parseInt(fields[17], 10) / 10),
						overLengthFront: (fields[14] == null || fields[14].length == 0) ? null : (parseInt(fields[14], 10) / 10),
						overLengthBack: (fields[15] == null || fields[15].length == 0) ? null : (parseInt(fields[15], 10) / 10),

						partialUpdateFieldSet: {
							equipmentIdentifier: true,
							isoCode: fields[2] != null,
							portOfLoading: true,
							portOfDischarge: true,
							carrierReference: true,
							grossWeight: !(fields[6] == null || fields[6].length == 0),
							isVgm: !(fields[29] == null || fields[29].length == 0),
							reeferTemperature: !(fields[10] == null || fields[10].length == 0),
							stackLocation: true,
							isDangerousGoods: true,
							isEmpty: true,
							overHeight: !(fields[18] == null || fields[18].length == 0),
							overWidthLeft: !(fields[16] == null || fields[16].length == 0),
							overWidthRight: !(fields[17] == null || fields[17].length == 0),
							overLengthFront: !(fields[14] == null || fields[14].length == 0),
							overLengthBack: !(fields[15] == null || fields[15].length == 0),
						},
					};
				});

				updateContainersAlternative({ variables: { planId: plan.id, updates, matchMode, originalCsv: csv } })
					.then((result) => {
						console.log(result);
						setUnmatched(result.data.updateContainersAlternative);
					})
					.finally(() => {
						setFinished(true);
					});
			}
		};
		fr.readAsText(file);
	}, [ plan.id, file ]);

	return <Modal
		isOpen={isOpen}
		onClose={onClose}
		title="Interman stacklocaties importeren">
		{!finished && <Form style={{ maxWidth: 'var(--u-512)' }} onSubmit={process}>
			<BigMultiSelector
				options={[
					{ id: 'ID_MATCH', title: 'Alleen overeenkomend containernummer', description: 'De containers zullen alleen worden geïmporteerd en gematcht op basis van hun overeenkomende containernummers.' },
					{ id: 'OTHER_MATCH', title: 'Overeenstemming met beschikbare informatie', description: 'Wij gaan ervan uit dat niet alle informatie beschikbaar is, in welk geval de vergelijking zal geschieden op basis van een best guess scenario.' },
				]}
				onChange={setMethod}
				value={method} />

			{method == 'OTHER_MATCH' && <>
				<WithLabel label="Containertype matchen op">
					<SelectField
						options={[
							{ value: 'ISO_CODE_3CHAR', label: 'Eerste 3 karakters ISO-code (45GX)' },
							{ value: 'MATCH_LENGTH_HEIGHT_WIDTH', label: 'Lengte/hoogte/breedte (45G1 en 45G1 uitwisselbaar)' },
							{ value: 'MATCH_LENGTH_HEIGHT', label: 'Lengte/hoogte (45G1 en 4EG1 uitwisselbaar)' },
							{ value: 'MATCH_LENGTH', label: 'Lengte (45G1 en 42G1 uitwisselbaar)' },
						]}
						onChange={(pick) => setMatchMode(pick?.value)}
						value={matchMode} />
				</WithLabel>
			</>}

			<FileDropInput
				onChange={setFile}
				value={file} />

			<ButtonArea>
				<ValidatedButton level="primary" type="submit" validations={[ { valid: file != null, error: 'Selecteer eerst een Interman CSV-bestand' } ]}>
					<span className="fa fa-cloud-upload" />&nbsp; Importeren
				</ValidatedButton>
				<Button onClick={onClose}>Annuleren</Button>
			</ButtonArea>
		</Form>}
		{!finished && loading && <LoadingOverlay />}
		{finished && unmatched != null && unmatched.length != 0 && <div style={{ display: 'flex', flexDirection: 'column' }}>
			<span>Overzicht matchings-issues:</span><br />
			<table className="table">
				<tbody>
					{Object.entries(_.groupBy(unmatched, 'reason')).map(([ reason, list ]) => <tr key={reason}><td>{reason}</td><td>{list.length}</td></tr>)}
				</tbody>
			</table>
			<br /><br />

			<span>De volgende containernummers zijn niet ingevuld:<br /><br /> </span>
			<table className="table">
				<tbody>
					{unmatched.map(noMatch => <tr key={noMatch.equipmentIdentifier}><td>{noMatch.equipmentIdentifier}</td><td>{noMatch.reason}</td></tr>)}
				</tbody>
			</table>
			<ButtonArea>
				<Button level="primary" onClick={() => { setLoading(false); setFinished(false); onClose(); }}><span className="fa fa-check"/> Sluiten</Button>
				<Button type="button" onClick={() => exportToExcel([ { signifier: 'A', name: 'Container', width: 60 }, { signifier: 'B', name: 'Reden', width: 200 } ], unmatched.map(nm => ({ 'Container': nm.equipmentIdentifier, 'Reden': nm.reason })))}><span className="fa fa-file-excel-o" />&nbsp; Download excel</Button>
			</ButtonArea>
		</div>}
		{finished && (unmatched == null || unmatched.length == 0) && <>
			<span>Alle containers zijn ingelezen en konden gematcht worden</span>
			<ButtonArea>
				<Button level="primary" onClick={() => { setLoading(false); setFinished(false); onClose(); }}><span className="fa fa-check"/> Sluiten</Button>
			</ButtonArea>
		</>}
	</Modal>;
}