import React, { useCallback, useState } from 'react';
import ButtonArea from '../../lib/forms/ButtonArea';
import { gql, useLazyQuery } from '@apollo/client';
import { BigMultiSelector, Button, Form, LoadingOverlay, Modal } from '@atrocit/scl';

export function download(filename, text) {
	const element = document.createElement('a');
	element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
	element.setAttribute('download', filename);

	element.style.display = 'none';
	document.body.appendChild(element);

	element.click();

	document.body.removeChild(element);
}

export default function ExportBaplieModal({ sp, isOpen, onClose }) {
	const [ loading, setLoading ] = useState(false);
	const [ error, setError ] = useState(null);
	const [ file, setFile ] = useState(null);
	const [ version, setVersion ] = useState('BAPLIE_2_2');

	const [ exportBaplie ] = useLazyQuery(gql`query Query($planId: Int!, $version: BaplieVersion!) {
        exportBaplie(planId: $planId, version: $version)
    }`);

	const process = useCallback(() => {
		exportBaplie({ variables: { planId: sp.id, version } })
			.then(query => {
				const { data } = query;
				download((sp.shipName ?? 'Ship') + '-' + (sp.callReference ?? 'unknowncall') + '.edi', data?.exportBaplie);
				onClose();
			})
			.catch(e => {
				setError('Er ging iets fout: ' + e);
			});
	}, [ sp.id, version, file ]);

	return <Modal isOpen={isOpen} onRequestClose={onClose} title="BAPLIE downloaden">
		<Form style={{ maxWidth: 'var(--u-512)' }} onSubmit={process}>
			<p style={{ marginTop: 0 }}>Bij het klikken op Downloaden wordt er een BAPLIE-bestand gedownload van het uitgaand stuwplan.</p>

			<BigMultiSelector
				options={[
					{ id: 'BAPLIE_1_5_PRE1993', title: 'BAPLIE 1.5', description: 'Specificatie van 1993, zoals gebruikt vóór 1995' },
					{ id: 'BAPLIE_1_5_POST1993', title: 'BAPLIE 1.5', description: 'Zoals vastgesteld in de specificatie 1993 met locatie-elementen volgens specificatie van 1995' },
					{ id: 'BAPLIE_2_2', title: 'BAPLIE 2.2.1', description: 'Specificatie van 1995-2016 (inclusief VGM indicatoren)' },
				]}
				onChange={setVersion}
				value={version} />

			<ButtonArea>
				<Button level="primary" type="submit">
					<span className="fa fa-cloud-download" />&nbsp; Download
				</Button>
				<Button onClick={onClose}>Annuleren</Button>
			</ButtonArea>
		</Form>
		{loading && <LoadingOverlay />}
	</Modal>;
}