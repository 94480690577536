import { gql, useLazyQuery, useQuery } from '@apollo/client';
import DateTime from '../../lib/formatting/DateTime';
import { Button, LoadingOverlay } from '@atrocit/scl';
import { download } from './ExportBaplieModal';

export default function EventLog({ stowagePlanId, cellsById }) {
	const eventLogQuery = useQuery(gql`query Query($spId: Int!) {
		eventLogsForStowagePlan(stowagePlanId: $spId) {
			id,
			createdAt,
			eventType,
			otherData
		}
	}`, { variables: { spId: stowagePlanId } });
	const events = eventLogQuery?.data?.eventLogsForStowagePlan ?? [];

	const [ getEventLogById, eventLogByIdQuery ] = useLazyQuery(gql`query Query($id: ID!) {
        eventLog(id: $id) {
            id,
            createdAt,
            eventType,
	        fileContents,
            otherData
        }
    }`);

	return <div>
		{(eventLogQuery.loading || eventLogByIdQuery.loading) && <LoadingOverlay />}

		<div style={{ textAlign: 'center', borderBottom: '1px solid var(--col-grey-200)', color: 'var(--col-grey-500)', fontSize: 'var(--fs-9)', padding: 'var(--u-4) 0', minWidth: '200px' }} onClick={() => eventLogQuery.refetch()}>
			{events.length} events
		</div>

		{events.map((e, idx) => {
			const downloadable = [ 'READ_INCOMING_BAPLIE', 'READ_OUTGOING_BAPLIE', 'READ_CSV' ].includes(e.eventType);
			let otherDataJson = null;
			try {
				otherDataJson = JSON.parse(e.otherData);
			} catch (e) { /* Don't care */ }

			return <div key={idx} className="event" style={{ borderBottom: '1px solid var(--col-grey-200)', padding: '4px', color: 'var(--col-grey-600)', fontSize: 'var(--fs-9)' }}>
				<div style={{ display: 'grid', gridTemplateColumns: '1fr 48px' }}>
					<div>
						<DateTime value={e.createdAt} />
						<br />
						<span style={{ fontWeight: 'bold' }}>{eventTypeToHumanName(e.eventType)}</span>

						{e.eventType == 'SWAP_CELLS' && otherDataJson != null && <div>
							<span>{cellsById[otherDataJson.cellFrom]?.cellCode} <span className="fa fa-arrows-h" /> {cellsById[otherDataJson.cellTo]?.cellCode}</span>
						</div>}
					</div>
					<div style={{ alignSelf: 'center' }}>
						{downloadable && <Button onClick={() => {
							getEventLogById({ variables: { id: e.id } })
								.then(({ data }) => {
									if (data?.eventLog == null) return;
									download('event' + (data?.eventLog.eventType == 'READ_CSV' ? '.csv' : '.edi'), data?.eventLog?.fileContents);
								});
						}}>
							<span className="fa fa-download" />
						</Button>}
					</div>
				</div>
			</div>;
		})}
	</div>;
}

function eventTypeToHumanName(eventType) {
	return {
		'READ_INCOMING_BAPLIE': 'BAPLIE inkomend',
		'READ_OUTGOING_BAPLIE': 'BAPLIE uitgaand',
		'READ_CSV': 'Interman CSV',
		'SWAP_CELLS': 'Containers gewisseld',
	}[eventType] || eventType;
}