import Modal from '../../lib/Modal';
import { gql, useMutation } from "@apollo/client";
import React from 'react';
import ButtonArea from "../../lib/forms/ButtonArea";
import { Button } from '@atrocit/scl';

export default function DeleteCallModal({ planId, isOpen, onClose }) {

	const [ deletePlan ] = useMutation(gql`mutation Mutation($planId: Int!) {
		deletePlan(planId: $planId)
	}`);

	return <Modal isOpen={isOpen} onClose={onClose} title="Call verwijderen?">
		<div>Ben je zeker dat je wilt deze call verwijderen?</div>
		<ButtonArea>
			<Button
				style={{ background: 'var(--col-red)', color: 'white' }}
				onClick={() => deletePlan({ variables: { planId: Number(planId) } }).finally(onClose)}>
				Verwijderen
			</Button>
			<Button onClick={onClose}>Annuleren</Button>
		</ButtonArea>
	</Modal>;
}