import React, { useCallback, useState } from 'react';
import { gql, useMutation } from '@apollo/client';
import Modal from '../../lib/Modal';
import Form from '../../lib/forms/Form';
import WithLabel from '../../lib/forms/WithLabel';
import ButtonArea from '../../lib/forms/ButtonArea';
import ValidatedButton from '../../lib/forms/ValidatedButton';
import LoadingOverlay from '../../lib/ui/LoadingOverlay';

export default function DeleteContainerModal({ isOpen, onClose, containerId }) {
	const [ loading, setLoading ] = useState(false);

	const [ deleteContainer ] = useMutation(gql`mutation Mutation($containerId: Int!) {
		deleteContainer(containerId: $containerId)
	}`);

	function process() {
		setLoading(true);
		deleteContainer({ variables: { containerId: containerId } })
			.then(() => setLoading(false))
			.finally(() => onClose());
	}

	return <Modal isOpen={isOpen} onClose={onClose} title="Container verwijderen?">
		<Form style={{ minWidth: 'var(--u-512)', maxWidth: 'var(--u-512)' }} onSubmit={process}>
			<p style={{ marginTop: 0 }}>Weet je zeker dat deze container verwijderd moet worden van de laadlijst? Deze actie kan niet ongedaan gemaakt worden.</p>
			<ButtonArea>
				<ValidatedButton type="submit">
					Verwijderen
				</ValidatedButton>
				<ValidatedButton onClick={onClose}>
					Annuleren
				</ValidatedButton>
			</ButtonArea>
		</Form>
		{loading && <LoadingOverlay />}
	</Modal>;
}