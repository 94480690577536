import { toCellCode } from './bayplanning/BigBay';

export default function generateWarnings(cells, template, killedSlots) {
	if (cells == null || killedSlots == null) return [];

	const warnings = [];

	const cellMap = {};
	cells.forEach(cell => (cellMap[cell.cellCode] = cell));

	const templateCellMap = {};
	template?.cells.forEach(cell => (templateCellMap[cell.cellCode] = cell));

	cells.forEach(cell => {
		if (cell.container == null) return;

		if (checkFloatingContainers(templateCellMap, cellMap, cell)) warnings.push({ type: 'Floating', cell });
		if (check20over40(cellMap, cell)) warnings.push({ type: 'TwentyOverForty', cell });
		if (checkIfContainerInKilledSlot(killedSlots, cell)) warnings.push({ type: 'KilledSlot', cell });
	});
	return warnings;
}

// Checks if current container (20ft) rests on top of a 40ft container, which can't work because of the cornerstones
function check20over40(cellMap, currentCell) {
	const { bay, row, tier } = currentCell;
	if (currentCell.bay % 2 == 0) return false; // ignore 40ft bays for this check

	// In bay X, the 40ft can be in X-1 or X+1, so try to find both
	const cellBelowInThePreviousBay = cellMap[toCellCode(bay - 1, row, tier - 2)];
	const cellBelowInTheNextBay = cellMap[toCellCode(bay + 1, row, tier - 2)];

	// If either exist, that's a problem
	return cellBelowInThePreviousBay?.container != null || cellBelowInTheNextBay?.container != null;
}

function checkFloatingContainers(templateCellMap, cellMap, currentCell) {
	const { bay, row, tier } = currentCell;
	const cellBelowStraight = cellMap[toCellCode(bay, row, tier - 2)];
	const cellBelowMinus = cellMap[toCellCode(bay - 1, row, tier - 2)];
	const cellBelowPlus = cellMap[toCellCode(bay + 1, row, tier - 2)];

	const shipTemplateCellBelowStraight = templateCellMap[toCellCode(bay, row, tier - 2)];
	const shipTemplateCellBelowMinus = templateCellMap[toCellCode(bay - 1, row, tier - 2)];
	const shipTemplateCellBelowPlus = templateCellMap[toCellCode(bay + 1, row, tier - 2)];

	const floatingStraight = shipTemplateCellBelowStraight != null && cellBelowStraight?.container == null;
	const floatingMinus = shipTemplateCellBelowMinus != null && cellBelowMinus?.container == null;
	const floatingPlus = shipTemplateCellBelowPlus != null && cellBelowPlus?.container == null;

	if (bay % 2 != 0) { // 40ft bay, so only straight checks
		return floatingStraight;
	} else {
		return !(!floatingStraight || (!floatingMinus && !floatingPlus));
	}
}

function checkIfContainerInKilledSlot(killedSlots, currentCell) {
	return killedSlots.has(currentCell.cellCode) && currentCell.container != null;
}