import React from 'react';
import { gql, useQuery } from '@apollo/client';
import Breadcrumbs from '../lib/Breadcrumbs';
import ShortDateTime from '../lib/formatting/ShortDateTime';
import ButtonArea from '../lib/forms/ButtonArea';
import { Link } from 'react-router-dom';
import { LinkButton, LoadingOverlay } from '@atrocit/scl';

export default function Dashboard() {
	const dashboardQuery = useQuery(gql`query {
		metrics {
			callsYTD,
			movesYTD,
        },
		stowagePlans(offset: 0, limit: 4, currentSorting: "pta", currentSortingDirection: "DESC") {
			content {
				id,
				pta,
				ptd,
				callReference,
				shipName,
				portUnlo,
				shipTemplate {
					id, templateName
                },
				inboundCells {
					id,
					container {
						portOfLoading,
						portOfDischarge,
						visibility
					}
                },
                outboundCells {
                    id,
                    container {
                        portOfLoading,
                        portOfDischarge,
                        visibility
                    }
                }
            }
		}
	}`);

	const metrics = dashboardQuery?.data?.metrics;
	const plans = dashboardQuery?.data?.stowagePlans?.content;

	return <div style={{ background: 'var(--col-grey-100)', minHeight: '100vh', position: 'relative' }}>
		<div className="app-ui" style={{ display: 'flex', flexDirection: 'column', gap: 'var(--u-24)' }}>
			<Breadcrumbs
				segments={[
					{ link: '/dashboard', label: 'Dashboard' },
					{ link: '/dashboard', label: 'Overzicht' },
				]} />

			{dashboardQuery.loading && <LoadingOverlay />}

			{!dashboardQuery.loading && <div>
				<h2>Statistieken</h2>
				<div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr 1fr', gap: 'var(--u-32)', alignItems: 'stretch' }}>
					<div className="card metric">
						<span className="metric-number">{metrics.callsYTD}</span><br />
						<span className="metric-description">Calls dit jaar</span>
					</div>
					<div className="card metric">
						<span className="metric-number">{metrics.movesYTD}</span><br />
						<span className="metric-description">Moves dit jaar</span>
					</div>
				</div>
			</div>}
			{!dashboardQuery.loading && <div>
				<div style={{ display: 'flex', alignItems: 'center', gap: 'var(--u-16)' }}>
					<h2>Calls</h2>
				</div>
				<div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr 1fr', gap: 'var(--u-32)', alignItems: 'stretch' }}>
					{plans.map(sp => {
						const aSet = new Set(sp.inboundCells.filter(c => c?.container?.equipmentIdentifier?.length > 0).map(c => c?.container?.equipmentIdentifier));
						const bSet = new Set(sp.outboundCells.filter(c => c?.container?.equipmentIdentifier?.length > 0).map(c => c?.container?.equipmentIdentifier));
						const shifters = [ ...bSet ].filter(eqId => aSet.has(eqId));
						return <div className="card" key={sp.id}>
							<div style={{ textAlign: 'center' }}>
								<span style={{ fontSize: 'var(--fs-13)' }}>{sp.callReference}</span><br />
								<span className="metric-description"><span className="fa fa-ship" /> {sp.shipName}</span><br />
							</div>
							<br />
							<div style={{ textAlign: 'center' }}>
								<ShortDateTime value={sp.pta} />
							</div>
							<br />
							<div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr' }}>
								<div style={{ textAlign: 'center' }}>
									<span style={{ fontSize: 'var(--fs-18)' }}>{sp.inboundCells.filter(c => c?.container?.portOfDischarge == sp.portUnlo || c?.container?.visibility == 'VISIBLE').length + shifters.length}</span><br />
									<span className="metric-description">Lossen</span>
								</div>
								<div style={{ textAlign: 'center' }}>
									<span style={{ fontSize: 'var(--fs-18)' }}>{sp.outboundCells.filter(c => c?.container?.portOfLoading == sp.portUnlo || c?.container?.visibility == 'VISIBLE').length + shifters.length}</span><br />
									<span className="metric-description">Laden</span>
								</div>
							</div>

							<br />
							<ButtonArea>
								<LinkButton to={'/calls/' + sp.id}>Openen</LinkButton>
							</ButtonArea>
						</div>;
					})}
					<Link to="/calls">Bekijk de volledige call-lijst &rsaquo;</Link>
				</div>
			</div>}
		</div>
	</div>;
}