import { gql, useQuery } from '@apollo/client';
import PortColorFunction from '../../context/PortColorFunction';
import { useCallback, useMemo } from 'react';
import { strHash } from '../../util/colors';

export default function PortColorFunctionInjector({ children }) {
	const portQuery = useQuery(gql`{ ports(query: "", offset: 0, limit: 999999, field: "unlo", direction: ASC) { content { id, name, unlo, hue } } }`);
	const ports = portQuery?.data?.ports?.content ?? [];

	const unloHueLookupMap = useMemo(() => {
		const map = {};
		ports.forEach(p => (map[p.unlo] = p.hue));
		return map;
	}, [ ports ]);

	const generateHueForUnlo = useCallback((unlo) => {
		if (unloHueLookupMap[unlo] != null) return unloHueLookupMap[unlo];
		return strHash(unlo) % 360;
	}, [ unloHueLookupMap ]);

	return <PortColorFunction.Provider value={generateHueForUnlo}>
		{children}
	</PortColorFunction.Provider>;
}