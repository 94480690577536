import React, { useState } from "react";

function readValueFromLocalStorage(storageKey, defaultValue) {
	try {
		const value = localStorage.getItem(storageKey);
		return value ? JSON.parse(value) : defaultValue;
	} catch(e) {
		return defaultValue;
	}
}


export default function usePersistentState(defaultValue, key) {
	const storageKey = 'whydah.persistentHook.' + key;
	const [ value, setter ] = useState(readValueFromLocalStorage(storageKey, defaultValue));
	return [
		value,
		newValue => {
			const actualNewValue = (typeof newValue === 'function') ? newValue(value) : newValue;
			localStorage.setItem(storageKey, JSON.stringify(actualNewValue));
			setter(actualNewValue);
		},
	];
}