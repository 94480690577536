import { Button, Form, InputField, LoadingOverlay, WithLabel } from '@atrocit/scl';
import Modal from '../../lib/Modal';
import { gql, useLazyQuery, useMutation } from '@apollo/client';
import { useEffect, useState } from 'react';
import ButtonArea from '../../lib/forms/ButtonArea';

export default function DangerousGoodsEditModal({ isOpen, onClose, containerId }) {
	const [ container, setContainer ] = useState(null);
	const [ dangerousGoods, setDangerousGoods ] = useState([]);
	const [ loading, setLoading ] = useState(true);

	const [ containerQuery ] = useLazyQuery(gql`query Query($containerId: Int!) {
		container(containerId: $containerId) {
			id, equipmentIdentifier,
			dangerousGoods {
				id, imoClass, undgCode, goodsDescription, packingCode
            }
		}
	}`);

	const [ undgByCode ] = useLazyQuery(gql`query Query($code: String!) {
		undgByCode(code: $code) {
			id, unNumberShort, unNumberFull, imoClass, packingCode, description
		}
	}`);

	const [ updateDangerousGoodsForContainer ] = useMutation(gql`mutation Mutation($containerId: Int!, $dgs: [ DangerousGoodInput ]!) {
    	updateDangerousGoodsForContainer(containerId: $containerId, dgs: $dgs) {
			id, dangerousGoods {
			    id, undgCode, imoClass, packingCode, goodsDescription
            }
		}
	}`);

	useEffect(() => {
		setLoading(true);
		containerQuery({ variables: { containerId } })
			.then(({ data }) => {
				setLoading(false);
				setContainer(data?.container);
				setDangerousGoods(data?.container?.dangerousGoods);
			});
	}, [ containerId, isOpen ]);

	return <Modal isOpen={isOpen} title="Gevaargoed" onClose={onClose}>
		{loading && <LoadingOverlay />}
		<Form onSubmit={() => {
			setLoading(true);
			updateDangerousGoodsForContainer({ variables: { containerId, dgs: dangerousGoods } })
				.finally(onClose);
		}}>
			<div style={{ display: 'flex', flexDirection: 'column', gap: 'var(--u-8)', minWidth: '500px' }}>
				<div style={{ display: 'grid', gridTemplateColumns: '80px 80px 1fr 80px 44px', gap: 'var(--u-8)', maxHeight: '20px', overflow: 'hidden' }}>
					<WithLabel label="UNDG" />
					<WithLabel label="IMO-class" />
					<WithLabel label="Beschrijving" />
					<WithLabel label="Packingcode" />
					<div />
				</div>
				{(dangerousGoods || []).map((dg, idx) => {
					const updateDg = (newDg) => setDangerousGoods(dgs => dgs.map((originalDg, originalIdx) => (originalIdx == idx ? newDg : originalDg)));
					return <div style={{ display: 'grid', gridTemplateColumns: '80px 80px 1fr 80px 44px', gap: 'var(--u-8)' }}>
						<div>
							<InputField
								onChange={undgCode => updateDg({ ...dg, undgCode })}
								value={dg.undgCode}
								onBlur={() => {
									if (dg.undgCode == null || dg.undgCode.length < 4) return;
									if (dg.description != null && dg.description.trim().length > 0) return;
									if (dg.imoClass != null && dg.imoClass.trim().length > 0) return;
									if (dg.packingCode != null && dg.packingCode.trim().length > 0) return;
									setLoading(true);
									undgByCode({ variables: { code: dg.undgCode } })
										.then(({ data }) => {
											const undg = data?.undgByCode;
											if (undg == null) return;
											updateDg({ ...dg, imoClass: undg.imoClass, packingCode: undg.packingCode, goodsDescription: undg.description });
										}).finally(() => setLoading(false));
								}} />
						</div>
						<div>
							<InputField onChange={imoClass => updateDg({ ...dg, imoClass })} value={dg.imoClass} />
						</div>
						<div>
							<InputField onChange={goodsDescription => updateDg({ ...dg, goodsDescription })} value={dg.goodsDescription} />
						</div>
						<div>
							<InputField onChange={packingCode => updateDg({ ...dg, packingCode })} value={dg.packingCode} />
						</div>
						<div>
							<Button onClick={() => setDangerousGoods(dgs => dgs.filter((_, origIdx) => origIdx != idx))}><span className="fa fa-trash" /></Button>
						</div>
					</div>;
				})}
			</div>

			<div style={{ padding: 'var(--u-16) 0', display: 'flex', justifyContent: 'flex-end' }}>
				<Button onClick={() => setDangerousGoods(dgs => [ ...dgs, { id: null, imoClass: '', undgCode: '', goodsDescription: '', packingCode: '' } ])}><span className="fa fa-plus" />&nbsp; Nieuw product</Button>
			</div>

			<ButtonArea>
				<Button level="primary" type="submit"><span className="fa fa-check" />&nbsp; Opslaan</Button>
				<Button level="secondary" onClick={onClose}>Annuleren</Button>
			</ButtonArea>
		</Form>
	</Modal>;
}