import { useState } from 'react';
import FileDropInput from '../../lib/forms/FileDropInput';
import ButtonArea from '../../lib/forms/ButtonArea';
import ValidatedButton from '../../lib/forms/ValidatedButton';
import JSZip from 'jszip';
import { gql, useMutation } from '@apollo/client';
import { Button, Form, InputField, LoadingOverlay, Modal, WithLabel } from '@atrocit/scl';

export default function ImportShipTemplateModal({ isOpen, onClose }) {
	const [ name, setName ] = useState('');
	const [ file, setFile ] = useState(null);

	const [ createShipTemplateFromSimpleStowXml, { loading } ] = useMutation(gql`mutation Mutation($name: String!, $xml: String!) {
        createShipTemplateFromSimpleStowXml(name: $name, xml: $xml) { id }
    }`);

	return <Modal title="Importeer SimpleStow template" isOpen={isOpen} onRequestClose={onClose}>
		<Form onSubmit={() => {
			if (loading) return; // prevent duplicate hitting of import
			JSZip.loadAsync(file)
				.then((zip) => {
					zip.file('ship_model.xml').async("blob").then(xmlBlob => {
						const fr = new FileReader();
						fr.onload = () => {
							const xml = fr.result;
							createShipTemplateFromSimpleStowXml({ variables: { name, xml } })
								.finally(onClose);
						};

						fr.readAsText(xmlBlob, 'utf-16');
					});
				});
		}}>
			{loading && <LoadingOverlay />}

			<WithLabel label="Naam template">
				<InputField onChange={setName} value={name} />
			</WithLabel>

			<WithLabel label="SimpleStow scheepstemplate" isFake={true}>
				<FileDropInput
					onChange={setFile}
					value={file} />
			</WithLabel>
			<br />

			<ButtonArea>
				<ValidatedButton
					level="primary"
					type="submit"
					validations={[
						{ valid: name != null && name.length >= 3, error: 'Vul een naam van minstens 3 karakters in' },
						{ valid: file != null, error: 'Selecteer een SimpleStow bestand' },
					]}>
					<span className="fa fa-cloud-upload" />&nbsp; Importeren
				</ValidatedButton>
				<Button onClick={onClose}>Annuleren</Button>
			</ButtonArea>
		</Form>
	</Modal>;
}