import Modal from '../../lib/Modal';
import React, { useContext, useState } from 'react';
import ButtonArea from '../../lib/forms/ButtonArea';
import ValidatedButton from '../../lib/forms/ValidatedButton';
import { gql, useMutation } from '@apollo/client';
import PortColorFunction from '../../../context/PortColorFunction';
import { DEFAULT_PORT_LIGHTNESS, generateColorForHue } from '../../../util/colors';
import { Button, Form, InputField, LoadingOverlay, Toggle, WarningBanner, WithLabel } from '@atrocit/scl';

const titles = {
	'FIRST_STEP': 'Nieuwe container',
	'SECOND_STEP': 'Gewicht & reeferplug',
	'THIRD_STEP': 'Goederen',
	'FOURTH_STEP': 'Traject',
	'FIFTH_STEP': 'Overmaten',
};

export default function CreateContainerModal({ plan, isOpen, onClose }) {
	const unloToHue = useContext(PortColorFunction);

	const [ step, setStep ] = useState('FIRST_STEP');
	const [ isEmpty, setIsEmpty ] = useState(true);
	const [ carrierReference, setCarrierReference ] = useState(null);
	const [ containerNumber, setContainerNumber ] = useState('');
	const [ portOfLoading, setPortOfLoading ] = useState('');
	const [ portOfDischarge, setPortOfDischarge ] = useState('');
	const [ stackLocation, setStackLocation ] = useState('');
	const [ isoCode, setIsoCode ] = useState('');
	const [ isVgm, setIsVgm ] = useState(false);
	const [ grossWeight, setGrossWeight ] = useState(null);
	const [ isReefer, setIsReefer ] = useState(false);
	const [ reeferTemperature, setReeferTemperature ] = useState(null);
	const [ cellCode, setCellCode ] = useState('');

	const [ addContainer ] = useMutation(gql`mutation Mutation($planId: Int!, $container: ContainerCreation!) {
        addContainer(planId: $planId, container: $container) { id }
    }`);

	return <Modal
		isOpen={isOpen}
		onClose={() => { onClose(); setStep('FIRST_STEP'); }}
		title={titles[step] || 'Nieuwe container'}>
		{step == 'PROCESSING' && <LoadingOverlay />}
		{step == 'FIRST_STEP' && <Form style={{ minWidth: 'var(--u-384)', maxWidth: 'var(--u-512)' }} onSubmit={() => setStep('SECOND_STEP')}>
			<div style={{ maxWidth: 'var(--u-384)', paddingBottom: 'var(--u-8)' }}>
				{prepContainerNumber(containerNumber).length == 11 && !isContainerNumberValid(containerNumber) && <WarningBanner>Het ingevuld containernummer heeft geen kloppende check-digit, controleer de invoer. Het is mogelijk dat dit klopt, bijvoorbeeld bij shippers-owned containers.</WarningBanner>}
				{prepContainerNumber(containerNumber).length > 11 && <WarningBanner>Het ingevuld containernummer is langer dan gebruikelijk, controleer of dit klopt</WarningBanner>}
			</div>

			<div style={{ display: 'grid', gridTemplateColumns: '1fr 48px', gap: '0px 16px', maxWidth: 'var(--u-384)' }}>
				<WithLabel label="Containernummer">
					<InputField onChange={cn => setContainerNumber(cn.toUpperCase())} value={containerNumber} />
				</WithLabel>
				<WithLabel label="Is vol">
					<Toggle onChange={() => setIsEmpty(!isEmpty)} value={!isEmpty} />
				</WithLabel>
				<WithLabel label="ISO-code">
					<InputField onChange={ic => setIsoCode(ic.toUpperCase())} value={isoCode} />
				</WithLabel>
				<div />
				<WithLabel label="B/L">
					<InputField onChange={setCarrierReference} value={carrierReference} />
				</WithLabel>
			</div>
			<ButtonArea>
				<ValidatedButton level="primary" type="submit">Doorgaan&nbsp; <span className="fa fa-chevron-circle-right" /></ValidatedButton>
			</ButtonArea>
		</Form>}
		{step == 'SECOND_STEP' && <Form style={{ minWidth: 'var(--u-384)', maxWidth: 'var(--u-384)' }} onSubmit={() => { isEmpty ? setStep('FOURTH_STEP') : setStep('FOURTH_STEP'); }}>
			{isEmpty && grossWeight > 10000 && <WarningBanner>Deze container is leeg, maar heeft een verdacht hoog gewicht</WarningBanner>}
			{isReefer && (isoCode || '').slice(2, 3).toUpperCase() != 'R' && (isoCode || '').slice(2, 3).toUpperCase() != 'H' && <WarningBanner>Deze container is gemarkeerd als actieve reefer, maar de ISO-code duidt er op dat het geen reefercapaciteiten heeft</WarningBanner>}
			{isReefer && !isEmpty && <WarningBanner>Deze container is gemarkeerd als actieve reefer, maar is leeg</WarningBanner>}


			<div style={{ display: 'grid', paddingTop: 'var(--u-8)', gridTemplateColumns: '64px 1fr' }}>
				<WithLabel label="VGM">
					<Toggle onChange={() => setIsVgm(!isVgm)} value={isVgm} />
				</WithLabel>
				<WithLabel label="Brutogewicht">
					<InputField onChange={setGrossWeight} style={{ maxWidth: 'calc(100% - 48px)' }} value={grossWeight} /> <span>kg</span>
				</WithLabel>
				<WithLabel label="Reefer">
					<Toggle
						onChange={() => {
							setIsReefer(!isReefer);
							if (!!isReefer) setReeferTemperature('');
						}}
						value={isReefer} />
				</WithLabel>
				<WithLabel label="Reefer Temperature">
					<InputField disabled={!isReefer} onChange={setReeferTemperature} style={{ maxWidth: 'calc(100% - 48px)' }} value={reeferTemperature} /> <span>&deg;C</span>
				</WithLabel>
			</div>
			<ButtonArea>
				<ValidatedButton level="primary" type="submit" validations={[
					{ valid: (isVgm || grossWeight != null) ? !Number.isNaN(grossWeight) : true, error: "Brutogewicht moet een getal zijn" },
					{ valid: (isVgm || grossWeight != null) ? grossWeight > 0 : true, error: "Brutogewicht moet groter dan 0 kg zijn" },
					{ valid: !isReefer || ((reeferTemperature + '').length == 0 || !isNaN(reeferTemperature)), error: "Reefertemperatuur moet een getal zijn" },
				]}>
					Doorgaan&nbsp; <span className="fa fa-chevron-circle-right" />
				</ValidatedButton>
				<Button onClick={() => setStep('FIRST_STEP')}>
					Terug
				</Button>
			</ButtonArea>
		</Form>}
		{step == 'FOURTH_STEP' && <Form style={{ minWidth: 'var(--u-512)', maxWidth: 'var(--u-1200)' }} onSubmit={() => {
			const container = {
				equipmentIdentifier: containerNumber,
				isoCode,
				portOfLoading,
				portOfDischarge,
				carrierReference,
				isEmpty,
				grossWeight,
				isVgm,
				reeferTemperature,
				stackLocation,
				cellCode,
			};
			setStep('PROCESSING');
			addContainer({ variables: { planId: plan.id, container } })
				.then(onClose)
				.catch((e) => console.error('Something went wrong', e))
				.finally(() => setStep('FOURTH_STEP'));
		}}>
			<div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: '12px 16px' }}>
				<WithLabel label="Port of Loading">
					<InputField onChange={setPortOfLoading} style={{ background: generateColorForHue(unloToHue(portOfLoading), DEFAULT_PORT_LIGHTNESS) }} value={portOfLoading} />
				</WithLabel>
				<WithLabel label="Port of Discharge">
					<InputField onChange={setPortOfDischarge} style={{ background: generateColorForHue(unloToHue(portOfDischarge), DEFAULT_PORT_LIGHTNESS) }} value={portOfDischarge} />
				</WithLabel>
			</div>
			<div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: '12px 16px' }}>
				<WithLabel label="Stacklocatie">
					<InputField onChange={setStackLocation} value={stackLocation} />
				</WithLabel>
			</div>
			<div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: '12px 16px' }}>
				<WithLabel label="Stuwagepositie">
					<InputField onChange={setCellCode} value={cellCode} />
				</WithLabel>
			</div>
			<ButtonArea>
				<ValidatedButton type="submit" level="primary" validations={[
					{ valid: portOfLoading.length > 0, error: "Port of Loading is verplicht" },
					{ valid: portOfDischarge.length > 0, error: "Port of Discharge is verplicht" },
					{ valid: cellCode.length == 7, error: 'Stuwagepositie is verplicht' },
				]}>
					<span className="fa fa-check" />&nbsp;Opslaan
				</ValidatedButton>
				<Button onClick={() => setStep('SECOND_STEP')}>
					Terug
				</Button>
			</ButtonArea>
		</Form>}
	</Modal>;
}

export function prepContainerNumber(containerNumber) {
	if (containerNumber == null) return '';
	return containerNumber.replace(/\s/g, '').toUpperCase();
}

export function isContainerNumberValid(containerNumber) {
	const preppedCn = prepContainerNumber(containerNumber);
	if (preppedCn.length != 11) return false;

	const mapping = {
		'A': 10, 'B': 12, 'C': 13, 'D': 14, 'E': 15, 'F': 16, 'G': 17, 'H': 18, 'I': 19, 'J': 20, 'K': 21, 'L': 23,
		'M': 24, 'N': 25, 'O': 26, 'P': 27, 'Q': 28, 'R': 29, 'S': 30, 'T': 31, 'U': 32, 'V': 34, 'W': 35, 'X': 36,
		'Y': 37, 'Z': 38,
		'0': 0, '1': 1, '2': 2, '3': 3, '4': 4, '5': 5, '6': 6, '7': 7, '8': 8, '9': 9,
	};

	let total = 0;
	for (let i = 0; i <= 9; i++) {
		total += Math.pow(2, i) * mapping[containerNumber[i]];
	}

	return mapping[containerNumber[10]] == (total - (Math.floor(total / 11) * 11));
}