import { gql, useMutation, useQuery, useSubscription } from '@apollo/client';
import { useMemo, useState } from 'react';
import DisplayDuration from '../DisplayDuration';
import { SortableContext, useSortable } from '@dnd-kit/sortable';
import { useSubscribedQuery } from '../../../../hooks/useSubscribedQuery';
import { useDndContext, useDndMonitor } from '@dnd-kit/core';
import { DiggingCalculationsProvider, useBayExclusionSets } from '../../viewcall/digging/DiggingCalculationsProvider';
import ProposedLoadingOrder from './proposedorder/ProposedLoadingOrder';

export default function OperationalPlanningScreen({ stowagePlan }) {
	const [ bayOrderOverride, setBayOrderOverride ] = useState(null);

	const operationalPlanSubscription = useSubscription(gql`subscription Subscription($opPlanId: Int!) {
		subscribeToOperationalPlan(operationalPlanId: $opPlanId)
	}`, { variables: { opPlanId: Number(stowagePlan?.operationalPlan?.id) } });
	const operationalPlanQuery = useSubscribedQuery(useQuery(gql`query Query($opPlanId: Int!) {
		operationalPlan(id: $opPlanId) {
			id,
			shipPosition,
			bays {
				id,
				bayNumber,
				operationType,
				baySection,
				crane,
				orderForCrane,
				allowTwinning,
				estimatedTimePerMoveDischarging,
				estimatedTimePerMoveLoading,
				plannedStart,
				plannedEnd,
			}
		}
	}`, { variables: { opPlanId: Number(stowagePlan?.operationalPlan?.id) }, onCompleted: () => setBayOrderOverride(null) }), operationalPlanSubscription);
	const operationalPlan = useMemo(() => operationalPlanQuery?.data?.operationalPlan, [ operationalPlanQuery?.data?.operationalPlan ]);

	// Get container digging information
	const containerDiggingQuery = useQuery(gql`{
		containerDiggingMap {
			containerNumber,
			blocking
        }
	}`, { pollInterval: 60 * 1000 });
	const containerDiggingMap = useMemo(() => (containerDiggingQuery?.data?.containerDiggingMap ?? []).reduce((tot, elem) => ({ ...tot, [elem.containerNumber]: elem }), {}), [ containerDiggingQuery.data ]);

	const [ moveOperationalPlanBay ] = useMutation(gql`mutation Mutation($opPlanId: Int!, $oldIndex: Int!, $newIndex: Int!) {
		moveOperationalPlanBay(opPlanId: $opPlanId, oldIndex: $oldIndex, newIndex: $newIndex) { id }
	}`);

	const maxEvenBay = Math.floor(stowagePlan.shipTemplate.cells.map(c => c.bay).reduce((a, b) => Math.max(a, b), 0) / 2) * 2;
	const augmentedBays = useMemo(() => {
		if (operationalPlanQuery?.data?.operationalPlan?.bays == null) return [];
		const relevantInbounds = stowagePlan.inboundCells.filter(cell => cell.container != null && (cell.container.portOfDischarge == stowagePlan.portUnlo || cell.container.carrierReference == 'SHIFTER' || cell.container.visibility == 'VISIBLE'));
		const relevantOutbounds = stowagePlan.outboundCells.filter(cell => cell.container != null && (cell.container.portOfLoading == stowagePlan.portUnlo || cell.container.carrierReference == 'SHIFTER' || cell.container.visibility == 'VISIBLE'));

		const augBays = operationalPlanQuery.data.operationalPlan.bays.map(bay => {
			function cellInBay(cell) {
				return cell.bay == bay.bayNumber || cell.bay == bay.bayNumber - 1 || cell.bay == bay.bayNumber + 1;
			}

			function cellBelowDeck(cell) {
				return cell.tier < 70;
			}

			function isTwinOfAnother(cell, cells) {
				return cell.bay == bay.bayNumber + 1 && cells.some(c => c.bay == bay.bayNumber - 1 && c.row == cell.row && c.tier == cell.tier);
			}

			const dischargeCells = bay.operationType == 'LOADING' ? [] : relevantInbounds.filter(cellInBay).filter(cell => (bay.baySection == 'BELOW_DECK') == cellBelowDeck(cell));
			const loadingCells = bay.operationType == 'DISCHARGING' ? [] : relevantOutbounds.filter(cellInBay).filter(cell => (bay.baySection == 'BELOW_DECK') == cellBelowDeck(cell));

			const movesDischarge = dischargeCells.filter(cell => !bay.allowTwinning || !isTwinOfAnother(cell, relevantInbounds)).length;
			const movesLoading = loadingCells.filter(cell => !bay.allowTwinning || !isTwinOfAnother(cell, relevantOutbounds)).length;

			return {
				...bay,
				movesDischarge,
				dischargeTime: movesDischarge * bay.estimatedTimePerMoveDischarging,
				dischargeCells,
				movesLoading,
				loadingTime: movesLoading * bay.estimatedTimePerMoveDischarging,
				loadingCells,
			};
		}).toSorted((a, b) => a.orderForCrane - b.orderForCrane);

		if (bayOrderOverride == null) return augBays;
		return bayOrderOverride.map(id => augBays.find(b => b.id == id));
	}, [ operationalPlan?.bays, bayOrderOverride ]);

	const totalMoves = augmentedBays.reduce((acc, bay) => acc + bay.movesDischarge + bay.movesLoading, 0);
	const totalTime = augmentedBays.reduce((acc, bay) => acc + bay.dischargeTime + bay.loadingTime, 0);

	useDndMonitor({
		onDragEnd(event) {
			const { active, over } = event;
			if (active.id !== over.id) {
				// Find the indices and reorder the array
				const bayIds = augmentedBays.map(b => b.id);
				const oldIndex = bayIds.indexOf(active.id);
				const newIndex = bayIds.indexOf(over.id);

				const item = bayIds[oldIndex];
				bayIds.splice(oldIndex, 1);
				bayIds.splice(newIndex, 0, item);

				setBayOrderOverride(bayIds);
				moveOperationalPlanBay({ variables: { opPlanId: Number(operationalPlan?.id), oldIndex, newIndex } });
			}
		},
	});

	const warnings = useMemo(() => {
		const output = [];
		for (let i = 0; i < augmentedBays.length; i++) {
			const bay = augmentedBays[i];
			if (bay.operationType == 'LOADING' && augmentedBays.slice(i).some(b => b.bayNumber == bay.bayNumber && b.operationType == 'DISCHARGING')) {
				output.push({ bayId: bay.id, message: `Bay ${bay.bayNumber} wordt geladen voordat deze gelost is` });
			}

			if (bay.operationType == 'LOADING' && bay.baySection == 'ABOVE_DECK' && augmentedBays.slice(i).some(b => b.bayNumber == bay.bayNumber && b.baySection == 'BELOW_DECK' && b.operationType == 'LOADING')) {
				output.push({ bayId: bay.id, message: `Bay ${bay.bayNumber} wordt bovendeks geladen voordat deze onderdeks geladen is` });
			}
		}

		return output;
	}, [ augmentedBays ]);

	return <DiggingCalculationsProvider stowagePlan={stowagePlan} operationalPlan={operationalPlan} containerDiggingMap={containerDiggingMap}>
		<div style={{ display: 'grid', gridTemplateColumns: '250px max-content max-content', alignItems: 'stretch', position: 'relative', height: '100%', background: 'var(--col-grey-100)' }}>
			<div style={{ background: 'var(--col-white)', height: '100%', boxShadow: '0 0 4px rgba(0, 0, 0, 0.05), 0 0 16px rgba(0, 0, 0, 0.03)' }}>
				<div style={{ zIndex: 10, height: '250px', width: '100%', background: 'rgba(0, 0, 0, 0.05)', backdropFilter: 'blur(6px)', padding: 'var(--u-8)', borderRadius: '2px', boxShadow: '0 2px 8px -2px inset rgba(0, 0, 0, 0.04)' }}>
					<div>
						{augmentedBays.map(bay => <div style={{ height: ((((bay.dischargeTime + bay.loadingTime) / totalTime) * (250 - 16)) + 'px'), position: 'relative' }}>
							<div style={{ position: 'absolute', [operationalPlan.shipPosition == 'STARBOARD_AT_QUAY' ? 'right' : 'left']: (((bay.bayNumber - 2) / (maxEvenBay + 1)) * 100) + '%', width: ((3 / (maxEvenBay + 1)) * 100) + '%', height: '100%', background: bay.movesDischarge > 0 ? 'var(--col-primary-300)' : 'var(--col-primary-600)', padding: '0 var(--u-4)' }}></div>
						</div>)}
					</div>
				</div>

				<div style={{ display: 'flex', flexDirection: 'column', gap: 'var(--u-8)', padding: 'var(--u-8)' }}>
					{warnings.map(w => <div style={{ color: 'var(--col-orange-800)', lineHeight: '1.2em', background: 'var(--col-orange-100)', padding: 'var(--u-4)', borderLeft: '4px solid var(--col-orange-500)' }}>{w.message}</div>)}
				</div>
			</div>

			<div style={{ height: '100%', overflow: 'auto', padding: 'var(--u-16)' }}>
				<h1 style={{ color: 'var(--col-grey-700', fontSize: '1.5em' }}>Werkvolgorde</h1>
				<div style={{ width: 'var(--u-512)', display: 'flex', flexDirection: 'column', gap: 'var(--u-8)', padding: 'var(--u-16) 0' }}>
					<h2 style={{ fontSize: '1.2em', color: 'var(--col-grey-600)', fontWeight: 'normal', marginTop: 0 }}>Kraan 1</h2>
					<SortableContext items={useMemo(() => augmentedBays.map(b => b.id), [ augmentedBays ])}>
						<>
							{augmentedBays.map((bay, idx, bays) => <BayCard key={bay.id} previousSameBay={bays[idx - 1]?.bayNumber == bay.bayNumber} bay={bay} containerDiggingMap={containerDiggingMap} />)}
						</>
					</SortableContext>
					<hr style={{ borderColor: 'var(--col-grey-200)' }} />
					<div style={{ textAlign: 'right', paddingRight: 'var(--u-16)' }}>
						<span className="fa fa-clock-o" />&nbsp; <span style={{ fontWeight: 'bold' }}><DisplayDuration value={totalTime} /></span>
					</div>
				</div>
			</div>

			<div style={{ display: 'flex', flexDirection: 'column', padding: 'var(--u-16)', overflow: 'auto' }}>
				<h1 style={{ color: 'var(--col-grey-700', fontSize: '1.5em' }}>Voorgestelde laadvolgorde</h1>
				<ProposedLoadingOrder stowagePlan={stowagePlan} operationalPlan={operationalPlan} bays={augmentedBays} containerDiggingMap={containerDiggingMap} />
			</div>
		</div>
	</DiggingCalculationsProvider>;
}

function BayCard({ bay, containerDiggingMap, previousSameBay }) {
	const sortable = useSortable({ id: `${bay.id}`, transition: { duration: 100, easing: 'ease-out' } });
	const sortableCss = {
		transform: 'translate(' + (sortable.transform?.x ?? 0) + 'px, ' + (sortable?.transform?.y ?? 0) + 'px)',
		transition: sortable.transition,
	};

	const bayExclusionSets = useBayExclusionSets();
	const bayExclusionSet = useMemo(() => (bayExclusionSets[bay.bayNumber] ?? {})[bay.baySection] ?? new Set(), [ bayExclusionSets, bay ]);
	const diggingMoves = useMemo(() => {
		return bay.loadingCells
			.filter(c => c.container != null && c.container.equipmentIdentifier != null && c.container.equipmentIdentifier != '')
			.map(c => containerDiggingMap[c.container.equipmentIdentifier])
			.filter(c => c != null)
			.map(c => c.blocking.filter(cnum => !bayExclusionSet.has(cnum.trim())));
	}, [ bay, bayExclusionSet, containerDiggingMap ]);

	return <div ref={sortable.setNodeRef} {...sortable.attributes} {...sortable.listeners} style={{ ...sortableCss, background: 'var(--col-white)', display: 'grid', gridTemplateColumns: '24px 32px min-content 1fr 1fr max-content', alignItems: 'center', padding: 'var(--u-4) 0', lineHeight: '1.2em', boxShadow: '0 2px 4px rgba(0, 0, 0, 0.05), 0 2px 16px rgba(0, 0, 0, 0.04)', marginTop: previousSameBay ? '-7px' : '0' }}>
		<div style={{ color: 'var(--col-grey-300)', textAlign: 'center', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
			<span className="fa fa-ellipsis-v" style={{ marginRight: '1px' }} />
			<span className="fa fa-ellipsis-v" />
		</div>
		<div style={{ textAlign: 'center' }}>
			<span style={{ textTransform: 'uppercase', fontSize: '0.8em', color: 'var(--col-grey-400)' }}>Bay</span><br />
			<span style={{ color: 'var(--col-grey-700)', fontWeight: 'bold', fontSize: '1.2em' }}>{bay.bayNumber}</span>
		</div>
		<div style={{ paddingRight: 'var(--u-24)', display: 'flex', flexDirection: 'column', gap: 'var(--u-2)', paddingTop: 'var(--u-2)', justifyContent: 'center' }}>
			<span className="fa fa-arrow-up" title="Bovendeks" style={{ fontSize: '1em', color: bay.baySection == 'ABOVE_DECK' ? 'var(--col-grey-500)' : 'var(--col-grey-100)', marginTop: '-1px' }} />
			<span className="fa fa-arrow-down" title="Onderdeks" style={{ fontSize: '1em', color: bay.baySection == 'BELOW_DECK' ? 'var(--col-grey-700)' : 'var(--col-grey-100)', marginTop: '-1px' }} />
		</div>
		<div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', paddingRight: 'var(--u-32)' }}>
			{bay.movesDischarge > 0 && <div style={{ display: 'flex', gap: 'var(--u-8)', alignItems: 'baseline', fontSize: '1.2em' }} title="Lossing">
				<span style={{ display: 'inline-block', minWidth: '4ch', textAlign: 'right' }}>{bay.movesDischarge}<span style={{ opacity: 0.5, fontSize: '0.8em' }}>&times;</span></span>
				<span className="fa fa-arrow-circle-down" style={{ color: 'var(--col-primary-400)' }} />
				<span style={{ color: 'var(--col-primary-400)' }}>Lossen</span>
			</div>}

			{bay.movesLoading > 0 && <div style={{ display: 'flex', gap: 'var(--u-8)', alignItems: 'baseline', fontSize: '1.2em' }} title="Lading">
				<span style={{ display: 'inline-block', minWidth: '4ch', textAlign: 'right' }}>{bay.movesLoading}<span style={{ opacity: 0.5, fontSize: '0.8em' }}>&times;</span></span>
				<span className="fa fa-arrow-circle-up" style={{ color: 'var(--col-primary-600)' }} />
				<span style={{ color: 'var(--col-primary-600)' }}>Laden</span>
			</div>}
		</div>
		<div>
			{bay.movesDischarge > 0 && <div style={{ display: 'flex', gap: 'var(--u-8)', alignItems: 'baseline', fontSize: '1.2em' }} title="Lossing">
				<span style={{ display: 'inline-block', minWidth: '4ch', textAlign: 'right' }}>{bay.dischargeCells.length}<span style={{ opacity: 0.5, fontSize: '0.8em' }}>&times;</span></span>
				<span className="fa fa-arrow-circle-down" style={{ color: 'var(--col-primary-400)' }} />
				<span style={{ color: 'var(--col-primary-400)' }}>Wegzetten</span>
			</div>}

			{bay.movesLoading > 0 && <div style={{ display: 'flex', gap: 'var(--u-8)', alignItems: 'baseline', fontSize: '1.2em' }} title="Lading">
				<span style={{ display: 'inline-block', minWidth: '4ch', textAlign: 'right' }}>{bay.loadingCells.length + diggingMoves.reduce((tot, n) => tot + n.length, 0)}<span style={{ opacity: 0.5, fontSize: '0.8em' }}>&times;</span></span>
				<span className="fa fa-arrow-circle-up" style={{ color: 'var(--col-primary-600)' }} />
				<span style={{ color: 'var(--col-primary-600)' }}>Uitgraven</span>
			</div>}
		</div>
		<div style={{ textAlign: 'right', paddingRight: 'var(--u-16)' }}>
			<span className="fa fa-clock-o" />&nbsp; <span style={{ fontWeight: 'bold', color: 'var(--col-grey-700)' }}><DisplayDuration value={(bay.dischargeTime + bay.loadingTime)} /></span>
		</div>
	</div>;
}