import { createContext, useContext, useMemo } from 'react';
import { prepContainerNumber } from '../CreateContainerModal';

const DiggingCalculationsContext = createContext({
	bayExclusionSets: {},
});

function loadingOrder(shipPosition, a, b) {
	if (a.tier > b.tier) return 1;
	if (a.tier < b.tier) return -1;
	const onOverSide = (
		shipPosition == 'STARBOARD_AT_QUAY' && (
			(a.row % 2 == 1 && (b.row < a.row || b.row % 2 == 0)) // if container on odd side, then even or smaller is overside
			|| (a.row % 2 == 0 && b.row > a.row && b.row % 2 == 0) // container on even side? Then number needs to be bigger to be 'left' of container
		)
	) || (
		shipPosition == 'PORT_SIDE_AT_QUAY' && (
			(a.row % 2 == 1 && (b.row > a.row && b.row % 2 == 1)) // if container on odd side, then even or bigger is overside
			|| (a.row % 2 == 0 && (b.row < a.row || b.row % 2 == 1)) // container on even side? Then number needs to be smaller or odd to be 'right' of container
		)
	);
	return onOverSide ? -1 : 1;
}

/**
 * The goal of this component is to provide a context element which tells you for each bay upper/lower deck,
 * which containers are at that point already loaded, and therefore can be removed from the blocking list per individual container
 *
 * This does NOT take into account the ordering within a bay, that will have to be calculated separately
 */
export function DiggingCalculationsProvider({ stowagePlan, operationalPlan, customBayOrder, children }) {
	const tasks = useMemo(() => {
		const tasks = [];
		if (operationalPlan == null || stowagePlan == null) return tasks;

		const bays = operationalPlan.bays.toSorted((a, b) => a.orderForCrane - b.orderForCrane);
		for (const bay of bays) {
			if (bay.operationType == 'DISCHARGING' || bay.operationType == 'COMBINED_DUAL_CYCLING' || bay.operationType == 'COMBINED_SEQUENTIAL') {
				const inboundCells = stowagePlan.inboundCells
					.filter(cell => cell.container?.portOfDischarge == stowagePlan.portUnlo || cell.container?.visibility == 'VISIBLE')
					.filter(cell => cell.bay == bay.bayNumber || cell.bay == bay.bayNumber - 1 || cell.bay == bay.bayNumber + 1)
					.filter(cell => (bay.baySection == 'BELOW_DECK') == cell.tier < 70)
					.toSorted((a, b) => -1 * loadingOrder(operationalPlan.shipPosition, a, b));

				for (const inboundCell of inboundCells) {
					tasks.push({ type: 'DISCHARGE', cell: inboundCell });
				}
			}

			if (bay.operationType == 'LOADING' || bay.operationType == 'COMBINED_DUAL_CYCLING' || bay.operationType == 'COMBINED_SEQUENTIAL') {
				const outboundCells = stowagePlan.outboundCells
					.filter(cell => cell.container?.portOfLoading == stowagePlan.portUnlo || cell.container?.visibility == 'VISIBLE')
					.filter(cell => cell.bay == bay.bayNumber || cell.bay == bay.bayNumber - 1 || cell.bay == bay.bayNumber + 1)
					.filter(cell => (bay.baySection == 'BELOW_DECK') == cell.tier < 70)
					.toSorted((a, b) => loadingOrder(operationalPlan.shipPosition, a, b));

				for (const inboundCell of outboundCells) {
					tasks.push({ type: 'LOAD', cell: inboundCell });
				}
			}
		}

		console.log('all tasks', tasks);
		return tasks;
	}, [ stowagePlan, operationalPlan ]);

	const bayExclusionSets = useMemo(() => {
		if (stowagePlan == null || operationalPlan == null) return {};

		const bays = customBayOrder != null ? customBayOrder.map(bayId => operationalPlan.bays.find(b => b.id == bayId)) : operationalPlan.bays.toSorted((a, b) => a.orderForCrane - b.orderForCrane);
		const exclusionSetsPerBay = {};
		const exclusionCountPerBay = {};
		const exclusionsRunningTotal = new Set();
		let exclusionCountsRunningTotal = 0;

		for (const bay of bays) {
			if (bay.operationType == 'DISCHARGING') continue;
			if (exclusionSetsPerBay[bay.bayNumber] == null) exclusionSetsPerBay[bay.bayNumber] = {};
			if (exclusionCountPerBay[bay.bayNumber] == null) exclusionCountPerBay[bay.bayNumber] = {};
			exclusionSetsPerBay[bay.bayNumber][bay.baySection] = new Set([ ...exclusionsRunningTotal ]);
			exclusionCountPerBay[bay.bayNumber][bay.baySection] = exclusionCountsRunningTotal;

			for (const cell of stowagePlan.outboundCells) {
				// Skip empty cells
				if (cell.container == null || cell.container.equipmentIdentifier == null || cell.container.equipmentIdentifier == '') continue;
				if (cell.container.portOfLoading != stowagePlan.portUnlo && cell.container.portOfDischarge != stowagePlan.portUnlo && cell.container.visibility != 'VISIBLE') continue;

				// Skip cells that are not in the same 40ft bay or the two 20ft bays
				if (cell.bay != bay.bayNumber && cell.bay != bay.bayNumber - 1 && cell.bay != bay.bayNumber + 1) continue;

				// Skip above deck/below deck if not matching
				if (cell.tier < 70 && bay.baySection == 'ABOVE_DECK') continue;
				if (cell.tier >= 70 && bay.baySection == 'BELOW_DECK') continue;

				exclusionsRunningTotal.add(cell.container?.equipmentIdentifier);
				exclusionCountsRunningTotal++;
			}
		}

		return { exclusionSetsPerBay, exclusionCountPerBay };
	}, [ stowagePlan, operationalPlan, customBayOrder ]);

	const containerExclusionSetsWithinBay = useMemo(() => {
		const exclusionSetPerContainer = {};
		const exclusionCountPerCellCode = {};
		if (stowagePlan == null || operationalPlan == null) return;

		const cellsByBay = operationalPlan.bays.filter(bay => bay.operationType != 'DISCHARGING').map(bay => {
			return {
				bayId: bay.id,
				cells: stowagePlan.outboundCells
					.filter(cell => cell.bay == bay.bayNumber || cell.bay == bay.bayNumber - 1 || cell.bay == bay.bayNumber + 1)
					.filter(cell => (bay.baySection == 'BELOW_DECK' && cell.tier < 70) || (bay.baySection == 'ABOVE_DECK' && cell.tier >= 70))
					.filter(cell => cell.container != null && cell.container.equipmentIdentifier != null && cell.container.equipmentIdentifier != ''),
			};
		}).reduce((tot, elem) => ({ ...tot, [elem.bayId]: elem.cells }), {});

		for (const bayId of Object.keys(cellsByBay)) {
			for (const cell of cellsByBay[bayId]) {
				if (cell.container.portOfLoading != stowagePlan.portUnlo && cell.container.portOfDischarge != stowagePlan.portUnlo && cell.container.visibility != 'VISIBLE') continue;
				if (exclusionSetPerContainer[cell.container.equipmentIdentifier] == null) exclusionSetPerContainer[prepContainerNumber(cell.container.equipmentIdentifier)] = new Set();
				if (exclusionCountPerCellCode[cell.cellCode] == null) exclusionCountPerCellCode[cell.cellCode] = 0;

				for (const otherCell of cellsByBay[bayId]) {
					if (otherCell.container.portOfLoading != stowagePlan.portUnlo && otherCell.container.portOfDischarge != stowagePlan.portUnlo && otherCell.container.visibility != 'VISIBLE') continue;
					const isBelowContainer = otherCell.tier < cell.tier;
					const sameTier = otherCell.tier == cell.tier;
					const onOverSide =
						(
							operationalPlan.shipPosition == 'STARBOARD_AT_QUAY' && (
								(cell.row % 2 == 1 && (otherCell.row < cell.row || otherCell.row % 2 == 0)) // if container on odd side, then even or smaller is overside
								|| (cell.row % 2 == 0 && otherCell.row > cell.row && otherCell.row % 2 == 0) // container on even side? Then number needs to be bigger to be 'left' of container
							)
						) || (
							operationalPlan.shipPosition == 'PORT_SIDE_AT_QUAY' && (
								(cell.row % 2 == 1 && (otherCell.row > cell.row && otherCell.row % 2 == 1)) // if container on odd side, then even or bigger is overside
								|| (cell.row % 2 == 0 && (otherCell.row < cell.row || otherCell.row % 2 == 1)) // container on even side? Then number needs to be smaller or odd to be 'right' of container
							)
						);

					if (isBelowContainer || (sameTier && onOverSide)) {
						exclusionSetPerContainer[prepContainerNumber(cell.container.equipmentIdentifier)].add(prepContainerNumber(otherCell.container.equipmentIdentifier));
						exclusionCountPerCellCode[cell.cellCode]++;
					}
				}
			}
		}

		return { exclusionSetPerContainer, exclusionCountPerCellCode };
	}, [ stowagePlan, operationalPlan ]);

	const value = useMemo(() => ({
		bayExclusionSets: bayExclusionSets?.exclusionSetsPerBay,
		bayExclusionCounts: bayExclusionSets?.exclusionCountPerBay,
		containerExclusionSetsWithinBay: containerExclusionSetsWithinBay?.exclusionSetPerContainer,
		exclusionCountPerCellCode: containerExclusionSetsWithinBay?.exclusionCountPerCellCode,

		tasks,
		tasksByCellCode: tasks.reduce((tot, task, idx) => ({ ...tot, [task.cell.cellCode]: [ ...(tot[task.cell.cellCode] ?? []), { task, index: idx } ] }), {}),
	}), [ bayExclusionSets ]);
	console.log(value.tasksByCellCode);

	return <DiggingCalculationsContext.Provider value={value}>
		{children}
	</DiggingCalculationsContext.Provider>;
}

export function useBayExclusionSets() {
	return useContext(DiggingCalculationsContext).bayExclusionSets;
}

export function useContainerExclusionSetsWithinBay() {
	return useContext(DiggingCalculationsContext).containerExclusionSetsWithinBay;
}

export function useExclusionSetForCell(cell) {
	const bayExclusionSets = useBayExclusionSets();
	const containerExclusionSetsWithinBay = useContainerExclusionSetsWithinBay();

	return useMemo(() => {
		if (cell == null || containerExclusionSetsWithinBay == null || bayExclusionSets == null) return new Set();

		const currentBayExclusionSets = bayExclusionSets[cell.bay] ?? bayExclusionSets[cell.bay - 1] ?? bayExclusionSets[cell.bay + 1];

		return new Set([ ...(currentBayExclusionSets?.[cell?.tier < 70 ? 'BELOW_DECK' : 'ABOVE_DECK'] ?? new Set()), ...containerExclusionSetsWithinBay[prepContainerNumber(cell?.container?.equipmentIdentifier)] ?? new Set() ]);
	}, [ cell, containerExclusionSetsWithinBay, bayExclusionSets ]);
}

export function useExclusionCountForCell(cell) {
	const bayExclusionCounts = useContext(DiggingCalculationsContext).bayExclusionCounts;
	const exclusionCountPerCellCode = useContext(DiggingCalculationsContext).exclusionCountPerCellCode;

	return useMemo(() => {
		if (cell == null || exclusionCountPerCellCode == null || bayExclusionCounts == null) return 0;
		if (exclusionCountPerCellCode[cell.cellCode] == null) return 0;

		const currentBayExclusionCount = bayExclusionCounts[cell.bay] ?? bayExclusionCounts[cell.bay - 1] ?? bayExclusionCounts[cell.bay + 1];

		return (currentBayExclusionCount?.[cell?.tier < 70 ? 'BELOW_DECK' : 'ABOVE_DECK'] ?? 0) + (exclusionCountPerCellCode[cell.cellCode] ?? 0);
	}, [ cell, bayExclusionCounts, exclusionCountPerCellCode ]);
}

export function useInboundExclusionCountForCell(cell) {
	const bayExclusionCounts = useContext(DiggingCalculationsContext).inboundBayExclusionCounts;
	const exclusionCountPerCellCode = useContext(DiggingCalculationsContext).inboundExclusionCountPerCellCode;

	return useMemo(() => {
		if (cell == null || exclusionCountPerCellCode == null || bayExclusionCounts == null) return 0;
		if (exclusionCountPerCellCode[cell.cellCode] == null) return 0;

		const currentBayExclusionCount = bayExclusionCounts[cell.bay] ?? bayExclusionCounts[cell.bay - 1] ?? bayExclusionCounts[cell.bay + 1];

		return (currentBayExclusionCount?.[cell?.tier < 70 ? 'BELOW_DECK' : 'ABOVE_DECK'] ?? 0) + (exclusionCountPerCellCode[cell.cellCode] ?? 0);
	}, [ cell, bayExclusionCounts, exclusionCountPerCellCode ]);
}

export function useTasks() {
	return useContext(DiggingCalculationsContext).tasks;
}

export function useTasksByCellCode() {
	return useContext(DiggingCalculationsContext).tasksByCellCode;
}