import _ from 'lodash';
import { containersCanBeSwapped, generateLowerTiers, generateRows, generateUpperTiers, getHighlightedCell, toCellCode } from './BigBay';
import { prepContainerNumber } from '../CreateContainerModal';
import { DEFAULT_PORT_LIGHTNESS, generateColorForHue } from '../../../../util/colors';
import React, { useContext } from 'react';
import PortColorFunction from '../../../../context/PortColorFunction';
import { useExclusionSetForCell } from '../digging/DiggingCalculationsProvider';

export default function MiniBay({ containerDiggingMap, colorMode, voyageData, showSwapSuggestions, cellsByCode, shifters, highlightCell, inOutBound, hasTemplate, templateCells, bay, bayNumber, setStatusMsg = () => {}, selectedBay, onSelectBay, polFilter, betterHighlightForPolOnly, blockedCells, combinedBays, editingMode }) {
	const selected = bayNumber == selectedBay;
	const { cells } = bay;

	const byRow = _.groupBy(cells, 'row');

	return <div className={'bay' + (selected ? ' bay-selected' : '')} onClick={() => onSelectBay()}>
		<div className="bay-upper-deck">
			<Rows
				colorMode={colorMode}
				voyageData={voyageData}
				showSwapSuggestions={showSwapSuggestions}
				cellsByCode={cellsByCode}
				combinedBays={combinedBays}
				shifters={shifters}
				highlightCell={highlightCell}
				hasTemplate={hasTemplate}
				templateCells={templateCells}
				bay={bay}
				bayNumber={bayNumber}
				lowerDeck={false}
				byRow={byRow}
				setStatusMsg={setStatusMsg}
				inOutBound={inOutBound}
				polFilter={polFilter}
				betterHighlightForPolOnly={betterHighlightForPolOnly}
				blockedCells={blockedCells}
				containerDiggingMap={containerDiggingMap}
				editingMode ={editingMode}/>
		</div>
		<div className="bay-lower-deck">
			<Rows
				colorMode={colorMode}
				voyageData={voyageData}
				showSwapSuggestions={showSwapSuggestions}
				cellsByCode={cellsByCode}
				combinedBays={combinedBays}
				shifters={shifters}
				highlightCell={highlightCell}
				hasTemplate={hasTemplate}
				templateCells={templateCells}
				bay={bay}
				bayNumber={bayNumber}
				lowerDeck={true}
				byRow={byRow}
				setStatusMsg={setStatusMsg}
				inOutBound={inOutBound}
				polFilter={polFilter}
				betterHighlightForPolOnly={betterHighlightForPolOnly}
				blockedCells={blockedCells}
				containerDiggingMap={containerDiggingMap}
				editingMode ={editingMode} />
		</div>
	</div>;
}

function Rows({ containerDiggingMap, colorMode, voyageData, showSwapSuggestions, cellsByCode, combinedBays, shifters = new Set(), highlightCell, inOutBound, hasTemplate, templateCells, bay, bayNumber, lowerDeck = false, byRow, setStatusMsg, polFilter, betterHighlightForPolOnly, blockedCells, editingMode }) {
	return <>
		{generateRows(hasTemplate, bay, editingMode).map((row, rIdx) => {
			const byTier = _.keyBy(byRow[row], 'tier');
			return <div key={row} className="row" style={{ display: 'flex', flexDirection: 'column-reverse' }}>
				{(lowerDeck ? generateLowerTiers(hasTemplate, bay, false) : generateUpperTiers(hasTemplate, bay, false)).map((tier, tIdx) => <Cell
					cell={byTier[tier]}
					bayNumber={bayNumber}
					row={row}
					tier={tier}
					hasTemplate={hasTemplate}
					templateCells={templateCells}
					highlightCell={highlightCell}
					shifters={shifters}
					polFilter={polFilter}
					betterHighlightForPolOnly={betterHighlightForPolOnly}
					inOutBound={inOutBound}
					editingMode={editingMode}
					cellsByCode={cellsByCode}
					blockedCells={blockedCells}
					combinedBays={combinedBays}
					showSwapSuggestions={showSwapSuggestions}
					voyageData={voyageData}
					colorMode={colorMode}
					containerDiggingMap={containerDiggingMap} />)}
			</div>;
		})}
	</>;
}

function Cell({ cell, bayNumber, row, tier, hasTemplate, templateCells, highlightCell, shifters, polFilter, betterHighlightForPolOnly, inOutBound, editingMode, cellsByCode, combinedBays, blockedCells, showSwapSuggestions, voyageData, colorMode, containerDiggingMap }) {
	const container = cell?.container;
	const showCell = container != null || !hasTemplate || templateCells[toCellCode(bayNumber, row, tier)] != null;

	const unloToHue = useContext(PortColorFunction);
	const highlightCellObj = getHighlightedCell(cellsByCode, highlightCell, combinedBays);
	const exclusionSet = useExclusionSetForCell(cell);
	const exclusionSetHighlightCell = useExclusionSetForCell(highlightCellObj);

	if (!showCell) {
		return <div key={tier} className="cell" style={{ opacity: 0 }} />;
	}

	const highlighted = highlightCell == toCellCode(bayNumber, row, tier) || (combinedBays && highlightCell == toCellCode(bayNumber + 1, row, tier));
	const isFaded = !shifters.has(prepContainerNumber(container?.equipmentIdentifier || '')) && !(polFilter == null || (inOutBound == 'INBOUND' ? container?.portOfDischarge : container?.portOfLoading) == polFilter);
	const fortyGhost = cell != null && bayNumber > cell.bay && editingMode == null;
	let statusMsg = ('' + bayNumber).padStart(2, '0') + ('' + row).padStart(2, '0') + ('' + tier).padStart(2, '0');
	if (container) {
		statusMsg += ' - ' + (container.equipmentIdentifier || 'Unknown container');
		statusMsg += ' - ' + (container.isoCode);
		statusMsg += ' - ' + (container.portOfLoading);
		statusMsg += ' - ' + (container.portOfDischarge);
	} else {
		statusMsg += ' - Not assigned';
	}

	const { score: highlightScore } = containersCanBeSwapped(highlightCellObj?.container, container);

	return <div
		key={tier}
		className={"cell" + (fortyGhost ? ' cell-forty-ghost' : (isFaded && container && betterHighlightForPolOnly) ? ' cell-faded-ghost' : '') + (highlighted ? ' cell-highlighted' : '')}
		title={statusMsg}>
		{blockedCells != null && blockedCells.has(toCellCode(bayNumber, row, tier)) && <div className="cell-blocked-mini" />}
		{showSwapSuggestions && container != null && highlightCell != null && <div style={{ position: 'absolute', inset: 0, background: 'rgba(0, 0, 0, ' + ((1 - highlightScore) * 0.5) + ')' }} />}
		{showSwapSuggestions && (containerDiggingMap[highlightCellObj?.container?.equipmentIdentifier]?.blocking ?? []).includes(container?.equipmentIdentifier) && <div style={{ position: 'absolute', inset: 0, border: exclusionSetHighlightCell.has(container?.equipmentIdentifier) ? '3px solid rgba(0, 100, 150, 0.8)' : '3px solid rgba(200, 20, 20, 0.7)' }} />}
		<div className="cell-content" style={{ width: '100%', height: '100%', backgroundPosition: 'center', background: editingMode != null ? '' : calculateStylesForContainer(container, inOutBound, voyageData, colorMode, isFaded, unloToHue, containerDiggingMap, exclusionSet, true) }}>
			{container && <>
				{container.dangerousGoods.length > 0 && <div className="dgs-indicator" />}
			</>}
		</div>
	</div>;
}

export function calculateStylesForContainer(container, inOutBound, voyageData, colorMode, isFaded, unloToHue, containerDiggingMap, diggingExclusionSet, darken = false) {
	const lightness = darken ? 0.64 : DEFAULT_PORT_LIGHTNESS;
	// const lightness = DEFAULT_PORT_LIGHTNESS;
	// const lightness = 0.64;
	if (isFaded && container) return 'var(--col-grey-200)';

	if (container) {
		if (colorMode == 'SPECIALS') {
			if (container.reeferTemperature != null) return generateColorForHue(210, lightness - 0.1);
			if (container.isOog) return generateColorForHue(25, lightness);
			if (container.dangerousGoods.length > 0) return generateColorForHue(0, lightness);
			if (container) return 'var(--col-grey-400)';
		} else if (colorMode == 'OPERATIONAL_STATUS') {
			if (inOutBound == 'OUTBOUND' && container) {
				const containerVoyageData = (voyageData?.outbound ?? {})[prepContainerNumber(container?.equipmentIdentifier)];
				if (containerVoyageData?.yardLocation == null && (containerVoyageData?.state == 'T' || containerVoyageData?.state == 'V')) {
					return 'repeating-linear-gradient(-45deg, rgba(255, 255, 255, 1) 0px, rgba(255, 255, 255, 1) 5px, rgba(0, 0, 0, 0.35) 6px, rgba(0, 0, 0, 0) 6px, rgba(0, 0, 0, 0) 12px) ' + generateColorForHue(unloToHue(container?.portOfDischarge), lightness);
				} else {
					return generateColorForHue(unloToHue(container?.portOfDischarge), lightness);
				}
			} else if (inOutBound == 'INBOUND' && container) {
				const containerVoyageData = (voyageData?.inbound ?? {})[prepContainerNumber(container?.equipmentIdentifier)];
				if (containerVoyageData?.state == 'T' || containerVoyageData?.state == 'V') {
					return 'repeating-linear-gradient(-45deg, rgba(255, 255, 255, 1) 0px, rgba(255, 255, 255, 1) 5px, rgba(0, 0, 0, 0.35) 6px, rgba(0, 0, 0, 0) 6px, rgba(0, 0, 0, 0) 12px) ' + generateColorForHue(unloToHue(container?.portOfDischarge), lightness);
				} else {
					return generateColorForHue(unloToHue(container?.portOfDischarge), lightness);
				}
			}
		} else if (colorMode == 'DIGGING') {
			return calcDiggingColor(container, containerDiggingMap, diggingExclusionSet);
		} else {
			return generateColorForHue(unloToHue(container?.portOfDischarge), lightness);
		}
	}

	return ''; // empty color if no container in cell
}

export function loadingComplete(voyageData, equipmentIdentifier) {
	const containerVoyageData = (voyageData?.outbound ?? {})[prepContainerNumber(equipmentIdentifier)];
	return containerVoyageData?.yardLocation == null && (containerVoyageData?.state == 'T' || containerVoyageData?.state == 'V');
}

export function dischargeComplete(voyageData, equipmentIdentifier) {
	const containerVoyageData = (voyageData?.outbound ?? {})[prepContainerNumber(equipmentIdentifier)];
	return containerVoyageData?.state == 'T' || containerVoyageData?.state == 'V';
}

export function calcDiggingColor(container, containerDiggingMap, diggingExclusionSet, lightness = 0.70) {
	const loading = containerDiggingMap == null || Object.keys(containerDiggingMap).length == 0;
	const digging = containerDiggingMap?.[container?.equipmentIdentifier];

	if (loading) {
		return 'var(--col-grey-400)';
		// return 'repeating-linear-gradient(0deg, hsl(0deg, 50%, 100%) 0px, hsl(60deg, 50%, 100%) 3px, hsl(120deg, 50%, 100%) 6px, hsl(180deg, 50%, 100%) 9px, hsl(240deg, 50%, 100%) 12px, hsl(300deg, 50%, 100%) 16px, hsl(360deg, 50%, 100%) 19px)';
	} else if (digging == null) {
		return generateColorForHue(200, 0.9);
	} else {
		const maxRange = 8; // Everything after 8 moves is just red
		const range = Math.min(maxRange, calcNumberBlockingContainers(container, containerDiggingMap, diggingExclusionSet));

		// Green to red hue depending on moves, 0 = green, maxRange = red, sqrt to make difference between green and yellow (0 vs 1 move) way more pronounced than between orange and red (7 / 8 moves)
		const hueDeg = 120 - Math.min(Math.sqrt(range) * (120 / Math.sqrt(maxRange)), 120);
		return generateColorForHue(hueDeg, lightness);
	}
}

export function calcNumberBlockingContainers(container, containerDiggingMap, diggingExclusionSet) {
	const digging = containerDiggingMap?.[container?.equipmentIdentifier];
	const adjustedBlocking = (digging?.blocking ?? []).filter(c => diggingExclusionSet == null || !diggingExclusionSet.has(c.trim()));

	return (adjustedBlocking.length ?? 0);
}