import React, { useContext, useEffect, useState } from 'react';
import { gql, useQuery } from '@apollo/client';
import Breadcrumbs from '../lib/Breadcrumbs';
import { generateColorForHue } from '../../util/colors';
import PortColorFunction from '../../context/PortColorFunction';
import { InputField, LinkButton, LoadingOverlay, Pagination } from '@atrocit/scl';

const PAGE_SIZE = 25;

export default function PortOverview() {
	const [ page, setPage ] = useState(1);
	const [ field, setField ] = useState('name');
	const [ direction, setDirection ] = useState('ASC');
	const [ query, setQuery ] = useState('');

	// Reset pagination on search/sort
	useEffect(() => { setPage(1); }, [ query, field, direction ]);

	const portsQuery = useQuery(gql`query Query($query: String!, $offset: Int!, $limit: Int!, $field: String!, $direction: SortDirection!) {
		ports(query: $query, offset: $offset, limit: $limit, field: $field, direction: $direction) {
			totalElements,
			content {
				id, name, unlo, hue
			}
		}
	}`, { variables: { query, offset: (page - 1) * PAGE_SIZE, limit: PAGE_SIZE, field, direction } });
	const ports = portsQuery?.data?.ports?.content ?? [];
	const totalElements = portsQuery?.data?.ports?.totalElements;

	return <div className="app-ui">
		{portsQuery.loading && <LoadingOverlay />}

		<Breadcrumbs
			segments={[
				{ link: '/ports', label: 'Havens' },
				{ link: '/ports', label: 'Overzicht' },
			]} />

		<div style={{ display: 'flex', justifyContent: 'flex-end' }}>
			<LinkButton to="/ports/create"><span className="fa fa-plus" />&nbsp; Nieuwe haven</LinkButton>
		</div>
		<br />

		<div>
			<InputField onChange={setQuery} value={query} placeholder="Zoeken..." />
		</div>
		<br />

		<table className="table">
			<colgroup>
				<col width="*" />
				<col width="120" />
				<col width="90" />
			</colgroup>
			<thead>
				<tr>
					<th>Havennaam</th>
					<th>UNLO-code</th>
					<th></th>
				</tr>
			</thead>
			<tbody>
				{ports.map(p => <tr key={p.id}>
					<td>{p.name}</td>
					<td style={{ background: generateColorForHue(p.hue, 0.78) }}>{p.unlo}</td>
					<td>
						<LinkButton to={'/ports/' + p.id}>Bewerken</LinkButton>
					</td>
				</tr>)}
				{ports.length == 0 && !portsQuery.loading && <tr><td colSpan={99}>Er zijn nog geen havens aangemaakt</td></tr>}
			</tbody>
		</table>

		<div style={{ display: 'flex', justifyContent: 'flex-end', paddingTop: 'var(--u-16)', gap: 'var(--u-24)', alignItems: 'center' }}>
			<div>
				<span>Havens {(page - 1) * PAGE_SIZE + 1} t/m {Math.min((page * PAGE_SIZE), totalElements)} getoond, van {totalElements} totaal</span>
			</div>
			<Pagination page={page} setPage={setPage} pageSize={PAGE_SIZE} total={totalElements} />
		</div>

	</div>;
}