import React, { useContext, useMemo } from 'react';
import { DEFAULT_PORT_LIGHTNESS, generateColorForHue, strHash } from '../../../util/colors';
import PortColorFunction from '../../../context/PortColorFunction';

export default function ColorsLegend({ ports, colorMode, inOutBound }) {
	const unloToHue = useContext(PortColorFunction);

	const sortedPorts = useMemo(() => {
		return [ ...ports ].sort().filter(p => p != null && p.length > 0);
	}, [ ports ]);

	if (colorMode == 'SPECIALS') {
		return <div className="ports-legend">
			<div className="ports-legend-item" style={{ background: generateColorForHue(210, DEFAULT_PORT_LIGHTNESS - 0.1) }}>Reefer</div>
			<div className="ports-legend-item" style={{ background: generateColorForHue(25, DEFAULT_PORT_LIGHTNESS) }}>Oversize</div>
			<div className="ports-legend-item" style={{ background: generateColorForHue(0, DEFAULT_PORT_LIGHTNESS) }}>Dangerous</div>
			<div className="ports-legend-item" style={{ background: 'var(--col-grey-400)' }}>Other</div>
		</div>;
	} else {
		return <div className="ports-legend">
			{sortedPorts.map(port => <div key={port} className="ports-legend-item" style={{ background: generateColorForHue(unloToHue(port), DEFAULT_PORT_LIGHTNESS) }}>
				{port}
			</div>)}
		</div>;
	}
}