import { Button, ButtonArea, ButtonGroup, Form, IlluminatedButton, InputField, WithLabel } from '@atrocit/scl';
import { useState } from 'react';
import { gql, useMutation } from '@apollo/client';

export default function CreateOperationalPlan({ stowagePlan, onRefresh }) {
	const [ shipPosition, setShipPosition ] = useState('');
	const [ estimatedTimePerMoveDischarging, setEstimatedTimePerMoveDischarging ] = useState('120');
	const [ estimatedTimePerMoveLoading, setEstimatedTimePerMoveLoading ] = useState('150');

	const [ createOperationalPlan ] = useMutation(gql`mutation Mutation($stowagePlanId: Int!, $shipPosition: ShipPosition!, $estimatedTimePerMoveDischarging: Int!, $estimatedTimePerMoveLoading: Int!) {
		createOperationalPlan(stowagePlanId: $stowagePlanId, shipPosition: $shipPosition, estimatedTimePerMoveDischarging: $estimatedTimePerMoveDischarging, estimatedTimePerMoveLoading: $estimatedTimePerMoveLoading) { id }
	}`);

	return <Form onSubmit={() => {
		createOperationalPlan({
			variables: {
				stowagePlanId: stowagePlan.id,
				shipPosition: shipPosition,
				estimatedTimePerMoveDischarging: parseInt(estimatedTimePerMoveDischarging, 10),
				estimatedTimePerMoveLoading: parseInt(estimatedTimePerMoveLoading, 10),
			},
		}).then(onRefresh);
	}}>
		<h1>Operationeel plan maken</h1>
		<br />

		<WithLabel label="Positie schip">
			<ButtonGroup>
				<IlluminatedButton onClick={() => setShipPosition('PORT_SIDE_AT_QUAY')} active={shipPosition == 'PORT_SIDE_AT_QUAY'}>Bakboord tegen kade</IlluminatedButton>
				<IlluminatedButton onClick={() => setShipPosition('STARBOARD_AT_QUAY')} active={shipPosition == 'STARBOARD_AT_QUAY'}>Stuurboord tegen kade</IlluminatedButton>
			</ButtonGroup>
		</WithLabel>

		<WithLabel label="Tijd per losmove (in seconden)">
			<InputField onChange={setEstimatedTimePerMoveDischarging} value={estimatedTimePerMoveDischarging} />
		</WithLabel>

		<WithLabel label="Tijd per laadmove (in seconden)">
			<InputField onChange={setEstimatedTimePerMoveLoading} value={estimatedTimePerMoveLoading} />
		</WithLabel>

		<ButtonArea>
			<Button level="primary" type="submit"><span className="fa fa-plus" />&nbsp; Operationeel plan maken</Button>
		</ButtonArea>
	</Form>;
}