import { Button, ButtonArea, DateField, Form, InputField, SelectField, Toggle, WithLabel } from '@atrocit/scl';
import React, { useRef, useState } from 'react';
import ReactToPrint from 'react-to-print';
import { DateTime } from 'luxon';
import atoLogo from './ato_edit.png';
import { gql, useLazyQuery, useQuery } from '@apollo/client';
import FileDropInput from '../lib/forms/FileDropInput';

const damageCodes = [
	{ code: '00', name: 'Top rail damaged', category: 'FRONT' },
	{ code: '01', name: 'Bottom rail damaged', category: 'FRONT' },
	{ code: '02', name: 'Hole', category: 'FRONT' },
	{ code: '03', name: 'Volume variation', category: 'FRONT' },
	{ code: '07', name: 'Tear', category: 'FRONT' },
	{ code: '08', name: 'Scratched/Dented', category: 'FRONT' },
	{ code: '10', name: 'Top rail damaged', category: 'LEFT' },
	{ code: '11', name: 'Bottom rail damaged', category: 'LEFT' },
	{ code: '12', name: 'Hole', category: 'LEFT' },
	{ code: '13', name: 'Volume variation', category: 'LEFT' },
	{ code: '14', name: 'Corner post damaged', category: 'LEFT' },
	{ code: '17', name: 'Tear', category: 'LEFT' },
	{ code: '18', name: 'Scratched/Dented', category: 'LEFT' },
	{ code: '20', name: 'Top rail damaged', category: 'DOORS' },
	{ code: '21', name: 'Bottom rail damaged', category: 'DOORS' },
	{ code: '22', name: 'Hole', category: 'DOORS' },
	{ code: '23', name: 'Doors damaged', category: 'DOORS' },
	{ code: '24', name: 'Doors not completely closed', category: 'DOORS' },
	{ code: '27', name: 'Tear', category: 'DOORS' },
	{ code: '28', name: 'Scratched/Dented', category: 'DOORS' },
	{ code: '30', name: 'Top rail damaged', category: 'RIGHT' },
	{ code: '31', name: 'Bottom rail damaged', category: 'RIGHT' },
	{ code: '32', name: 'Hole', category: 'RIGHT' },
	{ code: '33', name: 'Volume variation', category: 'RIGHT' },
	{ code: '34', name: 'Corner post damaged', category: 'RIGHT' },
	{ code: '37', name: 'Tear', category: 'RIGHT' },
	{ code: '38', name: 'Scratched/Dented', category: 'RIGHT' },
	{ code: '40', name: 'Floor damaged', category: 'FLOOR' },
	{ code: '41', name: 'Forkholes damaged', category: 'FLOOR' },
	{ code: '50', name: 'Tarpaulin damaged', category: 'ROOF' },
	{ code: '51', name: 'Tarpaulin missing', category: 'ROOF' },
	{ code: '52', name: 'Hole', category: 'ROOF' },
	{ code: '53', name: 'Tarpaulin not secured', category: 'ROOF' },
	{ code: '54', name: 'Volume variation', category: 'ROOF' },
	{ code: '55', name: 'Seal line damaged', category: 'ROOF' },
	{ code: '56', name: 'Seal line missing', category: 'ROOF' },
	{ code: '57', name: 'Tear', category: 'ROOF' },
	{ code: '58', name: 'Scratched/Dented', category: 'ROOF' },
	{ code: '60', name: 'Dirty/Smells', category: 'INSIDE' },
	{ code: '61', name: 'Floor wet', category: 'INSIDE' },
	{ code: '63', name: 'Sidepanels missing/Damaged', category: 'INSIDE' },
	{ code: '70', name: 'Outside panel missing/Damaged', category: 'TANK' },
	{ code: '71', name: 'Tank frame damaged', category: 'TANK' },
	{ code: '72', name: 'Bottom and/or top rail not present', category: 'TANK' },
	{ code: '73', name: 'Ladder behind damaged', category: 'TANK' },
	{ code: '74', name: 'Footbridge damaged', category: 'TANK' },
	{ code: '80', name: 'Cable missing', category: 'REEFER' },
	{ code: '81', name: 'Cable damaged', category: 'REEFER' },
	{ code: '82', name: 'Conair holes open', category: 'REEFER' },
	{ code: '83', name: 'Conair holes damaged', category: 'REEFER' },
	{ code: '84', name: 'Not functioning engine', category: 'REEFER' },
	{ code: '85', name: 'Temperature not recordable', category: 'REEFER' },
	{ code: '90', name: 'Labels missing', category: 'GENERAL' },
	{ code: '91', name: 'Old labels not removed', category: 'GENERAL' },
	{ code: '92', name: 'Not sealed at arrival', category: 'GENERAL' },
	{ code: '93', name: 'Container leaks', category: 'GENERAL' },
	{ code: '94', name: 'Dented in different places', category: 'GENERAL' },
	{ code: '95', name: 'General damages', category: 'GENERAL' },
	{ code: '96', name: 'Gassed', category: 'GENERAL' },
	{ code: '97', name: 'Non-ISO', category: 'GENERAL' },
	{ code: '99', name: 'Out-of-service', category: 'GENERAL' },
];

export default function CreateDamageReport() {
	const [ printMode, setPrintMode ] = useState({ render: false, selectedOnly: false, overviewOnly: false });

	const [ containerNr, setContainerNr ] = useState('');
	const [ billOfLading, setBillOfLading ] = useState('');
	const [ vessel, setVessel ] = useState('');
	const [ voyNr, setVoyNr ] = useState('');
	const [ reportDateTime, setReportDateTime ] = useState(DateTime.now().startOf('day'));
	const [ port, setPort ] = useState('BEANR');
	const [ sealNr, setSealNr ] = useState('');
	const [ pol, setPol ] = useState('');
	const [ pod, setPod ] = useState('');
	const [ isoCode, setIsoCode ] = useState('');
	const [ isFull, setIsFull ] = useState(false);
	const [ material, setMaterial ] = useState('STEEL');
	const [ cargo, setCargo ] = useState('');

	const [ applicableDamages, setApplicableDamages ] = useState(new Set());
	const [ damageCodeToAdd, setDamageCodeToAdd ] = useState(null);
	const [ remarks, setRemarks ] = useState('');

	const [ images, setImages ] = useState([]);

	const componentToPrint = useRef(null);

	const [ getContainerDamageStatus, containerDamageStatusQuery ] = useLazyQuery(gql`
		query Query($equipmentIdentifier: String!) {
			containerDamageStatus(equipmentIdentifier: $equipmentIdentifier) {
				equipmentIdentifier
				isoCode
				billOfLading
				damageCodes
			}
		}
	`);

	return <div className="page page-fw">
		<div style={{ display: 'grid', gridTemplateColumns: 'auto 210mm', gap: 'var(--u-32)', maxHeight: '95vh', height: '100%', alignItems: 'flex-start', margin: '0 auto', justifyContent: 'center' }}>
			<div style={{ display: 'flex', justifyContent: 'flex-end', overflow: 'auto', minHeight: '100%', height: '100%', maxHeight: '100%', width: 'var(--u-512)' }}>
				<div className="dmg-rep-form" style={{ width: 'var(--u-512)', padding: 'var(--u-4)' }}>
					<Form
						onSubmit={() => {
						}}>
						<WithLabel label="Containernummer" isFake={true}>
							<div style={{ display: 'grid', gridTemplateColumns: '1fr max-content', gap: 'var(--u-16)' }}>
								<InputField onChange={setContainerNr} value={containerNr} />

								<Button
									onClick={() => {
										getContainerDamageStatus({ variables: { equipmentIdentifier: containerNr.trim() } })
											.then(({ data }) => {
												if (data.containerDamageStatus) {
													setIsoCode(data.containerDamageStatus.isoCode);
													setBillOfLading(data.containerDamageStatus.billOfLading);
													setApplicableDamages(new Set(data.containerDamageStatus.damageCodes));
												}
											});
									}}
									disabled={containerDamageStatusQuery.loading}>
									<span className={containerDamageStatusQuery.loading ? "fa fa-pulse fa-spinner" : "fa fa-search"} />&nbsp; Aanvullen uit Interman
								</Button>
							</div>
						</WithLabel>

						<br />
						<WithLabel label="Schades" isFake={true}>
							<table className="table table-fw" style={{ marginBottom: 'var(--u-12)' }}>
								<colgroup>
									<col width="20" />
									<col width="60%" />
									<col width="30%" />
									<col width="1" />
								</colgroup>
								<thead>
									<tr>
										<th>Code</th>
										<th>Description</th>
										<th>Category</th>
										<th />
									</tr>
								</thead>
								<tbody>
									{[ ...applicableDamages ]
										.toSorted((a, b) => (a.code < b.code ? -1 : 1))
										.map(code => damageCodes.find(damageCode => damageCode.code == code))
										.map(damage => {
											return <tr key={damage.code}>
												<td>{damage.code}</td>
												<td>{damage.name}</td>
												<td>{damage.category}</td>
												<td>
													<Button onClick={() => setApplicableDamages(ad => new Set([ ...ad ].filter(d => d != damage.code)))}>
														<span className="fa fa-trash" />
													</Button>
												</td>
											</tr>;
										})}
									{applicableDamages.size == 0 && <tr>
										<td colSpan={4}>Geen schadecodes geselcteerd</td>
									</tr>}
								</tbody>
							</table>

							<div style={{ display: 'grid', gridTemplateColumns: '1fr auto', gap: 'var(--u-8)' }}>
								<SelectField
									onChange={code => setDamageCodeToAdd(code)}
									value={damageCodeToAdd}
									options={damageCodes.map(damageCode => ({ value: damageCode.code, label: damageCode.code + ' - ' + damageCode.name + ' - ' + damageCode.category }))}
									placeholder="Selecteer code" />
								<Button
									disabled={damageCodeToAdd == null || applicableDamages.has(damageCodeToAdd)}
									level="primary"
									onClick={() => {
										setDamageCodeToAdd(null);
										setApplicableDamages(ad => new Set([ ...ad, damageCodeToAdd ]));
									}}>
									<span className="fa fa-plus" />
								</Button>
							</div>
						</WithLabel>
						<br />


						<WithLabel label="B/L">
							<InputField onChange={setBillOfLading} value={billOfLading} />
						</WithLabel>
						<WithLabel label="Schip">
							<InputField onChange={setVessel} value={vessel} />
						</WithLabel>
						<WithLabel label="Reisnummer">
							<InputField onChange={setVoyNr} value={voyNr} />
						</WithLabel>

						<WithLabel label="Datum rapport">
							<DateField onChange={setReportDateTime} value={reportDateTime} />
						</WithLabel>
						<WithLabel label="Haven">
							<InputField onChange={setPort} value={port} />
						</WithLabel>
						<WithLabel label="Zegelnummer">
							<InputField onChange={setSealNr} value={sealNr} />
						</WithLabel>
						<WithLabel label="Laadhaven (PoL)">
							<InputField onChange={setPol} value={pol} />
						</WithLabel>
						<WithLabel label="Loshaven (PoD)">
							<InputField onChange={setPod} value={pod} />
						</WithLabel>

						<WithLabel label="ISO-code">
							<InputField onChange={setIsoCode} value={isoCode} />
						</WithLabel>
						<WithLabel label="Volle container">
							<Toggle onChange={f => setIsFull(f)} value={isFull} />
						</WithLabel>
						<WithLabel label="Materiaal">
							<InputField onChange={setMaterial} value={material} />
						</WithLabel>

						<WithLabel label="Lading">
							<InputField onChange={setCargo} value={cargo} />
						</WithLabel>

						<br /><br />
						<WithLabel label="Opmerkingen">
							<textarea className="scl-form-element" onChange={e => setRemarks(e.target.value)} value={remarks} style={{ resize: 'vertical', minHeight: '3em' }} />
						</WithLabel>

						<br /><br />
						<WithLabel label="Foto's">
							<input
								type="file"
								multiple
								accept="image/*"
								onChange={async e => {
									const dataUrls = [];
									for (const file of e.target.files) {
										dataUrls.push(await new Promise((resolve, reject) => {
											const reader = new FileReader();
											reader.onload = () => {
												resolve(reader.result);
											};
											reader.readAsDataURL(file);
											reader.onerror = reject;
										}));
									}
									setImages(dataUrls);
								}} />
						</WithLabel>

						<ButtonArea>
							<ReactToPrint
								trigger={() => <Button level="primary"><span className="fa fa-print" />&nbsp; Printen</Button>}
								content={() => componentToPrint?.current} />
						</ButtonArea>
					</Form>
				</div>
			</div>
			<div style={{ overflow: 'auto', height: '100%', maxHeight: '100%' }}>
				<div className="dmg-rep-preview" style={{ border: '1px solid var(--col-grey-300)', minHeight: '297mm' }}>
					<div className="dmg-rep-preview-content" ref={componentToPrint}>
						<style>
							{`
							@page {
						      size: A4;
						      padding: 15mm 20mm;
						    }
							`}
						</style>
						<div className="print-preview-page">
							<div style={{ display: 'grid', gridTemplateColumns: '1fr', fontSize: '14pt', paddingTop: 'var(--u-12)', paddingBottom: '8px' }}>
								<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
									<b>CONTAINER INSPECTION REPORT</b>
								</div>
							</div>
							<div style={{ display: 'grid', gridTemplateColumns: '1fr 2fr', fontSize: '12pt' }}>

								<div style={{ padding: 'var(--u-4)', border: '.5mm solid var(--col-grey-900)', borderRight: 0, borderBottom: 0, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
									<img src={atoLogo} style={{ width: '3cm', height: 'auto' }} />
								</div>
								<div style={{ padding: 'var(--u-4)', border: '.5mm solid var(--col-grey-900)', borderBottom: 0 }}>
									<b>Container:</b><br />
									<i>{containerNr}</i>
								</div>
							</div>
							<div style={{ fontSize: '12pt', border: '.5mm solid var(--col-grey-900)', borderBottom: 0, padding: 'var(--u-4)' }}>
								<b>B/L: </b><i>{billOfLading}</i>
							</div>
							<div style={{ display: 'grid', gridTemplateColumns: '2fr 1fr', fontSize: '12pt' }}>
								<div style={{ fontSize: '12pt', border: '.5mm solid var(--col-grey-900)', borderBottom: 0, borderRight: 0, padding: 'var(--u-4)' }}>
									<b>Vessel: </b><i>{vessel}</i>
								</div>
								<div style={{ padding: 'var(--u-4)', border: '.5mm solid var(--col-grey-900)', borderBottom: 0 }}>
									<b>Voyage: </b><i>{voyNr}</i>
								</div>
							</div>
							<div style={{ display: 'grid', gridTemplateColumns: '2fr 1fr', fontSize: '12pt' }}>
								<div style={{ fontSize: '12pt', border: '.5mm solid var(--col-grey-900)', borderBottom: 0, borderRight: 0, padding: 'var(--u-4)' }}>
									<b>Report date: </b><i>{reportDateTime.toFormat('yyyy-MM-dd')}</i>
								</div>
								<div style={{ padding: 'var(--u-4)', border: '.5mm solid var(--col-grey-900)', borderBottom: 0 }}>
									<b>Port: </b><i>BEANR</i>
								</div>
							</div>
							<div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr', fontSize: '12pt' }}>
								<div style={{ fontSize: '12pt', border: '.5mm solid var(--col-grey-900)', borderRight: 0, padding: 'var(--u-4)' }}>
									<b>Seal: </b><i>{sealNr}</i>
								</div>
								<div style={{ padding: 'var(--u-4)', border: '.5mm solid var(--col-grey-900)', borderRight: 0 }}>
									<b>PoL: </b><i>{pol}</i>
								</div>
								<div style={{ padding: 'var(--u-4)', border: '.5mm solid var(--col-grey-900)' }}>
									<b>PoD: </b><i>{pod}</i>
								</div>
							</div>

							<div style={{ display: 'grid', gridTemplateColumns: '1fr', fontSize: '12pt', paddingTop: 'var(--u-12)' }}>
								<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
									<b>CONTAINER & DAMAGE DESCRIPTION</b>
								</div>
							</div>
							<div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr', fontSize: '12pt' }}>
								<div style={{ fontSize: '12pt', border: '.5mm solid var(--col-grey-900)', borderRight: 0, borderBottom: 0, padding: 'var(--u-4)' }}>
									<b>ISO code: </b><i>{isoCode}</i>
								</div>
								<div style={{ fontSize: '12pt', border: '.5mm solid var(--col-grey-900)', borderRight: 0, borderBottom: 0, padding: 'var(--u-4)' }}>
									<b>Status: </b><i>{isFull ? 'FULL' : 'EMPTY'}</i>
								</div>
								<div style={{ fontSize: '12pt', border: '.5mm solid var(--col-grey-900)', borderBottom: 0, padding: 'var(--u-4)' }}>
									<b>Material: </b><i>{material}</i>
								</div>
							</div>
							<div style={{ display: 'grid', gridTemplateColumns: '1fr', fontSize: '12pt' }}>
								<div style={{ fontSize: '12pt', border: '.5mm solid var(--col-grey-900)', borderBottom: 0, padding: 'var(--u-4)' }}>
									<b>Cargo: </b><i>{cargo}</i>
								</div>
							</div>
							<div style={{ display: 'grid', gridTemplateColumns: '1fr' }}>
								<div style={{ fontSize: '10pt', border: '.5mm solid var(--col-grey-900)', borderBottom: 0, padding: 'var(--u-4)' }}>
									<b>Damages</b><br />

									<table style={{ borderCollapse: 'collapse', width: '100%' }}>
										<colgroup>
											<col width="40" />
											<col width="*" />
											<col width="150" />
										</colgroup>
										<tbody>
											{[ ...applicableDamages ]
												.toSorted((a, b) => (a.code < b.code ? -1 : 1))
												.map(code => damageCodes.find(damageCode => damageCode.code == code))
												.map(damage => {
													return <tr key={damage.code}>
														<td style={{ borderBottom: '1px solid var(--col-grey-200)' }}>{damage.code}</td>
														<td style={{ borderBottom: '1px solid var(--col-grey-200)' }}>{damage.name}</td>
														<td style={{ borderBottom: '1px solid var(--col-grey-200)', paddingLeft: 'var(--u-8)' }}>{damage.category}</td>
													</tr>;
												})}
										</tbody>
									</table>
								</div>
								{/* <div style={{ fontSize: '12pt', border: '.5mm solid var(--col-grey-900)', borderBottom: 0, padding: 'var(--u-4)' }}>*/}
								{/*	<img src={containerDmgPreview} alt="Container" style={{ width: '70mm', height: 'auto' }} />*/}
								{/* </div>*/}
							</div>
							<div style={{ display: 'grid', gridTemplateColumns: '1fr' }}>
								<div style={{ fontSize: '12pt', border: '.5mm solid var(--col-grey-900)', padding: 'var(--u-4)' }}>
									<b>Remarks:</b>
									<div style={{ borderBottom: '0.3mm solid var(--col-grey-800)', paddingTop: '1.5em' }}>
										{remarks}
									</div>
								</div>
							</div>

							<div style={{ display: 'grid', gridTemplateColumns: '1fr', fontSize: '12pt', paddingTop: 'var(--u-12)' }}>
								<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
									<b>SIGNATURES</b>
								</div>
							</div>
							<div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr 1fr', fontSize: '12pt' }}>
								<div style={{ fontSize: '12pt', border: '.5mm solid var(--col-grey-900)', borderRight: 0, padding: 'var(--u-4)' }}>
									<b>Vessel:</b>
									<br />
									<br />
									<br />
									<div style={{ borderBottom: '0.3mm solid var(--col-grey-800)' }} />
								</div>
								<div style={{ fontSize: '12pt', border: '.5mm solid var(--col-grey-900)', borderRight: 0, padding: 'var(--u-4)' }}>
									<b>Terminal:</b>
									<br />
									<br />
									<br />
									<div style={{ borderBottom: '0.3mm solid var(--col-grey-800)' }} />
								</div>
								<div style={{ fontSize: '12pt', border: '.5mm solid var(--col-grey-900)', borderRight: 0, padding: 'var(--u-4)' }}>
									<b>Stevedores:</b>
									<br />
									<br />
									<br />
									<div style={{ borderBottom: '0.3mm solid var(--col-grey-800)' }} />
								</div>
								<div style={{ fontSize: '12pt', border: '.5mm solid var(--col-grey-900)', padding: 'var(--u-4)' }}>
									<b>Agents:</b>
									<br />
									<br />
									<br />
									<div style={{ borderBottom: '0.3mm solid var(--col-grey-800)' }} />
								</div>
							</div>
						</div>

						<div className="print-preview-page">
							<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '4mm' }}>
								{images.map((image, idx) => <div style={{ pageBreakInside: 'avoid', height: 'auto', display: 'grid', gridTemplateRows: 'auto 1fr', alignItems: 'center' }}>
									<div style={{ textAlign: 'center', textSize: '9pt', color: 'var(--col-grey-700)' }}>Photo #{idx + 1}</div>
									<div>
										<img key={idx} src={image} style={{ objectFit: 'contain', width: 'auto', height: 'auto', maxWidth: '100%', maxHeight: '220mm', border: '0.5mm solid var(--col-grey-700)', borderRadius: '1mm', pageBreakInside: 'avoid' }} alt="" />
									</div>
								</div>)}
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>;
}