import React from 'react';
import SideBarLink from './SideBarLink';

export default function SideBar() {
	return <nav className="scl-sidebar">
		<div className="scl-sidebar-content">
			<SideBarLink to="/dashboard" label="Dashboard" icon="home" />
			<SideBarLink to="/calls" label="Calls" icon="calendar-o" />
			<SideBarLink to="/shipTemplates" label="Scheepstemplates" icon="ship" />
			<SideBarLink to="/ports" label="Havens" icon="map-marker" />
			<SideBarLink to="/damageReports" label="Schaderaport" icon="frown-o" />
			<SideBarLink to="/about" label="Over" icon="info-circle" />
		</div>
		{/* <div className="login-info">*/}
		{/*	<span className="login-info-user">Rick Lubbers</span><br />*/}
		{/*	<span className="login-info-company">Atrocit</span><br /><br />*/}

		{/*	<Button onClick={() => {}}>Uitloggen</Button>*/}
		{/* </div>*/}
	</nav>;
}