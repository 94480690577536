import Breadcrumbs from '../lib/Breadcrumbs';
import ReactTooltip from "react-tooltip";
import React from "react";

export default function About() {
	return <div className="app-ui">
		<Breadcrumbs segments={[
			{ link: '/about', label: 'Info' },
			{ link: '/about', label: 'Over Atrocit StowIT' },
		]} />
		<div style={{ width: '100%', maxWidth: '40em' }}>
			<h1 style={{ color: 'var(--col-primary-500)' }}>Atrocit StowIT</h1>
			<p>Deze applicatie is ontwikkeld door <a data-tip data-for='link-to-the-website' href="http://atrocit.com">Atrocit Digital Tooling</a></p>
			<ReactTooltip id='link-to-the-website' place='bottom' effect='solid' >Volg de link naar de website</ReactTooltip>
		</div>
	</div>;
}