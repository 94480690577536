import Form from '../lib/forms/Form';
import { useState } from 'react';
import ValidatedButton from '../lib/forms/ValidatedButton';
import { requestMagicLink } from '../../util/api';
import LoadingOverlay from '../lib/ui/LoadingOverlay';
import { InputField, WithLabel } from '@atrocit/scl';
import ButtonArea from '../lib/forms/ButtonArea';

export default function Login() {
	const [ email, setEmail ] = useState('');
	const [ loading, setLoading ] = useState(false);
	const [ showMagicLinkMessage, setShowMagicLinkMessage ] = useState(false);

	if (loading) {
		return <LoadingOverlay />;
	} else if (showMagicLinkMessage) {
		return <div>Er is een e-mail naar het opgegeven adres gestuurd met een link. Door op de link te klikken ben je onmiddellijk ingelogd. Dit tabblad kan nu gesloten worden.</div>;
	} else {
		return <Form onSubmit={() => {
			setLoading(true);
			requestMagicLink(email)
				.then(() => setShowMagicLinkMessage(true))
				.finally(() => setLoading(false));
		}}>
			<WithLabel label="E-mail">
				<InputField type="email" onChange={setEmail} value={email}/>
			</WithLabel>

			<ButtonArea>
				<ValidatedButton
					validations={[
						{ valid: email.length >= 3, error: 'E-mailadres is te kort' },
						{ valid: email.includes('@'), error: 'E-mailadres moet @ bevatten' },
					]}
					type="submit">
					Volgende stap
				</ValidatedButton>
			</ButtonArea>
		</Form>;
	}
}