import { useState } from 'react';
import ButtonArea from '../../lib/forms/ButtonArea';
import { prepContainerNumber } from './CreateContainerModal';
import Modal from '../../lib/Modal';
import { BigMultiSelector, Button, Form } from '@atrocit/scl';

export default function CargoListExcelExport({ pagedRows, rows, plan, inOutBound, shifters }) {
	const [ open, setOpen ] = useState(false);
	const [ exportType, setExportType ] = useState('VIEW_ONLY');

	return <>
		<Modal isOpen={open} title="Exporteer als Excel" onClose={() => setOpen(false)}>
			<Form onSubmit={() => {
				const columns = [
					{ name: 'V/L', width: 2 },
					{ name: 'Containernummer', width: 15 },
					{ name: 'B/L', width: 20 },
					{ name: 'UNDG', width: 10 },
					{ name: 'POL', width: 10 },
					{ name: 'POD', width: 10 },
					{ name: 'Yard loc.', width: 10 },
					{ name: 'Type', width: 8 },
					{ name: 'VGM', width: 2 },
					{ name: 'Weight', width: 10 },
					{ name: 'Cell', width: 12 },
					{ name: 'Temp', width: 8 },
					{ name: 'OOG', width: 8 },
					{ name: 'OH', width: 8 },
					{ name: 'OB Links', width: 8 },
					{ name: 'OB Rechts', width: 8 },
					{ name: 'OL Voor', width: 8 },
					{ name: 'OL Achter', width: 8 },
				];

				let rowsToExport = [];
				if (exportType == 'VIEW_ONLY') {
					rowsToExport = pagedRows;
				} else if (exportType == 'QUERY_ONLY') {
					rowsToExport = rows;
				} else if (exportType == 'EVERYTHING_TO_HANDLE') {
					const cells = inOutBound == 'INBOUND' ? plan.inboundCells : plan.outboundCells;
					rowsToExport = cells.filter(c => c.container != null && (shifters.has(prepContainerNumber(c.container?.equipmentIdentifier || '')) || (inOutBound == 'INBOUND' ? c.container?.portOfDischarge == plan.portUnlo : c.container?.portOfLoading == plan.portUnlo)));
				} else { // everything otherwise
					rowsToExport = inOutBound == 'INBOUND' ? plan.inboundCells : plan.outboundCells;
				}

				const excelRows = rowsToExport.map(row => {
					return {
						[columns[0].name]: row.container?.isEmpty ? 'L' : 'V',
						[columns[1].name]: prepContainerNumber(row.container?.equipmentIdentifier || ''),
						[columns[2].name]: row.carrierReference,
						[columns[3].name]: row.container?.dangerousGoods.map(dg => dg.undgCode ?? dg.imoClass).join(', ') || '',
						[columns[4].name]: row.container?.portOfLoading,
						[columns[5].name]: row.container?.portOfDischarge,
						[columns[6].name]: row.container?.stackLocation,
						[columns[7].name]: row.container?.isoCode,
						[columns[8].name]: row.container?.isVgm ? 'Y' : '',
						[columns[9].name]: row.container?.grossWeight,
						[columns[10].name]: row.cellCode,
						[columns[11].name]: row.container?.isOog ? 'Y' : '',
						[columns[12].name]: row.container?.reeferTemperature,
						[columns[13].name]: row.container?.overHeight,
						[columns[14].name]: row.container?.overWidthLeft,
						[columns[15].name]: row.container?.overWidthRight,
						[columns[16].name]: row.container?.overLengthFront,
						[columns[17].name]: row.container?.overLengthBack,
					};
				});

				import('../../../util/exportToExcel.js').then(({ default: exportToExcel }) => exportToExcel(columns, excelRows));
			}}>
				<BigMultiSelector
					options={[
						{ id: 'VIEW_ONLY', title: 'Alleen deze pagina', description: 'Exporteer alleen huidige pagina naar excel' },
						{ id: 'QUERY_ONLY', title: 'Deze zoekopdracht', description: 'Exporteer alle pagina\'s met huidig filtering naar excel' },
						{ id: 'EVERYTHING_TO_HANDLE', title: 'Alles hier te laden/lossen', description: 'Exporteer alle containers hier te laden/lossen naar excel' },
						{ id: 'EVERYTHING', title: 'Alle containers', description: 'Exporteer alle containers naar excel' },
					]}
					onChange={setExportType}
					value={exportType} />

				<ButtonArea>
					<Button level="primary" type="submit"><span className="fa fa-file-excel-o" />&nbsp;&nbsp;Exporteer</Button>
					<Button onClick={() => setOpen(false)}>Annuleren</Button>
				</ButtonArea>
			</Form>
		</Modal>
		<Button onClick={() => { console.log('open it'); setOpen(true); }}>
			<span className="fa fa-file-excel-o"/>&nbsp;&nbsp;Exporteer als Excel
		</Button>
	</>;
}