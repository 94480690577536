import React from "react";
import { Button, Form, Modal, WarningBanner } from '@atrocit/scl';
import ButtonArea from '../../lib/forms/ButtonArea';
import { gql, useMutation } from '@apollo/client';

export default function MergeModal({ sp, isOpen, onClose }) {
	const [ mergeInboundOntoOutbound ] = useMutation(gql`mutation Mutation($planId: Int!) {
        mergeInboundOntoOutbound(planId: $planId) {
            id
        }
    }`);

	return <Modal isOpen={isOpen} onRequestClose={onClose} title="Inkomend plan in uitgaand plan mergen">
		<Form onSubmit={() => mergeInboundOntoOutbound({ variables: { planId: sp.id } }).finally(onClose)}>
			<div style={{ maxWidth: 'var(--u-512)' }}>
				<WarningBanner>
					Dit is experimentele functionaliteit! Gebruik dit niet tenzij strict noodzakelijk, en lees de instructies goed alsvorens door te gaan.
				</WarningBanner>

				<p>Deze functionaliteit leegt het gehele uitgaand plan, met uitzondering van de containers te laden in <i>{sp.portUnlo}</i> en containers welke B/L referentie <i>SHIFTER</i> hebben, en neemt vervolgens alle containers van het inkomend plan over met uitzondering van de containers te lossen in <i>{sp.portUnlo}</i> en containers met B/L <i>SHIFTER</i>.</p>
				<p>Check voor dit te gebruiken:</p>
				<ul>
					<li>Alle shifters op het <b>uitgaand plan</b> hebben als B/L <i>SHIFTER</i> ingevuld. Op dit moment voldoen <b>{sp.outboundCells.filter(c => (c?.container?.carrierReference?.toUpperCase() ?? '') == 'SHIFTER').length}</b> containers aan dit criterium.</li>
					<li>Alle shifters op het <b>inkomend plan</b> hebben als B/L <i>SHIFTER</i> ingevuld. Deze moeten separaat ingevuld van het uitgaand plan! Op dit moment voldoen <b>{sp.inboundCells.filter(c => (c?.container?.carrierReference?.toUpperCase() ?? '') == 'SHIFTER').length}</b> containers aan dit criterium.</li>
					<li>Er zijn geen containers die een POL/POD hebben die afwijkt, bijvoorbeeld <i>BEATO</i> als dit <i>BEANR</i> behoort te zijn. Alles wat niet expliciet POL of POD <i>{sp.portUnlo}</i> heeft, wordt potentieel verwijderd!</li>
					<li>Je hebt een BAPLIE als <b>backup</b> gedownload</li>
				</ul>

				<ButtonArea>
					<Button type="submit"><span className="fa fa-check"/>&nbsp; Ja, ik weet wat ik doe: mergen!</Button>
					<Button level="primary">Annuleren</Button>
				</ButtonArea>
			</div>
		</Form>
	</Modal>;
}
