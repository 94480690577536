import { useParams } from 'react-router-dom';
import { gql, useQuery } from '@apollo/client';
import { useMemo } from 'react';
import { LoadingOverlay } from '@atrocit/scl';
import CreateOperationalPlan from './CreateOperationalPlan';
import OperationalPlanningScreen from './planningscreen/OperationalPlanningScreen';

export default function ViewOperationalPlan() {
	const stowagePlanId = useParams().id;
	const stowagePlanQuery = useQuery(gql`query Query($stowagePlanId: Int!) {
		stowagePlan(id: $stowagePlanId) {
			id,
			pta,
			ptd,
			portUnlo,
			callReference,
			shipName,
			shipTemplate {
				cells {
					bay,
					cellCode,
                },
            },
			operationalPlan {
				id,
            },
			inboundCells {
				id,
				cellCode,
				bay,
				row,
				tier,
				container {
					equipmentIdentifier,
					isoCode,
					isEmpty,
					carrierReference,
					visibility,
					portOfDischarge,
                },
            },
			outboundCells {
				id,
				cellCode,
				bay,
				row,
				tier,
				container {
					equipmentIdentifier,
					isoCode,
					isEmpty,
					carrierReference,
					portOfLoading,
					visibility,
                }
            },
		}
	}`, { variables: { stowagePlanId } });
	const stowagePlan = useMemo(() => stowagePlanQuery.data?.stowagePlan, [ stowagePlanQuery.data?.stowagePlan ]);

	return <>
		{stowagePlan == null && stowagePlanQuery.loading && <LoadingOverlay />}

		{stowagePlan != null && stowagePlan.operationalPlan == null && <div className="app-ui">
			<CreateOperationalPlan stowagePlan={stowagePlan} onRefresh={() => stowagePlanQuery.refetch()} />
		</div>}
		{stowagePlan != null && stowagePlan.operationalPlan != null && <OperationalPlanningScreen stowagePlan={stowagePlan} />}
	</>;
}
