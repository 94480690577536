import Popoutable3 from '../../lib/ui/Popoutable3';
import React, { startTransition, useCallback, useContext, useMemo, useState, useRef, useEffect } from 'react';
import ImportCsvLocationsModal from './ImportCsvLocationsModal';
import { get } from 'lodash';
import CreateContainerModal, { prepContainerNumber } from './CreateContainerModal';
import DeleteContainerModal from './DeleteContainerModal';
import EditableTableField, { BooleanEditFormComponent, NegatedBooleanEditFormComponent } from './EditableTableField';
import CargoListExcelExport from './CargoListExcelExport';
import { DEFAULT_PORT_LIGHTNESS, generateColorForHue } from '../../../util/colors';
import PortColorFunction from '../../../context/PortColorFunction';
import { Button, InputField, Pagination } from '@atrocit/scl';
import DangerousGoodsEditModal from './DangerousGoodsEditModal';
import AttachedEquipmentEditModal from './AttachedEquipmentEditModal';
import SelectField from '../../lib/selectfields/SelectField';
import VariousContainerTotalsModal from './VariousContainerTotalsModal';
import HoverBubble from '../../lib/ui/HoverBubble';

function TableHeader({ showSort, onClick, label }) {
	return <th onClick={onClick} style={{ userSelect: 'none' }}>
		{showSort == 'asc' && <span className="fa fa-sort-asc" />}
		{showSort == 'desc' && <span className="fa fa-sort-desc" />}
		{showSort != null && <>&nbsp;&nbsp;</>}
		{label}
	</th>;
}

const customSortKeys = {
	'container.dangerousGoods': (a, b) => a?.container?.dangerousGoods?.length - b?.container?.dangerousGoods?.length,
};

const pageSize = 100;

export default function CargoList({ highlightCell, shifters, setHighlightCell, editable, inOutBound, polFilter, plan, refetch, cells, open, onClose }) {
	const [ sortKey, setSortKey ] = useState('container.equipmentIdentifier');
	const [ sortDirection, setSortDirection ] = useState('asc');
	const [ deleteContainerId, setDeleteContainerId ] = useState(null);
	const [ importCsvLocationsModalOpen, setImportCsvLocationsModalOpen ] = useState(false);
	const [ createContainerModalOpen, setCreateContainerModalOpen ] = useState(false);
	const [ deleteContainerModalOpen, setDeleteContainerModalOpen ] = useState(false);
	const [ dangerousGoodsEditModalId, setDangerousGoodsEditModalId ] = useState(null); // contains container ID
	const [ attachedEquipmentEditModalId, setAttachedEquipmentEditModalId ] = useState(null); // contains container ID
	const [ variousTotalsModalOpen, setVariousTotalsModalOpen ] = useState(false);
	const [ editField, setEditField ] = useState(null);
	const [ searchQuery, setSearchQuery ] = useState('');
	const [ page, setPage ] = useState(1);
	const [ advancedFilteringBarShow, setAdvancedFilteringBarShow ] = useState(false);
	const [ advancedFilterFullEmpty, setAdvancedFilterFullEmpty ] = useState(null);
	const [ advancedFilterIsReefer, setAdvancedFilterIsReefer ] = useState(null);
	const [ advancedFilterIsOog, setAdvancedFilterIsOog ] = useState(null);

	const oldHighlightCell = useRef(highlightCell);

	const visibleCells = useMemo(() => {
		return cells.filter(c => c.container != null && (polFilter == null || shifters.has(prepContainerNumber(c.container?.equipmentIdentifier || '')) || (inOutBound == 'INBOUND' ? c.container?.portOfDischarge == polFilter : c.container?.portOfLoading == polFilter)));
	}, [ cells, polFilter ]);

	useEffect(() => {
		if (highlightCell != null) {
			setSearchQuery(highlightCell);
		} else if (oldHighlightCell.current == null && searchQuery == highlightCell) {
			setSearchQuery('');
		}
	}, [ highlightCell ]);

	const filteredCells = useMemo(() => {
		const q = searchQuery;
		let textFilter = visibleCells.filter(c => prepContainerNumber(c.container?.equipmentIdentifier || '').includes(q.toUpperCase()) || c.cellCode.includes(q)
				|| (c.container?.portOfLoading || '').includes(q.toUpperCase()) || (c.container.portOfDischarge || '').includes(q.toUpperCase())
				|| (c.container?.isoCode || '').includes(q.toUpperCase()) || (c.container?.stackLocation || '').includes(q.toUpperCase())
				|| (c.container?.carrierReference || '').toUpperCase().includes(q.toUpperCase()));

		if (advancedFilterFullEmpty != null) textFilter = textFilter.filter(c => (advancedFilterFullEmpty.value == "FULL" ? !c.container?.isEmpty : c.container?.isEmpty));
		if (advancedFilterIsReefer != null) textFilter = textFilter.filter(c => (advancedFilterIsReefer.value ? c.container?.reeferTemperature != null : c.container?.reeferTemperature == null));
		if (advancedFilterIsOog != null) textFilter = textFilter.filter(c => (advancedFilterIsOog.value ? c.container?.isOog : !c.container?.isOog));
		return textFilter;
	}, [ visibleCells, searchQuery, advancedFilterFullEmpty, advancedFilterIsReefer, advancedFilterIsOog ]);

	const rows = useMemo(() => {
		return filteredCells.slice().filter(c => c.container != null).sort((a, b) => {
			const valueA = get(a, sortKey);
			const valueB = get(b, sortKey);
			const modifier = sortDirection == 'asc' ? 1 : -1;
			if (customSortKeys[sortKey]) return modifier * customSortKeys[sortKey](a, b);
			if (valueA == valueB) {
				return a.id - b.id;
			} else if (valueA == null || valueA.toString().trim().length == 0) {
				return 1;
			} else if (valueB == null || valueB.toString().trim().length == 0) {
				return -1;
			} else if (valueA < valueB) {
				return modifier * -1;
			} else {
				return modifier * 1;
			}
		});
	}, [ sortKey, sortDirection, polFilter, filteredCells, page ]);

	const pagedRows = useMemo(() => {
		return rows.slice((page - 1) * pageSize, page * pageSize);
	}, [ rows, page ]);

	const onClickSort = useCallback((key) => {
		if (sortKey == key && sortDirection == 'asc') {
			setSortDirection('desc');
		} else {
			setSortDirection('asc');
		}
		setSortKey(key);
	}, [ sortKey, sortDirection ]);

	const counts = useMemo(() => {
		const counts = {
			total: 0, full: 0, empty: 0,
			undg: 0,
			vgm: 0,
			reefer: 0,
			oog: 0, oh: 0, owl: 0, owr: 0, olf: 0, olb: 0,
		};

		for (let i = 0; i < filteredCells.length; i++) {
			const cell = filteredCells[i];
			const ctr = cell.container;
			if (ctr == null) continue;
			counts.total++;
			if (ctr.isEmpty) counts.empty++;
			if (!ctr.isEmpty) counts.full++;
			if (ctr.dangerousGoods != null && ctr.dangerousGoods.length > 0) counts.undg++;
			if (ctr.isVgm) counts.vgm++;
			if (ctr.reeferTemperature != null) counts.reefer++;
			if (ctr.isOog) counts.oog++;
			if (ctr.overHeight) counts.oh++;
			if (ctr.overWidthLeft) counts.owl++;
			if (ctr.overWidthRight) counts.owr++;
			if (ctr.overLengthFront) counts.olf++;
			if (ctr.overLengthBack) counts.olb++;
		}
		return counts;
	}, [ filteredCells ]);

	oldHighlightCell.current = highlightCell;

	return <Popoutable3 open={open} onClose={onClose}>
		<ImportCsvLocationsModal
			key={'import-csv-model-' + importCsvLocationsModalOpen}
			plan={plan}
			inOutBound={inOutBound}
			isOpen={editable && importCsvLocationsModalOpen}
			cells={cells}
			onClose={() => {
				refetch();
				setImportCsvLocationsModalOpen(false);
			}} />

		<CreateContainerModal
			plan={plan}
			isOpen={editable && createContainerModalOpen}
			onClose={() => {
				refetch();
				setCreateContainerModalOpen(false);
			}} />

		<DeleteContainerModal
			isOpen={editable && deleteContainerModalOpen}
			onClose={() => {
				refetch();
				setDeleteContainerModalOpen(false);
			}}
			containerId={deleteContainerId} />

		<DangerousGoodsEditModal
			isOpen={editable && dangerousGoodsEditModalId != null}
			onClose={() => {
				refetch();
				setDangerousGoodsEditModalId(null);
			}}
			containerId={dangerousGoodsEditModalId} />

		<AttachedEquipmentEditModal
			isOpen={editable && attachedEquipmentEditModalId != null}
			onClose={() => {
				refetch();
				setAttachedEquipmentEditModalId(null);
			}}
			containerId={attachedEquipmentEditModalId} />

		<VariousContainerTotalsModal
			isOpen={variousTotalsModalOpen}
			onClose={() => {
				refetch();
				setVariousTotalsModalOpen(false);
			}}
			cells={visibleCells} />

		<div>
			<div className="toolbar">
				<div style={{ display: 'flex', flexDirection: 'row', gap: 'var(--u-8)', alignItems: 'center' }}>
					<Button onClick={() => setAdvancedFilteringBarShow(!advancedFilteringBarShow)} level='primary' active={advancedFilteringBarShow} style={{ paddingRight: '10px' }}>
						<span className="fa fa-filter" />
					</Button>
					<div className="toolbar-description">
						<span style={{ color: 'var(--col-grey-700)', fontSize: 'var(--fs-9)', textTransform: 'uppercase' }}>{plan.callReference} - {plan.shipName} - {plan.portUnlo}</span>
					</div>
				</div>
				<div>
					<InputField placeholder="Zoeken..." onChange={q => startTransition(() => { setSearchQuery(q); setPage(1); })} value={searchQuery} type="search" />
				</div>
				<div className="toolbar-controls">
					<div style={{ display: 'flex', flexDirection: 'row', gap: 'var(--u-8)', alignItems: 'center' }}>
						<CargoListExcelExport pagedRows={pagedRows} rows={rows} plan={plan} inOutBound={inOutBound} shifters={shifters} />
						{editable && <Button onClick={() => setImportCsvLocationsModalOpen(true)}><span className="fa fa-file-text-o" />&nbsp;&nbsp;Interman CSV inlezen</Button>}
						{editable && <Button onClick={() => setCreateContainerModalOpen(true)}><span className="fa fa-plus" />&nbsp;&nbsp;Container toevoegen</Button>}
						<Button onClick={() => setVariousTotalsModalOpen(true)}># Totalen</Button>
					</div>
				</div>
			</div>
			{advancedFilteringBarShow && <div className="advanced-toolbar">
				<div style={{ display: 'flex', alignItems: 'center', zIndex: 100 }}>
					<span style={{ paddingRight: "10px", color: 'var(--col-grey-700)', fontSize: 'var(--fs-9)', textTransform: 'uppercase' }}>V/L</span>
					<div style={{ width: '130px' }}>
						<SelectField
							options={[
								{ value: 'FULL', label: 'VOL' },
								{ value: 'EMPTY', label: 'LEEG' },
							]}
							onChange={setAdvancedFilterFullEmpty}
							value={advancedFilterFullEmpty?.value}
							placeholder={"ALLES"} />
					</div>
				</div>
				<div style={{ display: 'flex', alignItems: 'center', padding: '0 16px', zIndex: 100 }}>
					<span style={{ paddingRight: "10px", color: 'var(--col-grey-700)', fontSize: 'var(--fs-9)', textTransform: 'uppercase' }}>Active reefer</span>
					<div style={{ width: '130px' }}>
						<SelectField
							options={[
								{ value: true, label: 'JA' },
								{ value: false, label: 'NEE' },
							]}
							onChange={setAdvancedFilterIsReefer}
							value={advancedFilterIsReefer?.value}
							placeholder={"ALLES"} />
					</div>
				</div>
				<div style={{ display: 'flex', alignItems: 'center', padding: '0 16px', zIndex: 100 }}>
					<span style={{ paddingRight: "10px", color: 'var(--col-grey-700)', fontSize: 'var(--fs-9)', textTransform: 'uppercase' }}>OOG</span>
					<div style={{ width: '130px' }}>
						<SelectField
							options={[
								{ value: true, label: 'JA' },
								{ value: false, label: 'NEE' },
							]}
							onChange={setAdvancedFilterIsOog}
							value={advancedFilterIsOog?.value}
							placeholder={"ALLES"} />
					</div>
				</div>
			</div>}

			<table className="table" style={{ whiteSpace: 'nowrap' }}>
				<colgroup>
					<col width="30" />
					<col width="190" />
					<col width="*" />
					<col width="*" />
					<col width="80" />
					<col width="80" />
					<col width="150" />
					<col width="70" />
					<col width="30" />
					<col width="70" />
					<col width="90" />
					<col width="70" />
					<col width="30" />
					<col width="90" />
					<col width="90" />
					<col width="90" />
					<col width="90" />
					<col width="90" />
					<col width="100" />
				</colgroup>
				<thead>
					<tr>
						<th className="tbl-center">V / L</th>
						<TableHeader label="Containernummer" showSort={sortKey == 'container.equipmentIdentifier' ? sortDirection : null} onClick={() => onClickSort('container.equipmentIdentifier')} />
						<TableHeader label="B/L" showSort={sortKey == 'container.carrierReference' ? sortDirection : null} onClick={() => onClickSort('container.carrierReference')} />
						<TableHeader label="UNDG" showSort={sortKey == 'container.dangerousGoods' ? sortDirection : null} onClick={() => onClickSort('container.dangerousGoods')} />

						<TableHeader label="POL" showSort={sortKey == 'container.portOfLoading' ? sortDirection : null} onClick={() => onClickSort('container.portOfLoading')} />
						<TableHeader label="POD" showSort={sortKey == 'container.portOfDischarge' ? sortDirection : null} onClick={() => onClickSort('container.portOfDischarge')} />
						<TableHeader label="Yard loc." showSort={sortKey == 'container.stackLocation' ? sortDirection : null} onClick={() => onClickSort('container.stackLocation')} />
						<TableHeader label="Type" showSort={sortKey == 'container.isoCode' ? sortDirection : null} onClick={() => onClickSort('container.isoCode')} />
						<TableHeader label="VGM" showSort={sortKey == 'container.isVgm' ? sortDirection : null} onClick={() => onClickSort('container.isVgm')} />
						<TableHeader label="Weight" showSort={sortKey == 'container.grossWeight' ? sortDirection : null} onClick={() => onClickSort('container.grossWeight')} />
						<TableHeader label="Cell" showSort={sortKey == 'cellCode' ? sortDirection : null} onClick={() => onClickSort('cellCode')} />
						<TableHeader label="Temp" showSort={sortKey == 'container.reeferTemperature' ? sortDirection : null} onClick={() => onClickSort('container.reeferTemperature')} />
						<TableHeader label="OOG" showSort={sortKey == 'container.isOog' ? sortDirection : null} onClick={() => onClickSort('container.isOog')} />

						<TableHeader label="OH" showSort={sortKey == 'container.overHeight' ? sortDirection : null} onClick={() => onClickSort('container.overHeight')} />
						<TableHeader label="OB links" showSort={sortKey == 'container.overWidthLeft' ? sortDirection : null} onClick={() => onClickSort('container.overWidthLeft')} />
						<TableHeader label="OB rechts" showSort={sortKey == 'container.overWidthRight' ? sortDirection : null} onClick={() => onClickSort('container.overWidthRight')} />
						<TableHeader label="OL voor" showSort={sortKey == 'container.overLengthFront' ? sortDirection : null} onClick={() => onClickSort('container.overLengthFront')} />
						<TableHeader label="OL achter" showSort={sortKey == 'container.overLengthBack' ? sortDirection : null} onClick={() => onClickSort('container.overLengthBack')} />
						<TableHeader label="" />
					</tr>
					<tr className="table-secondary-header">
						<th className="tbl-center">{counts.full} / {counts.empty}</th>
						<th>{counts.total} containers</th>
						<th />
						<th>&times; {counts.undg}</th>
						<th />
						<th />
						<th />
						<th />
						<th>&times; {counts.vgm}</th>
						<th></th>
						<th />
						<th>&times; {counts.reefer}</th>
						<th>&times; {counts.oog}</th>
						<th>&times; {counts.oh}</th>
						<th>&times; {counts.owl}</th>
						<th>&times; {counts.owr}</th>
						<th>&times; {counts.olf}</th>
						<th>&times; {counts.olb}</th>
						<th/>
					</tr>
				</thead>
				<tbody>
					{pagedRows.map((cell, idx) => {
						const ctr = cell?.container;
						return <>
							<CargoRow
								inOutBound={inOutBound}
								cell={cell}
								ctr={ctr}
								editField={editField}
								plan={plan}
								refetch={refetch}
								pagedRows={pagedRows}
								idx={idx}
								setEditField={setEditField}
								setHighlightCell={setHighlightCell}
								setDeleteContainerId={setDeleteContainerId}
								setDeleteContainerModalOpen={setDeleteContainerModalOpen}
								setDangerousGoodsEditModalId={setDangerousGoodsEditModalId}
								setAttachedEquipmentEditModalId={setAttachedEquipmentEditModalId} />
							{/* {ctr.attachedEquipment.filter(ae => ae != null && ae.length > 0).map((c, idx) => {*/}
							{/*	let ctrAE = { ...cell?.container, equipmentIdentifier: c };*/}
							{/*	return <EQARow*/}
							{/*		eqaIndex={idx}*/}
							{/*		cell={cell}*/}
							{/*		ctr={ctrAE}*/}
							{/*		editField={editField}*/}
							{/*		plan={plan}*/}
							{/*		refetch={refetch}*/}
							{/*		setEditField={setEditField}*/}
							{/*		setDeleteContainerId={setDeleteContainerId}*/}
							{/*		setDeleteContainerModalOpen={setDeleteContainerModalOpen}*/}
							{/*		isEQA={true} />;*/}
							{/* })}*/}
						</>;
					})}
				</tbody>
				<tfoot>
					<tr>
						<td colSpan={99} style={{ boxShadow: '0 0 4px rgba(0, 0, 0, 0.1)', background: 'var(--col-primary-900)', color: 'var(--col-white)' }}>
							<div style={{ display: 'flex', justifyContent: 'flex-end', minHeight: 'var(--u-32)', gap: 'var(--u-24)', alignItems: 'stretch' }}>
								<div style={{ color: 'var(--col-primary-100)', fontSize: 'var(--fs-11)', display: 'flex', alignItems: 'center' }}>
									<span>Containers {(page - 1) * pageSize} t/m {Math.min((page * pageSize) - 1, rows.length)} getoond, {rows.length} resultaten, van {visibleCells.length} totaal</span>
								</div>

								<Pagination
									page={page}
									setPage={setPage}
									pageSize={pageSize}
									total={rows.length} />
							</div>
						</td>
					</tr>
				</tfoot>
			</table>
		</div>
	</Popoutable3>;
}

function EQARow({ cell, ctr }) {
	const unloToHue = useContext(PortColorFunction);

	return <tr key={cell.id}>
		<td className="tbl-center tbl-fit">
			<DisplayFullEmpty value={ctr.isEmpty} />
		</td>
		<td>
			<DisplayEQA value={ctr.equipmentIdentifier} />
		</td>
		<td>
			{ctr.carrierReference}
		</td>
		<td style={{ whiteSpace: 'initial' }}>{ctr.dangerousGoods.map(dg => dg.undgCode ?? dg.imoClass).join(', ')}</td>
		<td style={{ background: ctr.portOfLoading == null ? '' : generateColorForHue(unloToHue(ctr.portOfLoading), DEFAULT_PORT_LIGHTNESS) }}>
			{ctr.portOfLoading}
		</td>
		<td style={{ background: ctr.portOfDischarge == null ? '' : generateColorForHue(unloToHue(ctr.portOfDischarge), DEFAULT_PORT_LIGHTNESS) }}>
			{ctr.portOfDischarge}
		</td>
		<td>
			{ctr.stackLocation}
		</td>
		<td>
			{ctr.isoCode}
		</td>
		<td className="tbl-center tbl-fit">
			<DisplayVgm value={ctr.isVgm} />
		</td>
		<td className="tbl-align-right">
			{ctr.grossWeight}
		</td>
		<td>{cell.cellCode}</td>
		<td className="tbl-align-right">
			<DisplayTmp value={ctr.reeferTemperature} />
		</td>
		<td>
			{ctr.isOog ? <span className="fa fa-check-circle green" /> : ''}
		</td>
		<td className="tbl-align-right">
			<DisplayMm value={ctr.overHeight} />
		</td>
		<td className="tbl-align-right">
			<DisplayMm value={ctr.overWidthLeft} />
		</td>
		<td className="tbl-align-right">
			<DisplayMm value={ctr.overWidthRight} />
		</td>
		<td className="tbl-align-right">
			<DisplayMm value={ctr.overLengthFront} />
		</td>
		<td className="tbl-align-right">
			<DisplayMm value={ctr.overLengthBack} />
		</td>
		<td/>
	</tr>;
}

function CargoRow({ pagedRows, idx, inOutBound, cell, ctr, editField, setEditField, refetch, plan, setDeleteContainerId, setDeleteContainerModalOpen, setHighlightCell, setDangerousGoodsEditModalId, setAttachedEquipmentEditModalId }) {
	const unloToHue = useContext(PortColorFunction);

	const commonProps = useMemo(() => ({
		onDone: () => {
			setEditField(null);
			refetch();
		},
		onGoUp: () => {
			if (idx == 0) {
				setEditField(null);
			} else {
				setEditField(ef => ({ ...ef, row: pagedRows[idx - 1].container?.id }));
			}
			refetch();
		},
		onGoDown: () => {
			if (idx == pagedRows.length - 1) {
				setEditField(null);
			} else {
				setEditField(ef => ({ ...ef, row: pagedRows[idx + 1].container?.id }));
			}
			refetch();
		},
		requestEdit: (field) => setEditField({ row: ctr.id, field }),
	}), [ pagedRows, idx, refetch, setEditField ]);

	return <tr key={cell.id}>
		<EditableTableField
			{...commonProps}
			value={ctr.isEmpty}
			className="tbl-center tbl-fit"
			displayComponent={DisplayFullEmpty}
			editFormComponent={NegatedBooleanEditFormComponent}
			editing={editField != null && editField.row == ctr.id && editField.field == 'isEmpty'}
			planId={plan.id}
			containerId={ctr.id}
			field="isEmpty" />
		<EditableTableField
			{...commonProps}
			uppercaseOnly={true}
			validateEquipmentIdentifier={true}
			value={ctr.equipmentIdentifier}
			extra={ctr.attachedEquipment.length > 0 && <div style={{ fontSize: '0.85em', color: 'var(--col-grey-700)' }}>
				{ctr.attachedEquipment.map((ae, idx) => <div key={idx}>{'\u21B3'} {ae || '-'}</div>)}
			</div>}
			editing={editField != null && editField.row == ctr.id && editField.field == 'equipmentIdentifier'}
			editingExtra={(ctr.isoCode != null && ctr.isoCode[2] == 'P') ? <><Button onClick={() => setAttachedEquipmentEditModalId(ctr.id)}><span className="fa fa-align-justify" /></Button></> : null}
			planId={plan.id}
			containerId={ctr.id}
			field="equipmentIdentifier" />
		<EditableTableField
			{...commonProps}
			value={ctr.carrierReference}
			editing={editField != null && editField.row == ctr.id && editField.field == 'carrierReference'}
			planId={plan.id}
			containerId={ctr.id}
			field="carrierReference" />
		<td className="td-editable" style={{ whiteSpace: 'initial' }} onDoubleClick={() => setDangerousGoodsEditModalId(ctr.id)}>
			{ctr.dangerousGoods.map(dg => dg.undgCode ?? dg.imoClass).sort().join(', ')}
			<div className="td-editable-indicator" />
		</td>
		<EditableTableField
			{...commonProps}
			value={ctr.portOfLoading}
			uppercaseOnly={true}
			editing={editField != null && editField.row == ctr.id && editField.field == 'portOfLoading'}
			planId={plan.id}
			containerId={ctr.id}
			field="portOfLoading"
			style={{ background: ctr.portOfLoading == null ? '' : generateColorForHue(unloToHue(ctr.portOfLoading), DEFAULT_PORT_LIGHTNESS) }} />
		<EditableTableField
			{...commonProps}
			value={ctr.portOfDischarge}
			uppercaseOnly={true}
			editing={editField != null && editField.row == ctr.id && editField.field == 'portOfDischarge'}
			requestEdit={() => (inOutBound == 'INBOUND' ? null : setEditField({ row: ctr.id, field: 'portOfDischarge' }))}
			planId={plan.id}
			containerId={ctr.id}
			field="portOfDischarge"
			style={{ background: ctr.portOfDischarge == null ? '' : generateColorForHue(unloToHue(ctr.portOfDischarge), DEFAULT_PORT_LIGHTNESS) }} />
		<EditableTableField
			{...commonProps}
			value={ctr.stackLocation}
			uppercaseOnly={true}
			editing={editField != null && editField.row == ctr.id && editField.field == 'stackLocation'}
			planId={plan.id}
			containerId={ctr.id}
			field="stackLocation" />
		<EditableTableField
			{...commonProps}
			value={ctr.isoCode}
			uppercaseOnly={true}
			editing={editField != null && editField.row == ctr.id && editField.field == 'isoCode'}
			planId={plan.id}
			containerId={ctr.id}
			field="isoCode" />
		<EditableTableField
			{...commonProps}
			className="tbl-center tbl-fit"
			value={ctr.isVgm}
			displayComponent={DisplayVgm}
			editFormComponent={BooleanEditFormComponent}
			editing={editField != null && editField.row == ctr.id && editField.field == 'isVgm'}
			planId={plan.id}
			containerId={ctr.id}
			field="isVgm" />
		<EditableTableField
			{...commonProps}
			className="tbl-align-right"
			value={ctr.grossWeight}
			editing={editField != null && editField.row == ctr.id && editField.field == 'grossWeight'}
			planId={plan.id}
			containerId={ctr.id}
			field="grossWeight" />
		<td>{cell.cellCode}</td>
		<EditableTableField
			{...commonProps}
			className="tbl-align-right"
			displayComponent={DisplayTmp}
			isNumber={true}
			value={ctr.reeferTemperature}
			editing={editField != null && editField.row == ctr.id && editField.field == 'reeferTemperature'}
			planId={plan.id}
			containerId={ctr.id}
			field="reeferTemperature" />
		<td className="tbl-center">
			{ctr.isOog ? <span className="fa fa-check-circle green" /> : ''}
		</td>
		<EditableTableField
			{...commonProps}
			className="tbl-align-right"
			displayComponent={DisplayMm}
			processBeforeSaving={v => v * 10}
			value={ctr.overHeight}
			editing={editField != null && editField.row == ctr.id && editField.field == 'overHeight'}
			requestEdit={() => setEditField({ row: ctr.id, field: 'overHeight' })}
			onDone={() => { setEditField(null); refetch(); }}
			planId={plan.id}
			containerId={ctr.id}
			isNumber={true}
			field="overHeight" />
		<EditableTableField
			{...commonProps}
			className="tbl-align-right"
			displayComponent={DisplayMm}
			processBeforeSaving={v => v * 10}
			value={ctr.overWidthLeft}
			editing={editField != null && editField.row == ctr.id && editField.field == 'overWidthLeft'}
			planId={plan.id}
			containerId={ctr.id}
			isNumber={true}
			field="overWidthLeft" />
		<EditableTableField
			{...commonProps}
			className="tbl-align-right"
			displayComponent={DisplayMm}
			processBeforeSaving={v => v * 10}
			value={ctr.overWidthRight}
			editing={editField != null && editField.row == ctr.id && editField.field == 'overWidthRight'}
			planId={plan.id}
			containerId={ctr.id}
			isNumber={true}
			field="overWidthRight" />
		<EditableTableField
			{...commonProps}
			className="tbl-align-right"
			displayComponent={DisplayMm}
			processBeforeSaving={v => v * 10}
			value={ctr.overLengthFront}
			editing={editField != null && editField.row == ctr.id && editField.field == 'overLengthFront'}
			planId={plan.id}
			containerId={ctr.id}
			isNumber={true}
			field="overLengthFront" />
		<EditableTableField
			{...commonProps}
			className="tbl-align-right"
			displayComponent={DisplayMm}
			processBeforeSaving={v => v * 10}
			value={ctr.overLengthBack}
			editing={editField != null && editField.row == ctr.id && editField.field == 'overLengthBack'}
			planId={plan.id}
			containerId={ctr.id}
			isNumber={true}
			field="overLengthBack" />
		<td>
			<div style={{ display: 'flex', gap: 'var(--u-8)' }}>
				<HoverBubble isRightOverflow={true} content="Highlight container in stuwplan"><Button className="fa fa-crosshairs" onClick={() => setHighlightCell(cell.cellCode)} /></HoverBubble>
				<HoverBubble isRightOverflow={true} content="Verwijder deze container"><Button className="fa fa-trash" onClick={() => { setDeleteContainerId(ctr.id); setDeleteContainerModalOpen(true); }} /></HoverBubble>
			</div>
		</td>
	</tr>;
}

function DisplayFullEmpty({ value }) {
	if (value) {
		return <span style={{ display: 'inline-block', width: '13px', height: '13px', borderRadius: '50%', background: 'var(--col-primary-200)', border: '2px solid var(--col-primary-600)' }} />;
	} else {
		return <span style={{ display: 'inline-block', width: '13px', height: '13px', borderRadius: '50%', background: 'var(--col-primary-600)' }} />;
	}
}

function DisplayVgm({ value }) {
	return value ? <span className="fa fa-check-circle green" /> : <span className="fa fa-times-circle red" />;
}

function DisplayMm({ value }) {
	if (value == null) return null;
	return <span>{value} <span style={{ fontSize: '9pt', color: 'var(--col-grey-400)' }}>mm</span></span>;
}

function DisplayCm({ value }) {
	if (value == null) return null;
	return <span>{value / 10} <span style={{ fontSize: '9pt', color: 'var(--col-grey-400)' }}>cm</span></span>;
}

function DisplayTmp({ value }) {
	if (value == null) return null;
	return <span>{value} <span style={{ fontSize: '9pt', color: 'var(--col-grey-400)' }}>&deg;C</span></span>;
}

function DisplayEQA({ value }) {
	if (value == null) return null;
	return <span>{'\u21B3'} {value}</span>;
}