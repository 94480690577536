export default function DisplayDuration({ value }) {
	const minutes = Math.floor((value / 60) % 60);
	const hours = Math.floor((value / 60 / 60));

	const hoursStr = hours.toString().padStart(2, '0');
	const minutesStr = minutes.toString().padStart(2, '0');

	return <>
		{hours >= 100 && <Digit digit={hoursStr[0]} faded={false} />}
		<Digit digit={hoursStr[hoursStr.length - 2]} faded={hours < 10} />
		<Digit digit={hoursStr[hoursStr.length - 1]} faded={hours == 0} />
		<span style={{ opacity: hours == 0 ? 0.2 : 1 }}>:</span>
		<Digit digit={minutesStr[0]} faded={hours == 0 && minutes < 10} />
		<Digit digit={minutesStr[1]} faded={hours == 0 && minutes == 0} />
	</>;
}

function Digit({ digit, faded }) {
	return <span style={{ opacity: faded ? 0.2 : 1 }}>{digit}</span>;
}