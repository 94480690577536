import React from 'react';
import { parseValue } from './DateTime';

const weekDayMapping = [ 'Zo', 'Ma', 'Di', 'Wo', 'Do', 'Vr', 'Za' ];

export default function ShortDateTime({ value, disableTime }) {
	const d = parseValue(value);
	if (d == null) return <span>-</span>;

	// Shows DD-MM-YYYY
	const datePart = weekDayMapping[d.getDay()].toLowerCase() + ' ' + (d.getDate() + '').padStart(2, '0') + '-' + ((d.getMonth() + 1) + '').padStart(2, '0');
	// Shows HH:MM
	const timePart = (d.getHours() + '').padStart(2, '0') + ':' + (d.getMinutes() + '').padStart(2, '0');
	return <span>{datePart + ' ' + (disableTime ? '' : timePart)}</span>;
}
