import React, { useState } from 'react';
import { DateTime } from 'luxon';
import ButtonArea from '../lib/forms/ButtonArea';
import { gql, useMutation } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import ValidatedButton from '../lib/forms/ValidatedButton';
import Breadcrumbs from '../lib/Breadcrumbs';
import ShipTemplateField from '../lib/selectfields/ShipTemplateField';
import DateTimeField from '../lib/forms/DateTimeField';
import { Form, InputField, WithLabel } from '@atrocit/scl';

export default function CreateCall() {
	const navigate = useNavigate();

	const [ callReference, setCallReference ] = useState('');
	const [ shipTemplate, setShipTemplate ] = useState(null);
	const [ shipName, setShipName ] = useState('');
	const [ portUnlo, setPortUnlo ] = useState('BEANR');
	const [ pta, setPta ] = useState(DateTime.now().startOf('hour').plus({ hours: 1 }));
	const [ ptd, setPtd ] = useState(DateTime.now().startOf('hour').plus({ hours: 5 }));

	const [ createPlan, { loading } ] = useMutation(gql`mutation Mutation($callReference: String!, $shipName: String!, $shipTemplate: Int, $portCode: String!, $pta: Instant!, $ptd: Instant!) {
		createPlan(callReference: $callReference, shipName: $shipName, shipTemplate: $shipTemplate, portCode: $portCode, pta: $pta, ptd: $ptd) { id }
	}`);

	return <div className="app-ui">
		<Breadcrumbs segments={[
			{ link: '/calls', label: 'Calls' },
			{ link: '/calls/create', label: 'Nieuwe call' },
		]} />
		<Form onSubmit={() => {
			const variables = {
				callReference,
				shipName,
				shipTemplate,
				portCode: portUnlo,
				pta: pta.toUTC().toISO(),
				ptd: ptd.toUTC().toISO(),
			};
			createPlan({ variables })
				.then(results => {
					console.log('Results', results);
					console.log('pushing to ' + '/calls/' + results?.data?.createPlan?.id);
					navigate('/calls/' + results?.data?.createPlan?.id);
				}).catch(err => {
					console.log('Error in create a plan ');
				});
		}}>
			<WithLabel label="Callreferentie">
				<InputField onChange={setCallReference} value={callReference} />
			</WithLabel>

			<WithLabel label="Scheepstemplate">
				<ShipTemplateField
					onChange={(stId, template) => {
						setShipTemplate(stId);
						if (shipName == null || shipName.trim().length == 0) {
							setShipName(template?.templateName ?? '');
							setShipTemplate(stId);
						}
					}}
					value={shipTemplate} />
			</WithLabel>

			<WithLabel label="Scheepsnaam">
				<InputField onChange={setShipName} value={shipName} />
			</WithLabel>

			<WithLabel label="Haven UN-locatie code">
				<InputField onChange={setPortUnlo} value={portUnlo} />
			</WithLabel>

			<div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: 'var(--u-16)' }}>
				<div>
					<WithLabel label="Geplande aankomst (PTA)">
						<DateTimeField onChange={dt => (new DateTime(dt).isValid ? setPta(dt) : null)} value={pta} />
					</WithLabel>
				</div>
				<div>
					<WithLabel label="Gepland vertrek (PTD)">
						<DateTimeField onChange={dt => (new DateTime(dt).isValid ? setPtd(dt) : null)} value={ptd} />
					</WithLabel>
				</div>
			</div>

			<ButtonArea>
				<ValidatedButton type="submit">Call aanmaken</ValidatedButton>
			</ButtonArea>
		</Form>
	</div>;
}