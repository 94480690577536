import React, { useCallback, useEffect, useState } from 'react';
// import InputField from '../../lib/forms/InputField';
import Form from '../../lib/forms/Form';
import { gql, useMutation } from '@apollo/client';
import Button from '../../lib/forms/Button';
import { GQL_CONTAINER_FRAGMENT } from './ViewCall';
import { Toggle } from '../../lib/forms/Toggle';
import { InputField } from '@atrocit/scl';

function IdentityComponent({ value }) {
	return <>{value}</>;
}

export default React.memo(function EditableTableField({ extra = null, editingExtra = null, className, editing, requestEdit, value, onDone, planId, containerId, field, displayComponent, editFormComponent, isNumber = false, uppercaseOnly = false, validateEquipmentIdentifier = false, style, onGoUp, onGoDown }) {
	const [ newValue, setNewValue ] = useState(null);

	const [ updateContainers ] = useMutation(gql`
		${GQL_CONTAINER_FRAGMENT}
		
		mutation Mutation($planId: Int!, $updates: [ ContainerPartialUpdate! ]!) {
	        updateContainers(planId: $planId, updates: $updates) {
		        ...ContainerView
	        }
	    }
	`);

	const DisplayComp = displayComponent || IdentityComponent;
	const calculatedValue = newValue ?? value;

	const EditFormComponent = editFormComponent || DefaultEditFormComponent;

	useEffect(() => {
		if (editing) return;
		setNewValue(null);
	}, [ editing ]);

	const onSubmit = useCallback((callDone = true) => {
		return updateContainers({
			variables: {
				planId,
				updates: [
					{
						containerId: containerId,
						[field]: isNumber && (typeof calculatedValue == 'string' && calculatedValue.length == 0) ? null : calculatedValue,
						partialUpdateFieldSet: {
							[field]: true,
						},
					},
				],
			},
		}).finally(callDone ? onDone : () => {});
	}, [ field, containerId, calculatedValue, updateContainers, onDone ]);

	if (editing) {
		return <td className={"td-editable " + (className || '')}>
			<Form onSubmit={onSubmit}>
				<div style={{ display: 'flex', alignItems: 'center' }}>
					<EditFormComponent
						onDone={onDone}
						onGoUp={() => {
							if (calculatedValue == value) return onGoUp();
							onSubmit(false)
								.then(onGoUp);
						}}
						onGoDown={() => {
							if (calculatedValue == value) return onGoDown();
							onSubmit(false)
								.then(onGoDown);
						}}
						calculatedValue={calculatedValue}
						setNewValue={newVal => {
							// eslint-disable-next-line
							if (typeof newVal == 'string' && uppercaseOnly) newVal = newVal.toUpperCase();
							setNewValue(newVal);
						}} />
					{validateEquipmentIdentifier && <div style={{ paddingLeft: '4px' }}>
						{calculatedValue != null && calculatedValue.length == 11 && isValidIso6346(calculatedValue) && <span className="fa fa-check-circle green" />}
						{calculatedValue != null && calculatedValue.length == 11 && !isValidIso6346(calculatedValue) && <span className="fa fa-times-circle" style={{ color: 'var(--col-red)' }} />}
						{calculatedValue.length != 11 && <span className="fa fa-question-circle" style={{ color: 'var(--col-grey-500)' }} />}
					</div>}
					{editingExtra}
				</div>
			</Form>
		</td>;
	} else {
		return <td onDoubleClick={() => requestEdit(field)} className={"td-editable " + (className || '')} style={style}>
			<DisplayComp value={value} />
			{extra}
			<div className="td-editable-indicator" />
		</td>;
	}
});

function DefaultEditFormComponent({ onDone, onGoUp, onGoDown, calculatedValue, setNewValue }) {
	return <>
		<InputField
			onKeyDown={e => {
				if (e.keyCode == 27) onDone();
				if (e.keyCode == 38) {
					onGoUp();
					e.preventDefault();
				}
				if (e.keyCode == 40) {
					onGoDown();
					e.preventDefault();
				}
			}}
			autoFocus={true}
			value={calculatedValue}
			onChange={setNewValue} />

		<div style={{ overflow: 'hidden', width: 0, height: 0 }}>
			<Button type="submit" style={{ width: 0, height: 0, opacity: 0, margin: 0, padding: 0 }} />
		</div>
	</>;
}

export function BooleanEditFormComponent({ onDone, calculatedValue, setNewValue }) {
	return <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 'var(--u-4)' }}>
		<Toggle onChange={() => setNewValue(!calculatedValue)} value={calculatedValue} />
		<Button type="submit">
			<span className="fa fa-check" />
		</Button>
	</div>;
}

export function NegatedBooleanEditFormComponent({ onDone, calculatedValue, setNewValue }) {
	return <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 'var(--u-4)' }}>
		<div>
			<Toggle onChange={() => setNewValue(!calculatedValue)} value={!calculatedValue} />
		</div>
		<Button type="submit">
			<span className="fa fa-check" />
		</Button>
	</div>;
}


const alphabetNumerical = {
	'0': 0, '1': 1, '2': 2, '3': 3, '4': 4, '5': 5, '6': 6, '7': 7, '8': 8, '9': 9,
	'A': 10, 'B': 12, 'C': 13, 'D': 14, 'E': 15, 'F': 16, 'G': 17, 'H': 18, 'I': 19, 'J': 20, 'K': 21, 'L': 23, 'M': 24,
	'N': 25, 'O': 26, 'P': 27, 'Q': 28, 'R': 29, 'S': 30, 'T': 31, 'U': 32, 'V': 34, 'W': 35, 'X': 36, 'Y': 37, 'Z': 38,
};

function isValidIso6346(value) {
	const containerNumber = (value || '').replace(/[^a-zA-Z0-9]+/g, '').toUpperCase();
	if (!/[A-Z]{4}[0-9]{7}/.test(containerNumber)) return false;

	let sum = 0;
	for (let i = 0; i < containerNumber.length - 1; i++) {
		sum += alphabetNumerical[containerNumber[i]] * Math.pow(2, i);
	}
	let elevenedSum = Math.floor(sum / 11) * 11;
	const checkDigit = (sum - elevenedSum) % 10;

	return checkDigit == (alphabetNumerical[containerNumber.slice(-1)] ?? -1);
}