import { Canvas, useFrame } from '@react-three/fiber';
import { useContext, useRef, useState } from 'react';
import { OrbitControls, PerspectiveCamera } from '@react-three/drei';
import { DARK_PORT_LIGHTNESS, generateHexColorForHue } from '../../../../util/colors';
import PortColorFunction from '../../../../context/PortColorFunction';
import { useTasks, useTasksByCellCode } from '../digging/DiggingCalculationsProvider';
import { Button, ButtonGroup } from '@atrocit/scl';
import { dischargeComplete, loadingComplete } from '../bayplanning/MiniBay';

export default function ThreeDimensionalShip({ voyageData, selectedBay, plan, polFilter, inOutBound }) {
	const [ mode, setMode ] = useState({ type: 'ANIMATION' });
	const numberInbound = (plan?.inboundCells ?? []).filter(c => c != null && c.container != null && (c.container.portOfDischarge == plan.portUnlo || c.container.visibility == 'VISIBLE')).length;
	const numberOutbound = (plan?.outboundCells ?? []).filter(c => c != null && c.container != null && (c.container.portOfLoading == plan.portUnlo || c.container.visibility == 'VISIBLE')).length;
	const animationTotal = numberInbound + numberOutbound;

	return <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'stretch', flex: 1, height: '100%' }}>
		<div style={{ padding: 'var(--u-16)' }}>
			<ButtonGroup>
				<Button active={mode.type == 'ANIMATION'} level={mode.type == 'ANIMATION' ? 'primary' : 'secondary'} onClick={() => setMode({ type: 'ANIMATION' })}>Animating</Button>
				<Button active={mode.type == 'REALTIME'} level={mode.type == 'REALTIME' ? 'primary' : 'secondary'} onClick={() => setMode({ type: 'REALTIME' })}>Realtime</Button>
				<Button active={mode.type == 'INBOUND'} level={mode.type == 'INBOUND' ? 'primary' : 'secondary'} onClick={() => setMode({ type: 'INBOUND' })}>Inbound</Button>
				<Button active={mode.type == 'OUTBOUND'} level={mode.type == 'OUTBOUND' ? 'primary' : 'secondary'} onClick={() => setMode({ type: 'OUTBOUND' })}>Outbound</Button>
			</ButtonGroup>
		</div>

		<div style={{ flex: 1 }}>

			<Canvas>
				<PerspectiveCamera makeDefault={true} fov={40} position={[ -100, 150, 100 ]} />
				<OrbitControls
					makeDefault={true}
					enableDamping={true}
					dampingFactor={0.05}
					minDistance={10}
					maxDistance={1000}
					target={[ 0, 10, 0 ]} />

				<ambientLight intensity={Math.PI * 0.35} />
				<pointLight position={[ -50, 140, 10 ]} decay={0} intensity={Math.PI * 0.3} />

				{plan != null && <Ship plan={plan} voyageData={voyageData} mode={mode} animationTotal={animationTotal} />}
			</Canvas>
		</div>
	</div>;
}

function Ship({ plan, voyageData, mode, animationTotal }) {
	const animationSpeed = 10; // in moves/sec
	const tasks = useTasks() ?? [];

	// const cogRef = useRef();

	// Center of gravity calculations/animations
	// useFrame((state, delta) => {
	// 	const offset = (((state.clock.elapsedTime) * animationSpeed) % animationTotal);
	// 	const tasksDone = tasks.slice(0, Number(offset));
	// 	const allCells = new Set(tasks.map(t => t.cell.cellCode));
	// 	const cellsDischarged = new Set(tasksDone.filter(t => t.type == 'DISCHARGE').map(t => t.cell.cellCode));
	// 	const cellsLoaded = new Set(tasksDone.filter(t => t.type == 'LOAD').map(t => t.cell.cellCode));
	//
	// 	const sumOfWeightInit = plan.inboundCells.reduce((tot, c) => tot + (c.container?.grossWeight ?? 0), 0);
	// 	const initCogX = plan.inboundCells.reduce((tot, c) => tot + (c.container?.grossWeight ?? 0) * bayToX(c.bay), 0) / sumOfWeightInit;
	// 	const initCogY = plan.inboundCells.reduce((tot, c) => tot + (c.container?.grossWeight ?? 0) * tierToY(c.tier), 0) / sumOfWeightInit;
	// 	const initCogZ = plan.inboundCells.reduce((tot, c) => tot + (c.container?.grossWeight ?? 0) * rowToZ(c.row), 0) / sumOfWeightInit;
	//
	// 	const cellsOnBoard = [
	// 		...plan.inboundCells.filter(c => allCells.has(c.cellCode) && !cellsDischarged.has(c.cellCode)),
	// 		...plan.outboundCells.filter(c => cellsLoaded.has(c.cellCode) || !allCells.has(c.cellCode)),
	// 	];
	//
	// 	const sumOfWeight = cellsOnBoard.reduce((tot, c) => tot + (c.container?.grossWeight ?? 0), 0);
	// 	const cogX = cellsOnBoard.reduce((tot, c) => tot + (c.container?.grossWeight ?? 0) * bayToX(c.bay), 0) / sumOfWeight;
	// 	const cogY = cellsOnBoard.reduce((tot, c) => tot + (c.container?.grossWeight ?? 0) * tierToY(c.tier), 0) / sumOfWeight;
	// 	const cogZ = cellsOnBoard.reduce((tot, c) => tot + (c.container?.grossWeight ?? 0) * rowToZ(c.row), 0) / sumOfWeight;
	//
	// 	cogRef.current.position.x = cogX;
	// 	cogRef.current.position.y = cogY;
	// 	cogRef.current.position.z = cogZ;
	// });

	const shipGroupRef = useRef();

	return <group ref={shipGroupRef}>
		{plan != null && <>
			{/* <mesh ref={cogRef}>*/}
			{/*	<sphereGeometry args={[ 5, 32, 32 ]} />*/}
			{/*	<meshStandardMaterial color={"blue"} />*/}
			{/* </mesh>*/}

			{plan.inboundCells.map(cell => {
				if (cell == null || cell.container == null) return null;
				if (cell.bay == 4) console.log(cell);

				return <Container
					animationSpeed={animationSpeed}
					voyageData={voyageData}
					cell={cell}
					mode={mode}
					animationTotal={animationTotal}
					inboundOutbound="INBOUND"
					portUnlo={plan.portUnlo}
					key={cell.id} />;
			})}
			{plan.outboundCells.map(cell => {
				if (cell == null || cell.container == null) return null;

				return <Container
					animationSpeed={animationSpeed}
					voyageData={voyageData}
					cell={cell}
					mode={mode}
					animationTotal={animationTotal}
					inboundOutbound="OUTBOUND"
					portUnlo={plan.portUnlo}
					key={cell.id} />;
			})}
		</>}
	</group>;
}

function bayToX(bay) {
	return bay * 3.20 - (23 * 3.5);
}

function tierToY(tier) {
	return (tier > 80 ? tier - 60 : tier) * 1.6;
}

function rowToZ(row) {
	return (row % 2 == 0 ? row / 2 : (-1 * Math.ceil(row / 2))) * 2.6;
}

function Container({ animationSpeed, voyageData, mode, portUnlo, cell, inboundOutbound, animationTotal, ...props }) {
	const unloToHue = useContext(PortColorFunction);
	// const inboundExclusionCount = useInboundExclusionCountForCell(cell);
	// const outboundExclusionCount = useExclusionCountForCell(cell);

	const isFaded = !(cell.container.portOfLoading == portUnlo || cell.container.portOfDischarge == portUnlo || cell.container.visibility == 'VISIBLE');

	const length = cell.container.length / 1000;
	const width = cell.container.width / 1000;
	const height = cell.container.height / 1000;

	const x = bayToX(cell.bay); // cell.bay * 3.20 - (23 * 3.5);
	const y = tierToY(cell.tier); // (cell.tier > 80 ? cell.tier - 60 : cell.tier) * 1.6;
	const z = rowToZ(cell.row); // (cell.row % 2 == 0 ? cell.row / 2 : (-1 * Math.ceil(cell.row / 2))) * 2.6;

	// console.log(cell.container.portOfLoading, cell.container.portOfDischarge, cell.container.visibility, portUnlo, isFaded);
	const color = isFaded ? '#cfcfcf' : ('#' + generateHexColorForHue(unloToHue(cell.container?.portOfDischarge), DARK_PORT_LIGHTNESS).toString(16).padStart(6, '0'));

	// This reference gives us direct access to the THREE.Mesh object
	const ref = useRef();
	const materialRef = useRef();

	// Hold state for hovered and clicked events
	const [ hovered, hover ] = useState(false);

	const tasks = (useTasksByCellCode() ?? {})[cell.cellCode] ?? [];

	// Subscribe this component to the render-loop, rotate the mesh every frame
	const baseOpacity = isFaded ? 0.1 : 1;
	const containerAnimationHeight = 5;
	const animationDuration = 2.5; // in number of moves the animation is playing
	useFrame((state, delta) => {
		if (mode.type == 'ANIMATION') {
			if (inboundOutbound == 'INBOUND') {
				const task = tasks.find(t => t.task.type == 'DISCHARGE');
				const offset = task == null ? 0 : (((state.clock.elapsedTime) * animationSpeed) % animationTotal);
				const targetOffset = task?.index ?? 0;
				const visible = offset < targetOffset;

				const animationPercentage = Math.sin(Math.max(0, Math.min(1, (targetOffset - offset) / animationDuration)) * 0.5 * Math.PI);

				ref.current.position.y = y + (1 - animationPercentage) * containerAnimationHeight;
				materialRef.current.opacity = visible ? animationPercentage : 0;
			} else {
				const task = tasks.find(t => t.task.type == 'LOAD');
				const offset = task == null ? Infinity : (((state.clock.elapsedTime) * animationSpeed) % animationTotal);
				const targetOffset = task?.index ?? 0;
				const visible = offset > targetOffset;

				const animationPercentage = Math.sin(Math.max(0, Math.min(1, (offset - targetOffset) / animationDuration)) * 0.5 * Math.PI);

				ref.current.position.y = y + (1 - animationPercentage) * containerAnimationHeight;
				materialRef.current.opacity = visible ? animationPercentage * baseOpacity : 0;
			}
		} else if (mode.type == 'OUTBOUND') {
			materialRef.current.opacity = inboundOutbound == 'OUTBOUND' ? baseOpacity : 0;
		} else if (mode.type == 'INBOUND') {
			materialRef.current.opacity = inboundOutbound == 'INBOUND' ? baseOpacity : 0;
		} else if (mode.type == 'REALTIME') {
			if (inboundOutbound == 'INBOUND') {
				materialRef.current.opacity = dischargeComplete(voyageData, cell.container.equipmentIdentifier) ? 0 : baseOpacity;
			} else {
				materialRef.current.opacity = loadingComplete(voyageData, cell.container.equipmentIdentifier) ? baseOpacity : 0;
			}
		}
	});

	// Return the view, these are regular Threejs elements expressed in JSX
	return <mesh
		position={[ x, y, z ]}
		length={length}
		height={height}
		width={width}
		ref={ref}
		onPointerOver={(event) => hover(true)}
		onPointerOut={(event) => hover(false)}>
		<boxGeometry args={[ length, height, width ]} />
		<meshStandardMaterial depthWrite={false} transparent={true} ref={materialRef} color={color} />
	</mesh>;
}