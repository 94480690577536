import React from 'react';

export function parseValue(value) {
	if (typeof value == 'string') { // assume ISO-format: YYYY-MM-DDTHH:MM:SSZ
		return new Date(Date.parse(value));
	} else if (typeof value == 'number') { // assume timestamp in ms
		return new Date(value);
	} else if (value instanceof Date) { // assume it's a Date object already
		return value;
	} else if (value == null) {
		return value;
	} else {
		throw new Error('Unknown date format');
	}
}

export default function DateTime({ value, disableTime }) {
	const d = parseValue(value);
	if (d == null) return <span>-</span>;

	// Shows DD-MM-YYYY
	const datePart = (d.getDate() + '').padStart(2, '0') + '-' + ((d.getMonth() + 1) + '').padStart(2, '0') + '-' + d.getFullYear();
	// Shows HH:MM
	const timePart = (d.getHours() + '').padStart(2, '0') + ':' + (d.getMinutes() + '').padStart(2, '0');
	return <span>{datePart + ' ' + (disableTime ? '' : timePart)}</span>;
}
