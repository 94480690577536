import { Button } from '@atrocit/scl';
import React, { useContext } from 'react';
import _ from 'lodash';
import ButtonArea from '../../lib/forms/ButtonArea';
import Modal from '../../lib/Modal';
import { DEFAULT_PORT_LIGHTNESS, generateColorForHue } from '../../../util/colors';
import PortColorFunction from '../../../context/PortColorFunction';

export default function VariousContainerTotalsModal({ isOpen, onClose, cells }) {
	const unloToHue = useContext(PortColorFunction);

	const containers = cells.map(c => c?.container);
	const groupedByPoL = _.groupBy(containers, 'portOfLoading');
	const groupedByPoD = _.groupBy(containers, 'portOfDischarge');

	return <Modal title="Totalen" isOpen={isOpen} onClose={onClose}>
		<TotalsTable ports={groupedByPoL} isPoL={true} />
		<br/>
		<TotalsTable ports={groupedByPoD} isPoL={false} />
		<ButtonArea>
			<Button level="secondary" onClick={onClose}>Sluiten</Button>
		</ButtonArea>
	</Modal>;
}

function TotalsTable({ ports, isPoL }) {
	const unloToHue = useContext(PortColorFunction);
	let twentyFtTotals = 0;
	let twentyFtTotalsEmpty = 0;
	let twentyFtTotalsFull = 0;

	let fortyTfTotals = 0;
	let fortyTfTotalsEmpty = 0;
	let fortyTfTotalsFull = 0;

	let otherFtTotals = 0;
	let otherFtTotalsEmpty = 0;
	let otherFtTotalsFull = 0;

	let oogTotals = 0;
	let dangerousGoodsTotals = 0;
	let activeReefersTotals = 0;

	let highCubeTotals = 0;
	let highCubeTotalsEmpty = 0;
	let highCubeTotalsFull = 0;

	let totalsPerRow = 0;

	return <table className="table" style={{ whiteSpace: 'nowrap' }}>
		<thead>
			<tr>
				<th className="tbl-align-center">{isPoL ? 'PoL' : 'PoD'}</th>
				<th className="tbl-align-center" colSpan={2}>#20FT</th>
				<th className="tbl-align-center" colSpan={2}>#40FT</th>
				<th className="tbl-align-center" colSpan={2}>#??FT</th>
				<th className="tbl-align-center">#OOG</th>
				<th className="tbl-align-center">#DG</th>
				<th className="tbl-align-center">#RF</th>
				<th className="tbl-align-center" colSpan={2}>#HC</th>
				<th className="tbl-align-center">Totaal</th>
			</tr>
		</thead>
		<tbody>
			{Object.keys(ports).map(port => {
				const containers = ports[port];

				const nrTwentyFt = containers.map(c => c.isoCode).filter(iso => iso != null && iso.length > 0 && iso[0] == '2').length;
				const nrTwentyFtEmpty = containers.filter(c => c.isEmpty && c.isoCode != null && c.isoCode.length > 0 && c.isoCode[0] == '2').length;
				const nrTwentyFtFull = containers.filter(c => !c.isEmpty && c.isoCode != null && c.isoCode.length > 0 && c.isoCode[0] == '2').length;
				twentyFtTotals = twentyFtTotals + nrTwentyFt;
				twentyFtTotalsEmpty = twentyFtTotalsEmpty + nrTwentyFtEmpty;
				twentyFtTotalsFull = twentyFtTotalsFull + nrTwentyFtFull;

				const nrFortyft = containers.map(c => c.isoCode).filter(iso => iso != null && iso.length > 0 && iso[0] == '4').length;
				const nrFortyftEmpty = containers.filter(c => c.isEmpty && c.isoCode != null && c.isoCode.length > 0 && c.isoCode[0] == '4').length;
				const nrFortyftFull = containers.filter(c => !c.isEmpty && c.isoCode != null && c.isoCode.length > 0 && c.isoCode[0] == '4').length;
				fortyTfTotals = fortyTfTotals + nrFortyft;
				fortyTfTotalsEmpty = fortyTfTotalsEmpty + nrFortyftEmpty;
				fortyTfTotalsFull = fortyTfTotalsFull + nrFortyftFull;

				const nrOtherFt = containers.map(c => c.isoCode).filter(iso => iso == null || ((iso.length == 0 || (iso[0] != '2' && iso[0] != '4')))).length;
				const nrOtherFtEmpty = containers.filter(c => c.isEmpty && c.isoCode == null || (c.isoCode != null && (c.isoCode.length == 0 || (c.isoCode[0] != '2' && c.isoCode[0] != '4')))).length;
				const nrOtherFtFull = containers.filter(c => !c.isEmpty && c.isoCode == null || (c.isoCode != null && (c.isoCode.length == 0 || (c.isoCode[0] != '2' && c.isoCode[0] != '4')))).length;
				otherFtTotals = otherFtTotals + nrOtherFt;
				otherFtTotalsEmpty = otherFtTotalsEmpty + nrOtherFtEmpty;
				otherFtTotalsFull = otherFtTotalsFull + nrOtherFtFull;

				const nrOog = containers.filter(c => c.isOog).length;
				oogTotals = oogTotals + nrOog;

				const nrDangerousGoods = containers.filter(c => c.dangerousGoods != null && c.dangerousGoods.length > 0).length;
				dangerousGoodsTotals = dangerousGoodsTotals + nrDangerousGoods;

				const nrActiveReefers = containers.filter(c => c.reeferTemperature != null).length;
				activeReefersTotals = activeReefersTotals + nrActiveReefers;

				const nrHighCube = containers.filter(c => c.isoCode != null && c.isoCode.length > 0 && c.isoCode[1] != '2').length;
				const nrHighCubeEmpty = containers.filter(c => c.isEmpty && c.isoCode != null && c.isoCode.length > 0 && c.isoCode[1] != '2').length;
				const nrHighCubeFull = containers.filter(c => !c.isEmpty && c.isoCode != null && c.isoCode.length > 0 && c.isoCode[1] != '2').length;
				highCubeTotals = highCubeTotals + nrHighCube;
				highCubeTotalsEmpty = highCubeTotalsEmpty + nrHighCubeEmpty;
				highCubeTotalsFull = highCubeTotalsFull + nrHighCubeFull;

				totalsPerRow = totalsPerRow + nrTwentyFt + nrFortyft + nrOtherFt;

				return <tr>
					<td style={{ background: port == "null" ? null : generateColorForHue(unloToHue(port), DEFAULT_PORT_LIGHTNESS) }}>{port == "null" ? "" : port}</td>
					<td className="tbl-align-right"><BlurredZeroValue value={nrTwentyFtEmpty} /></td>
					<td className="tbl-align-right tbl-inverted"><BlurredZeroValue value={nrTwentyFtFull} /></td>
					<td className="tbl-align-right"><BlurredZeroValue value={nrFortyftEmpty} /></td>
					<td className="tbl-align-right tbl-inverted"><BlurredZeroValue value={nrFortyftFull} /></td>
					<td className="tbl-align-right"><BlurredZeroValue value={nrOtherFtEmpty} /></td>
					<td className="tbl-align-right tbl-inverted"><BlurredZeroValue value={nrOtherFtFull} /></td>
					<td className="tbl-align-right"><BlurredZeroValue value={nrOog} /></td>
					<td className="tbl-align-right"><BlurredZeroValue value={nrDangerousGoods} /></td>
					<td className="tbl-align-right"><BlurredZeroValue value={nrActiveReefers} /></td>
					<td className="tbl-align-right"><BlurredZeroValue value={nrHighCubeEmpty} /></td>
					<td className="tbl-align-right tbl-inverted" style={{ borderRight: "1px solid black" }}><BlurredZeroValue value={nrHighCubeFull} /></td>
					<td className="tbl-align-right"><BlurredZeroValue value={nrTwentyFt + nrFortyft + nrOtherFt} /></td>
				</tr>;
			})}
		</tbody>
		<tfoot>
			<tr>
				<td style={{ borderTop: "1px solid black" }}>TOTAAL</td>
				<td className="tbl-align-right" style={{ borderTop: "1px solid black" }}><BlurredZeroValue value={twentyFtTotalsEmpty} /></td>
				<td className="tbl-align-right tbl-inverted" style={{ borderTop: "1px solid black" }}><BlurredZeroValue value={twentyFtTotalsFull} /></td>
				<td className="tbl-align-right" style={{ borderTop: "1px solid black" }}><BlurredZeroValue value={fortyTfTotalsEmpty} /></td>
				<td className="tbl-align-right tbl-inverted" style={{ borderTop: "1px solid black" }}><BlurredZeroValue value={fortyTfTotalsFull} /></td>
				<td className="tbl-align-right" style={{ borderTop: "1px solid black" }}><BlurredZeroValue value={otherFtTotalsEmpty} /></td>
				<td className="tbl-align-right tbl-inverted" style={{ borderTop: "1px solid black" }}><BlurredZeroValue value={otherFtTotalsFull} /></td>
				<td className="tbl-align-right" style={{ borderTop: "1px solid black" }}><BlurredZeroValue value={oogTotals} /></td>
				<td className="tbl-align-right" style={{ borderTop: "1px solid black" }}><BlurredZeroValue value={dangerousGoodsTotals} /></td>
				<td className="tbl-align-right" style={{ borderTop: "1px solid black" }}><BlurredZeroValue value={activeReefersTotals} /></td>
				<td className="tbl-align-right" style={{ borderTop: "1px solid black" }}><BlurredZeroValue value={highCubeTotalsEmpty} /></td>
				<td className="tbl-align-right tbl-inverted" style={{ borderTop: "1px solid black" }}><BlurredZeroValue value={highCubeTotalsFull} /></td>
				<td className="tbl-align-right" style={{ borderTop: "1px solid black" }}><BlurredZeroValue value={totalsPerRow} /></td>
			</tr>
		</tfoot>
	</table>;
}

export function BlurredZeroValue({ value }) {
	return <span style={{ opacity: value == 0 ? '0.2' : '1' }}>{value}</span>;
}