import { useState } from 'react';
import { gql, useMutation } from '@apollo/client';
import ButtonArea from '../../lib/forms/ButtonArea';
import ValidatedButton from '../../lib/forms/ValidatedButton';
import DateTimeField from '../../lib/forms/DateTimeField';
import { DateTime } from 'luxon';
import ShipTemplateField from '../../lib/selectfields/ShipTemplateField';
import { Button, Form, InputField, LoadingOverlay, Modal, WithLabel } from '@atrocit/scl';

export function fromIso(iso) {
	return iso == null ? null : DateTime.fromISO(iso);
}

export default function EditCallInfoModal({ sp, isOpen, onClose }) {
	const [ loading, setLoading ] = useState(false);

	const [ shipName, setShipName ] = useState(sp.shipName ?? '');
	const [ shipTemplate, setShipTemplate ] = useState(sp?.shipTemplate?.id);
	const [ callReference, setCallReference ] = useState(sp.callReference ?? '');
	const [ portUnlo, setPortUnlo ] = useState(sp.portUnlo ?? 'BEANR');
	const [ pta, setPta ] = useState(fromIso(sp.pta));
	const [ ptd, setPtd ] = useState(fromIso(sp.ptd));

	const [ updatePlan ] = useMutation(gql`mutation Mutation($planId: Int!, $callReference: String!, $shipName: String!, $shipTemplate: Int, $portUnlo: String!, $pta: Instant!, $ptd: Instant!) {
		updatePlan(planId: $planId, callReference: $callReference, shipName: $shipName, shipTemplate: $shipTemplate, portCode: $portUnlo, pta: $pta, ptd: $ptd) {
			id
		}
	}`);

	return <Modal isOpen={isOpen} onClose={onClose} title="Callinfo aanpassen">
		{loading && <LoadingOverlay />}
		<Form style={{ maxWidth: 'var(--u-512)' }} onSubmit={() => {
			setLoading(true);
			const variables = {
				planId: sp.id,
				callReference,
				shipName,
				shipTemplate,
				portUnlo,
				pta: pta.toUTC().toISO(),
				ptd: ptd.toUTC().toISO(),
			};
			updatePlan({ variables })
				.then(onClose)
				.finally(() => setLoading(false));
		}}>
			<WithLabel label="Callreferentie">
				<InputField onChange={setCallReference} value={callReference} />
			</WithLabel>

			<WithLabel label="Scheepstemplate">
				<ShipTemplateField onChange={id => setShipTemplate(id)} value={shipTemplate} />
			</WithLabel>

			<WithLabel label="Scheepsnaam">
				<InputField onChange={setShipName} value={shipName} />
			</WithLabel>

			<WithLabel label="Haven UNLO-code">
				<InputField onChange={setPortUnlo} value={portUnlo} />
			</WithLabel>

			<WithLabel label="Geplande aankomst (PTA)">
				<DateTimeField onChange={dt => (new DateTime(dt).isValid ? setPta(dt) : null)} value={pta} />
			</WithLabel>

			<WithLabel label="Gepland vertrek (PTD)">
				<DateTimeField onChange={dt => (new DateTime(dt).isValid ? setPtd(dt) : null)} value={ptd} />
			</WithLabel>

			<ButtonArea>
				<ValidatedButton
					type="submit"
					level="primary"
					validations={[
						{ valid: portUnlo.length == 5, error: 'Haven UN location code moet 5 tekens zijn (bijvoorbeeld BEANR of NLRTM)' },
						{ valid: callReference.length > 0, error: 'Callreferentie mag niet leeg zijn' },
						{ valid: shipName.length > 0, error: 'Scheepsnaam mag niet leeg zijn' },
						{ valid: pta != null, error: 'Geplande aankomst mag niet leeg zijn' },
						{ valid: ptd != null, error: 'Gepland vertrek mag niet leeg zijn' },
					]}>
					<span className="fa fa-check" />&nbsp; Opslaan
				</ValidatedButton>
				<Button level="secondary" onClick={onClose}>Annuleren</Button>
			</ButtonArea>
		</Form>
	</Modal>;
}